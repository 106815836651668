import { Button } from "@material-ui/core";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Oven from "../../assets/img/oven.png";
import SimpleSnackbar from "../../components/UI/SnakBar";
import ProductFooter from "./ProductFooter";
import { getProductDetail, getUserAddressById } from "./ProductService";

/**
 * @author
 * @function OrderSuccess
 **/

const OrderSuccess = (props) => {
  let history = useHistory();
  const { productId } = useParams();

  const [productDetails, setProductDetails] = useState({});
  const [addressDetails, setAddressDetails] = useState({});

  const getUserAddressByIdHandler = useCallback(async () => {
    const id = productId.split("-")[1];
    const res = await getUserAddressById(id);

    if (res && res.status) {
      setAddressDetails(res.data);
    }
  }, [productId]);

  const getProductDetailHandler = useCallback(async () => {
    const id = productId.split("-")[0];
    const res = await getProductDetail(id);
    if (res && res.status) {
      setProductDetails(res.data);
    }
  }, [productId]);

  useEffect(() => {
    getUserAddressByIdHandler();
    getProductDetailHandler();
  }, [getUserAddressByIdHandler, getProductDetailHandler]);

  const estimatedDate = moment().add(10, "days").format("dddd, MMM Do YYYY");

  return (
    <>
      <div className="order_success_wrap border-top">
        <div className="head">
          <div className="wrap">
            <i className="icon-lg endorsement"></i>
            <p className="text-primary">Your Order is Successful</p>
            <p>Confirmation will be sent to your email.</p>
          </div>
        </div>

        <div className="order_detail_wrap">
          <div className="details">
            <p className="shipping">
              Shipping to : <strong>{addressDetails.fullName}</strong> -{" "}
              {addressDetails.address} - {addressDetails.pincode}{" "}
              {addressDetails.city}, INDIA
            </p>
            <p className="e_delivery">
              Estimated delivery :{" "}
              <span className="text-primary">{estimatedDate}</span>{" "}
            </p>
          </div>
          <div className="product_image">
            {productDetails?.mediaLinks?.slice(0, 1).map((img) => {
              return <img src={img.location} alt="" />;
            })}
          </div>
        </div>
        <div className="buttons_review_share">
          <Button
            className="mr-35"
            onClick={() => history.push("/ecommerce/dashboard/my-orders")}
          >
            Review or edit your orders
          </Button>
          {/* <Button>Share</Button> */}
        </div>
        <ProductFooter />
      </div>
    </>
  );
};

export default OrderSuccess;

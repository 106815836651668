import axios from "axios";

export const userProfile = async () => {
  try {
    const { data } = await axios.get(`auth/profile`);
    return data;
  } catch (err) {
    return err;
  }
};
export const userProfileOf = async (id) => {
  try {
    if (!id) {
      let userDetails = localStorage.getItem("userDetail");
      userDetails = JSON.parse(userDetails);
      id = userDetails["_id"];
    }
    const { data } = await axios.get(`user/profile/${id}/details`);
    return data;
  } catch (err) {
    return err;
  }
};
export const userProfileUpdate = async (payload) => {
  try {
    const { data } = await axios.post(`user/profile`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const getAllFeedsOfUser = async (id) => {
  try {
    const { data } = await axios.get(`user/${id}/feed`);
    return data;
  } catch (err) {
    return err;
  }
};
export const streamStart = async (payload) => {
  try {
    const { data } = await axios.post(`stream`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const streamEdit = async (streamId, payload) => {
  try {
    const { data } = await axios.post(`stream/${streamId}`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const userSuggestions = async (payload) => {
  try {
    const { data } = await axios.get(`user/userSuggestions?more=false`);
    return data;
  } catch (err) {
    return err;
  }
};
export const followUser = async (id) => {
  try {
    const { data } = await axios.post(`user/${id}/follow`);
    return data;
  } catch (err) {
    return err;
  }
};
export const unFollowUser = async (id) => {
  try {
    const { data } = await axios.post(`user/${id}/unfollow`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getFollowing = async (id) => {
  try {
    const { data } = await axios.get(`user/profile/${id}/following`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getFollowers = async (id) => {
  try {
    const { data } = await axios.get(`user/profile/${id}/followers`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getUploadedPhotos = async () => {
  try {
    const { data } = await axios.get(`uploadFile`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getUploadedPhotosByUserId = async (id) => {
  try {
    const { data } = await axios.get(`uploadFiles/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};
export const validateUsername = async (username) => {
  try {
    const { data } = await axios.get(
      `user/profile/validateUsername?username=${username}`
    );
    return data;
  } catch (err) {
    return err;
  }
};

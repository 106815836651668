import React from "react";
import { Alert, Button } from "react-bootstrap";

export default function AlertBox(props) {
  const { onShow, onHide } = props;
  return (
    <>
      <div className="alert_wrap">
        <Alert show={onShow} variant="success" className="alert_wrap">
          <Alert.Heading>Registration Successfully!</Alert.Heading>
          <p>Please verify your email and then login</p>
        </Alert>
      </div>
    </>
  );
}

import { Button } from "@material-ui/core";
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";

import SimpleSnackbar from "../../components/UI/SnakBar";

import {
  followUser,
  unFollowUser,
  userProfile,
} from "../Profile/ProfileService";
import { getSearchData } from "./SearchService";

/**
 * @author
 * @function Search
 **/

const SearchResult = (props) => {
  const { query } = useParams();
  const [userProfileData, setUserProfileData] = useState({});
  const [searchType, setSearchType] = useState("ALL");
  const [allUsersSearchData, setAllUsersSearchData] = useState([]);
  const [allPostsSearchData, setAllPostsSearchData] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const getSearchDataHandler = useCallback(async () => {
    const res = await getSearchData(searchType, query);

    if (res && res.status) {
      if (res.data.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
      const tempUsers = res.data.filter((p) => p.type === "USER");
      const tempPosts = res.data.filter((p) => p.type === "POST");
      setAllUsersSearchData(tempUsers);
      setAllPostsSearchData(tempPosts);
    } else {
      setAllUsersSearchData([]);
      setAllPostsSearchData([]);
    }
  }, [query, searchType]);

  const handleTypeSearch = (type) => {
    setSearchType(type);
  };

  const getUserProfileHandler = useCallback(async () => {
    const res = await userProfile();

    if (res && res.status) {
      setUserProfileData(res.data);
    }
  }, []);

  const followUserHandler = async (id) => {
    const res = await followUser(id);

    if (res && res.status) {
      setOpen(true);
      setMessage("You have started following");
      getUserProfileHandler();
    } else {
      setOpen(true);
      setMessage("Something went wrong");
    }
  };
  const unFollowUserHandler = async (id) => {
    const res = await unFollowUser(id);

    if (res && res.status) {
      setOpen(true);
      setMessage("You have unfollowed");
      getUserProfileHandler();
    } else {
      setOpen(true);
      setMessage("Something went wrong");
    }
  };

  useEffect(() => {
    getSearchDataHandler();
    getUserProfileHandler();
  }, [getSearchDataHandler, getUserProfileHandler]);

  return (
    <>
      <div className="search_wrapper">
        <div className="head">
          <ul>
            <li onClick={() => handleTypeSearch("ALL")}>
              <p className={`${searchType === "ALL" ? "active" : ""}`}>All</p>
            </li>
            <li onClick={() => handleTypeSearch("POST")}>
              <p className={`${searchType === "POST" ? "active" : ""}`}>
                Posts
              </p>
            </li>
            <li onClick={() => handleTypeSearch("USER")}>
              <p className={`${searchType === "USER" ? "active" : ""}`}>
                People
              </p>
            </li>
          </ul>
        </div>
        <div className="body">
          {!noDataFound ? (
            <>
              {allUsersSearchData.length > 0 ? (
                <div className="people_wrap">
                  <h5>People</h5>
                  <ul>
                    {allUsersSearchData.map((user) => {
                      return (
                        <li key={user._id}>
                          <div className="media">
                            {user.profilePic ? (
                              <img src={user.profilePic} alt="" />
                            ) : (
                              <span className="dp_name">
                                {user.name.charAt(0)}
                              </span>
                            )}

                            <div className="media_body">
                              <h6
                                onClick={() =>
                                  (window.location.href = `/profile/${user._id}`)
                                }
                              >
                                @{user?.username ? user?.username : "username"}
                              </h6>
                              <p>{user?.location}</p>
                            </div>
                          </div>
                          <div className="media_button">
                            {userProfileData._id !== user._id && (
                              <>
                                {userProfileData?.following?.some(
                                  (p) => p === user._id
                                ) ? (
                                  <Button
                                    variant={"outlined"}
                                    color="secondary"
                                    type="submit"
                                    onClick={() =>
                                      unFollowUserHandler(user._id)
                                    }
                                  >
                                    Following
                                  </Button>
                                ) : (
                                  <Button
                                    variant={"contained"}
                                    color="primary"
                                    type="submit"
                                    onClick={() => followUserHandler(user._id)}
                                  >
                                    Follow
                                  </Button>
                                )}
                              </>
                            )}
                          </div>
                        </li>
                      );
                    })}

                    {/* <li>
                <div className="media">
                  <img src={FollowersImg} alt="" />
                  <div className="media_body">
                    <h6>Brock Lee</h6>
                    <p>Canberra, Australia</p>
                  </div>
                </div>
                <div className="media_button">
                  <Button variant="outlined" color="secondary" type="submit">
                    Following
                  </Button>
                </div>
              </li> */}
                  </ul>
                </div>
              ) : (
                ""
              )}

              {allPostsSearchData.length > 0 ? (
                <div className="posts_wrap">
                  <h5>Posts</h5>
                  <ul>
                    {allPostsSearchData.map((post) => {
                      return (
                        <li key={post._id}>
                          <div className="post_card">
                            <div className="post_head">
                              {post.user.map((user) => {
                                return (
                                  <div className="media">
                                    {user.profilePic ? (
                                      <img src={user.profilePic} alt="" />
                                    ) : (
                                      <span className="dp_name">
                                        {user.name.charAt(0)}
                                      </span>
                                    )}
                                    <div className="media_body">
                                      <h6
                                        onClick={() =>
                                          (window.location.href = `/profile/${user._id}`)
                                        }
                                      >
                                        {user.name}
                                      </h6>
                                      <p>
                                        @
                                        {user?.username
                                          ? user?.username
                                          : "username"}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}

                              <div className="time">
                                <p>17 May 2020</p>
                              </div>
                            </div>

                            <div className="post_body">
                              {/* <img src={PostImg} alt="" /> */}
                              {post.description}
                            </div>
                            <div className="post_footer">
                              <p>
                                <i className="icon  liked"></i> Anna Mull 55
                                others liked
                              </p>
                              <p>
                                <i className="icon comment"></i>
                                40 Comment
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <h5 className="text-center mt-5 mb-5"> No Data Found</h5>
          )}
        </div>
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default SearchResult;

import { Button } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import DP from "../../assets/img/followers.png";
import SimpleSnackbar from "../../components/UI/SnakBar";
import LocalStorageService from "../../utils/LocalStorageService";
import { followUser, getFollowers, unFollowUser } from "./ProfileService";
import { useUser } from "../../context/UserProvider";

/**
 * @author
 * @function Followers
 **/

const Followers = (props) => {
  const { userId, fromDashboard } = props;
  const { getUserOfData, userOfData } = useUser();

  const [followerList, setFollowerList] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const getFollowerList = useCallback(async () => {
    const { following } = userOfData;
    const res = await getFollowers(localStorage.getItem("userId"));

    if (res && res.status) {
      res.data.followers.forEach((element) => {
        if (following.some((p) => p === element._id)) {
          element.isFollowing = true;
        } else {
          element.isFollowing = false;
        }
      });

      setFollowerList(res.data.followers);
      console.log(res.data.followers, "followers list")
    } else {
      setFollowerList([]);
    }
  }, []);

  const followUserHandler = async (id) => {
    const res = await followUser(id);

    if (res && res.status) {
      setOpen(true);
      setMessage("You have started following");

      getUserOfData(userId);
      setTimeout(() => {
        getFollowerList();
      }, 1000);
    } else {
      setOpen(true);
      setMessage("Something went wrong");
    }
  };
  const unFollowUserHandler = async (id) => {
    const res = await unFollowUser(id);

    if (res && res.status) {
      setOpen(true);
      setMessage("You have unfollowed");
      getFollowerList();
    } else {
      setOpen(true);
      setMessage("Something went wrong");
    }
  };

  useEffect(() => {
    getFollowerList();
  }, [getFollowerList]);

  return (
    <>
      <p className="followers_text">
        {followerList.length} {fromDashboard ? "Participants" : "Followers"}
      </p>
      <div className="followers_wrap">
        <div className="body">
          <ul>
            {followerList.map((item) => {
              return (
                <li key={item._id} >
                  <div className="user_wrap">
                    {item.profilePic ? (
                      <img src={item.profilePic} alt="" onClick={() =>
                        (window.location.href = `/profile/${item._id}`)
                      } style={{cursor:"pointer"}} />
                    ) : (
                      <span className="dp_name" onClick={() =>
                        (window.location.href = `/profile/${item._id}`)
                      } style={{cursor:"pointer"}}>{item.name.charAt(0)}</span>
                    )}
                    <div className="content">
                      <h6
                        // onClick={() =>
                        //   (window.location.href = `/profile/${item._id}`)
                        // }
                      >
                        @{item?.username ? item?.username : "username"}
                      </h6>
                      <p>{item?.location}</p>
                    </div>
                  </div>
                  <div className="button_wrap">
                    {!item.isFollowing && (
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => followUserHandler(item._id)}
                      >
                        Follow
                      </Button>
                    )}

                    <span
                      className="remove"
                      onClick={() => unFollowUserHandler(item._id)}
                    >
                      <i className="icon remove"></i>
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default Followers;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import MyProduct from "../Products/MyProduct";

/**
 * @author
 * @function ProductOfUser
 **/

const ProductOfUser = (props) => {
  const { id } = useParams();
  useEffect(() => {
    localStorage.setItem("userId", id);
  }, []);
  return (
    <>
      <MyProduct userIdOfProduct={id} />
    </>
  );
};

export default ProductOfUser;

import React from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import ALEN from "../../assets/img/alen.png";
import LocalStorageService from "../../utils/LocalStorageService";

/**
 * @author
 * @function LeftMenu
 **/

const LeftMenu = (props) => {
  let location = useLocation();
  const userDetails = LocalStorageService.getItem("userDetail");
  const {
    isStreamingChat,
    isFeed,
    handleGameStartOpen,
    streamId,
    role,
    fromProfile,
    userOfDetails,
    fromStream,
  } = props;

  let isVisibleMyItems = false;
  if (
    location.pathname.includes("my-product") ||
    location.pathname.includes("address")
  ) {
    isVisibleMyItems = true;
  }

  return (
    <div className="wrap_menu sticky-top">
      <div className="wrap menu_wrap">
        {isStreamingChat ? "" : <h5>Menu</h5>}

        <ul>
          <li>
            <NavLink activeClassName="active" to="/feed">
              <span>Home</span>
              <i className="icon home"></i>
            </NavLink>
          </li>
          {/* <li>
            <NavLink activeClassName="active" to="/videos">
              <span>Videos</span>
              <i className="icon video_menu"></i>
            </NavLink>
          </li> */}
          <li>
            <NavLink activeClassName="active" to={`/streams/all`}>
              <span>Streams</span>
              <i className="icon streamer_menu"></i>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={`/my-product`}>
            {/* <NavLink activeClassName="active" to={`/my-product/add`}> */}
              <span>Store</span>
              <i className="icon shopping_bag"></i>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={`/ecommerce/dashboard/my-orders`}>
              <span>Orders</span>
              <i className="icon shopping_bag"></i>
            </NavLink>
          </li>

          {/* <li>
              <NavLink activeClassName="active" to="/kycupdate">
              <span>KYC</span>
                <i className="icon kyc"></i>
              </NavLink>
                
              </li>
             
              <li>
                <NavLink activeClassName="active" to="/address">
                  <span>My Address</span>
                  <i className="icon address"></i>
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/change-password">
                  <span>Change Password</span>
                  <i className="icon address"></i>
                </NavLink>
              </li> */}


          <li>
            <NavLink activeClassName="active" to="/messages">
              <span>Messages</span>
              <i className="icon message_menu"></i>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/notifications">
              <span>Notifications</span>
              <i className="icon notification_menu"></i>
            </NavLink>
          </li>

          <li>
            <a
              activeClassName="active"
              target="_blank"
              href="https://revicoin.com/"
              rel="noreferrer"
            >
              <span>Wallet</span>
              <i className="icon wallet"></i>
            </a>
          </li>
          {(fromProfile && userDetails?._id === userOfDetails?._id) ||
          isVisibleMyItems ||
          userDetails?._id === userOfDetails?._id ? (
            <>
              {" "}
              {/* <li>
              <NavLink activeClassName="active" to="/kycupdate">
              <span>KYC Updation</span>
                <i className="icon kyc"></i>
              </NavLink>
                
              </li> */}
              {/* <li>
                <NavLink activeClassName="active" to="/my-product">
                  <span>My Products</span>
                  <i className="icon shopping_bag"></i>
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink activeClassName="active" to="/address">
                  <span>My Address</span>
                  <i className="icon address"></i>
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/change-password">
                  <span>Change Password</span>
                  <i className="icon address"></i>
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink activeClassName="active" to="/my-orders">
                  <span>My Orders</span>
                  <i className="icon shopping_bag"></i>
                </NavLink>
              </li> */}
            </>
          ) : null}
        </ul>
      </div>
      {!fromProfile && (
        <div className="wrap game_wrap">
          <h5>Games</h5>

          <ul>
            {fromStream ? (
              <li>
                <a href={`/streaming/game/${streamId}/${role}`}>
                  <span>Poker</span>
                  <i className="icon poker"></i>
                </a>
              </li>
            ) : (
              <li>
                <a activeClassName="active" href={`/game/poker`}>
                  <span>Poker</span>
                  <i className="icon poker"></i>
                </a>
              </li>
            )}

            {/* <li>
            <span>Flash Time</span>
            <i className="icon teenpatti"></i>
          </li> */}
          </ul>
        </div>
      )}

      {/* {isFeed && (
        <div className="wrap game_wrap ">
          <h5>People</h5>

          <div className="contact_list">
            <ul>
              <li>
                <div className="image_wrap">
                  <img src={ALEN} alt="dd" />
                </div>
                <div className="content">
                  <h6>Brock Lee</h6>
                  <p>Canberra, Australia</p>
                </div>
              </li>
              <li>
                <div className="image_wrap">
                  <img src={ALEN} alt="dd" />
                </div>
                <div className="content">
                  <h6>Brock Lee</h6>
                  <p>Canberra, Australia</p>
                </div>
              </li>
              <li>
                <div className="image_wrap">
                  <img src={ALEN} alt="dd" />
                </div>
                <div className="content">
                  <h6>Brock Lee</h6>
                  <p>Canberra, Australia</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default LeftMenu;

import React from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";

/**
 * @author
 * @function FormInputGroup
 **/

const FormInputGroup = (props) => {
  const { groupText, placeholder, name, value, onChange, label, ...rest } =
    props;
  return (
    <>
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        <FormControl
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          {...rest}
        />
        <InputGroup.Append>
          <InputGroup.Text id="basic-addon2">{groupText}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </>
  );
};

export default FormInputGroup;

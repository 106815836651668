/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import { Link } from "react-router-dom";

/**
 * @author
 * @function AuthFooter
 **/

const AuthFooter = (props) => {
  return (
    <>
      <div className="footer_wrap">
        <div className="copyright">
          <span>Copyright @ 2022</span>
        </div>
        <div className="footer_links">
          <div className="social_links">
            <ul>
              <li>
                <a href="javascript:void(0)">
                  <i className="icon facebook"></i>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <i className="icon twitter"></i>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <i className="icon linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="links">
            <ul>
              <li>
                <Link to="/terms">Terms of Service</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Center</Link>
              </li>
              {/* <li>
                <a href="javascript:void(0)">AdChoices</a>
              </li>
              <li>
                <a href="javascript:void(0)">Security Center</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthFooter;

import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/Logo.png";

/**
 * @author
 * @function Terms
 **/

const Privacy = (props) => {
  return (
    <>
      <div className="head logo_head_common">
        <Link to="/">
          <img src={Logo} className="logo" alt="logo" />
        </Link>
      </div>
      <div className="terms_wrap border-top">
        <Container fluid>
          <h1>Privacy Policy</h1>
          <p className="sub_heading">Welcome to REVIT</p>
          <h3>1. The services we provide.</h3>
          <p>
            Our mission is to give people the power to build community and bring
            the world closer together. To help advance this mission, we provide
            the products and services described below to you:
          </p>
          <ul>
            <li>
              <h5>Provide a personalised experience for you:</h5>
              <p>
                Your experience on Facebook is unlike anyone else's: from the
                posts, stories, events, ads and other content that you see in
                News Feed or our video platform to the Pages that you follow and
                other features that you might use, such as Trending, Marketplace
                and search. We use the data that we have – for example, about
                the connections you make, the choices and settings you select,
                and what you share and do on and off our Products – to
                personalise your experience.
              </p>
            </li>
            <li>
              <h5>
                Connect you with people and organisations that you care about:
              </h5>
              <p>
                We help you find and connect with people, groups, businesses,
                organisations and others that matter to you across the Facebook
                Products that you use. We use the data that we have to make
                suggestions for you and others – for example, groups to join,
                events to attend, Pages to follow or send a message to, shows to
                watch and people who you may want to become friends with.
                Stronger ties make for better communities, and we believe that
                our services are most useful when people are connected to
                people, groups and organisations that they care about.
              </p>
            </li>
            <li>
              <h5>
                Empower you to express yourself and communicate about what
                matters to you:
              </h5>
              <p>
                There are many ways to express yourself on Facebook and to
                communicate with friends, family and others about what matters
                to you – for example, sharing status updates, photos, videos and
                stories across the Facebook Products that you use, sending
                messages to a friend or several people, creating events or
                groups, or adding content to your profile. We have also
                developed, and continue to explore, new ways for people to use
                technology, such as augmented reality and 360 video to create
                and share more expressive and engaging content on Facebook.
              </p>
            </li>
            <li>
              <h5>
                Help you discover content, products and services that may
                interest you:
              </h5>
              <p>
                We show you ads, offers and other sponsored content to help you
                discover content, products and services that are offered by the
                many businesses and organisations that use Facebook and other
                Facebook Products. Section 2 below explains this in more detail.
              </p>
            </li>
            <li>
              <h5>
                Combat harmful conduct, and protect and support our community:
              </h5>
              <p>
                People will only build community on Facebook if they feel safe.
                We employ dedicated teams around the world and develop advanced
                technical systems to detect misuse of our Products, harmful
                conduct towards others and situations where we may be able to
                help support or protect our community. If we learn of content or
                conduct like this, we will take appropriate action – for
                example, offering help, removing content, removing or
                restricting access to certain features, disabling an account or
                contacting law enforcement. We share data with other Facebook
                Companies when we detect misuse or harmful conduct by someone
                using one of our Products.
              </p>
            </li>
          </ul>
          <h3>2. How our services are funded</h3>
          <p>
            nstead of paying to use Facebook and the other products and services
            we offer, by using the Facebook Products covered by these Terms, you
            agree that we can show you ads that businesses and organisations pay
            us to promote on and off the Facebook Company Products. We use your
            personal data, such as information about your activity and
            interests, to show you ads that are more relevant to you. Protecting
            people's privacy is central to how we've designed our ad system.
            This means that we can show you relevant and useful ads without
            telling advertisers who you are. We don't sell your personal data.
            We allow advertisers to tell us things such as their business goal,
            and the kind of audience that they want to see their ads (for
            example, people between the ages of 18-35 who like cycling). We then
            show their ad to people who might be interested.
          </p>
        </Container>
      </div>
    </>
  );
};

export default Privacy;

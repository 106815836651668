import axios from "axios";

export const emailVerificationAPI = async (token) => {
  try {
    const { data } = await axios.get(`auth/verifyEmail?token=${token}`);
    return data;
  } catch (err) {
    return err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  emailVerificationAPI,
};

/* eslint-disable no-script-url */
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Container,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
  InputGroup,
} from "react-bootstrap";

import Logo from "../../assets/img/Logo.png";
import smartTruncate from "smart-truncate";

import { Link, NavLink, useHistory } from "react-router-dom";
import AppContext from "../../context";
import LocalStorageService from "../../utils/LocalStorageService";
import { userProfile } from "../../containers/Profile/ProfileService";
import {
  getAllNotifications,
  markReadAllNotifications,
} from "../../containers/Notifications/NotificationService";
import { useNotifications } from "../../context/NotificationProvider";
import { useUser } from "../../context/UserProvider";
import SimpleSnackbar from "../UI/SnakBar";
import { getAllMessages } from "../../containers/Messanger/Service";
import CustomModal from "../UI/Modal";
import { Button } from "@material-ui/core";

/**
 * @author
 * @function HeaderComponent
 **/

const HeaderComponent = (props) => {
  const userDetails = LocalStorageService.getItem("userDetail");
  const { onSubmitUserHandler, userOfData } = useUser();

  const {
    setAllNotifications,
    onSubmitNotificationHandler,
    onSubmitMessageCount,
    messageCount,
    onSubmitUnreadMessageUserList,
  } = useNotifications();
  const [notificationsCount, setNotificationCount] = useState(0);

  const context = useContext(AppContext);
  const { userData } = context;
  let history = useHistory();

  const [loggedInUserDetails, setLoggedInUserDetails] = useState({
    name: "",
  });
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [walletOpen, setWalletOpen] = useState(false);

  const handleCloseWalletOpen = () => setWalletOpen(false);
  const handleOpenWalletOpen = () => setWalletOpen(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const logoutHandler = () => {
    //const rememberObj = LocalStorageService.getItem("remeberMe");
    localStorage.clear();
    // LocalStorageService.setItem("remeberMe", rememberObj);
    window.location.href = "/login";
  };

  const getUserProfileHandler = async () => {
    const res = await userProfile();

    if (res && res.status) {
      setLoggedInUserDetails(res.data);
      onSubmitUserHandler(res.data);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    if (e.key === "Enter") {
      history.push(`/search/${value}`);
    }
  };

  const getAllNotificationsHandler = useCallback(async () => {
    const res = await getAllNotifications();
    if (res && res.status) {
      onSubmitNotificationHandler(res.data);
      const founded = res.data.filter((n) => !n.read);
      setNotificationCount(founded.length);
    }
  }, []);

  const markReadAllNotificationsHandler = async () => {
    await markReadAllNotifications();

    setNotificationCount(0);
  };

  useEffect(() => {
    getAllNotificationsHandler();
  }, [getAllNotificationsHandler]);

  useEffect(() => {
    getUserProfileHandler();
  }, []);

  const address = smartTruncate(loggedInUserDetails?.address, 18, {
    position: 8,
  });

  function copyAddressHandler() {
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(loggedInUserDetails?.address);
    setOpen(true);
    setMessage("Address copied to clipboard");
  }

  const getAllMessagesHandler = useCallback(async () => {
    const res = await getAllMessages(userDetails?._id);
    if (res?.status) {
      const count = res?.data?.reduce((sum, item) => {
        return sum + item.count;
      }, 0);
      onSubmitMessageCount(count);
      onSubmitUnreadMessageUserList(res.data);
    }
  }, [userDetails?._id]);

  useEffect(() => {
    getAllMessagesHandler();
  }, [getAllMessagesHandler]);

  const goToWallet = () => {
    window.location.href = "https://revicoin.com";
  };

  return (
    <>
      <Navbar fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container fluid>
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="logo" />
          </Link>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          {userDetails?._id && (
            <Navbar.Collapse id="responsive-navbar-nav">
              <div className="input_group_wrap ">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (searchQuery) {
                            history.push(`/search/${searchQuery}`);
                          }
                        }}
                        className="icon search"
                      ></i>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl placeholder="Search" onKeyDown={handleSearch} />
                </InputGroup>
              </div>

              <Nav>
                <NavLink
                  className="nav-link notification_link"
                  activeClassName="active"
                  to="/messages"
                >
                  <i className="icon chat"></i>
                  {messageCount > 0 && (
                    <span className="notification_count">{messageCount}</span>
                  )}
                </NavLink>
                <NavLink
                  className="nav-link notification_link"
                  activeClassName="active"
                  to={`/notifications`}
                  onClick={markReadAllNotificationsHandler}
                >
                  <i className="icon notification"></i>
                  {notificationsCount > 0 && (
                    <span className="notification_count">
                      {notificationsCount}
                    </span>
                  )}
                </NavLink>

                <Nav.Link
                  activeClassName="active"
                  href={`/profile/${loggedInUserDetails?._id}`}
                >
                  {loggedInUserDetails?.profilePic ? (
                    <img
                      className="dp"
                      alt="dp"
                      src={loggedInUserDetails?.profilePic}
                    />
                  ) : (
                    <span className="dp_name">
                      {loggedInUserDetails?.name?.charAt(0)}
                    </span>
                  )}
                </Nav.Link>

                <NavDropdown
                  title={<i className="icon three-dot"></i>}
                  id="basic-nav-dropdown"
                  className="pr-0"
                >
                  <NavDropdown.Item href={`/profile/${userDetails?._id}`}>
                    <i className="icon user_new"></i> My Profile
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    href={`/stream/dashboard/${userDetails?._id}`}
                  >
                    <i className="icon streamer_menu"></i> My Streams
                  </NavDropdown.Item>

                  {/* <NavDropdown.Item
                    href={`${
                      loggedInUserDetails?.productCount > 0
                        ? "/ecommerce/dashboard"
                        : "/my-product/add"
                    }`}
                  >
                    <i className="icon shopping_bag"></i> My Store
                  </NavDropdown.Item> */}

                  {/* <NavDropdown.Item href={`/ecommerce/dashboard/my-orders`}>
                    <i className="icon shopping_bag"></i> My Orders
                  </NavDropdown.Item> */}

                  <NavDropdown.Item
                    href="#!"
                    className="wallet_link"
                    onClick={handleOpenWalletOpen}
                  >
                    <i className="icon wallet"></i> Wallet
                  </NavDropdown.Item>

                  <NavDropdown.Item href={`/kycupdate`}>
                    <i className="icon kyc"></i> KYC
                  </NavDropdown.Item>

                  <NavDropdown.Item href={`/address`}>
                    <i className="icon address"></i> My Address
                  </NavDropdown.Item>

                  <NavDropdown.Item href={`/change-password`}>
                    <i className="icon address"></i> Change Password
                  </NavDropdown.Item>


                  <NavDropdown.Item
                    href="javascript:void(0)"
                    onClick={logoutHandler}
                  >
                    <i className="icon logout_new"></i> Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
      <CustomModal
        show={walletOpen}
        handleClose={handleCloseWalletOpen}
        title="Wallet"
      >
        <div className="wallet-details">
          <div className="address_balance">
            <div className="balance">
              <h5>Balance</h5>
              <p>{loggedInUserDetails?.balance} RVT</p>
            </div>
            <div className="address">
              <h5>Wallet Address</h5>
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  copyAddressHandler();
                }}
              >
                {" "}
                {address}
              </p>
            </div>
          </div>
          <Button variant="contained" color="primary" onClick={goToWallet}>
            Manage Wallet
          </Button>
        </div>
      </CustomModal>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default HeaderComponent;

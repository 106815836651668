import React, { useCallback, useEffect, useRef, useState } from "react";

import { InputGroup, FormControl, Row, Container, Col } from "react-bootstrap";
import Picker from "emoji-picker-react";

import Conversation from "./Conversation";
import Messages from "./Messages";
import LocalStorageService from "../../utils/LocalStorageService";
import {
  getFollowers,
  getFollowing,
  userProfileOf,
} from "../Profile/ProfileService";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import { useHistory, useParams } from "react-router-dom";
import { useProfilePic } from "../../context/UserProfilePicProvider";
import isEmpty from "is-empty";

import { getAllMessages, getAllContacts } from "./Service";
import useChat from "../../hooks/useChat";
import { useUser } from "../../context/UserProvider";
import { useNotifications } from "../../context/NotificationProvider";
import ContactListLoader from "../../components/SkeletonLoaders/ContactListLoader";

/**
 * @author
 * @function Messanger
 **/

const Messanger = (props) => {
  let history = useHistory();
  const { userData } = useUser();
  const { onSubmitMessageCount, unreadMessagesUserList } = useNotifications();
  const { conversationId } = useParams();
  const { messages, sendMessage, setMessages } = useChat(userData._id);
  const { profilePic, findUserProfilePicHandler } = useProfilePic();
  const userDetails = LocalStorageService.getItem("userDetail");
  const [contactList, setContactList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [currentChat, setCurrentChat] = useState(null);
  // const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [copiedList, setCopiedList] = useState([]);

  const messagesEndRef = useRef(null);

  const handleShowEmojiOpen = () => setIsEmojiOpen(true);
  const handleCloseEmojiOpen = () => setIsEmojiOpen(false);

  const getUserDetailsById = useCallback(async () => {
    const res = await userProfileOf(conversationId);
  

    if (res.status) {
      setCurrentChat(res.data);
    }
  }, [conversationId]);

  useEffect(() => {
    getUserDetailsById();
  }, [getUserDetailsById]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isEmpty(newMessage)) {
      return;
    }
    const messageData = {
      senderId: userDetails._id,
      receiverId: conversationId,
      message: newMessage,
    };

    sendMessage(messageData);
    setNewMessage("");
    setMessages((prev) => [...prev, messageData]);
  };

  const getAllMessagesHandler = useCallback(async () => {
    const res = await getAllMessages(userDetails._id, conversationId);
    console.log("This is msg", res.data)
    if (res.status) {
      setMessages(res.data);
    }
  }, [conversationId, setMessages, userDetails._id]);   


  useEffect(() => {
    getAllMessagesHandler();
  }, [getAllMessagesHandler, conversationId]);

  useEffect(() => {
    if (conversationId) {
      findUserProfilePicHandler(conversationId);
    }
  }, [conversationId]);

  useEffect(() => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const onEmojiClick = (event, emojiObject) => {
    // setChosenEmoji(emojiObject);
    // setFeedData((prevState) => ({
    //   ...prevState,
    //   content: prevState.content + emojiObject.emoji,
    // }));
    setNewMessage((prevState) => prevState + emojiObject.emoji);
    handleCloseEmojiOpen();
  };

  useEffect(() => {
    document.body.classList.add("is_message_page");
    return () => document.body.classList.remove("is_message_page");
  }, []);

  const handleSearch = (e) => {
    const tempData = [...contactList];
    const { value } = e.target;
    const filterData = tempData.filter((p) =>
      p.username.toLowerCase().includes(value)
    );
    if (value) {
      setContactList(filterData);
    } else {
      setContactList(copiedList);
    }
  };

  const getAllContactsHanlder = async () => {
    setIsLoading(true);
    const res = await getAllContacts();
   
    if (res.status) {
      res.data.forEach((element) => {
        element.isActive = false;
        element.unreadMessage = false;

        for (const list of unreadMessagesUserList) {
          if (list._id === element._id) {
            element.unreadMessage = true;
            element.messageCount = list.count;
          }
        }
      });
      setCopiedList(res.data);
      setContactList(res.data);
      setIsLoading(false);
    } else {
      setContactList([]);
      setIsLoading(false);
    }
    console.log("API Response:", res.data);
  };
// Forget Password module
// Follower list
// Image wala
//chat module-->
  useEffect(() => {
    getAllContactsHanlder();
  }, []);

  return (
    <>
      <Container fluid className="h-100">
        <Row className="h-100 pb-5">
          <Col md={3}>
            <div className="contact_wrap">
              <div className="head">
                <h5>Contacts</h5>
              </div>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <i className="icon search"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl placeholder="Search" onChange={handleSearch} />
              </InputGroup>
              {isLoading ? (
                <ContactListLoader />
              ) : (
                <div className="contact_list">
                  <ul>
                    {contactList.map((f) => (
                      <div
                        onClick={() => {
                          const tempData = [...contactList];
                          tempData.forEach((element) => {
                            if (element._id === f._id) {
                              element.isActive = true;
                              element.unreadMessage = false;
                            } else {
                              element.isActive = false;
                              element.unreadMessage = false;
                            }
                          });
                          onSubmitMessageCount(0);
                          setContactList(tempData);
                          history.push(`/messages/user/${f._id}`);
                          setCurrentChat(f);
                        }}
                      >
                        <Conversation
                          conversation={f}
                          currentUser={userDetails}
                        />
                      </div>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </Col>
          <Col md={6}>
            {conversationId ? (
              <div className="message_wrap">
                <div className="head">
                  {currentChat?.profilePic ? (
                    <img src={currentChat?.profilePic} alt="" />
                  ) : (
                    <span class="dp_name">
                      {currentChat?.username?.charAt(0)}
                    </span>
                  )}

                  <div className="details">
                    <h5>@{currentChat?.username}</h5>
                    <p>
                      <small>{currentChat?.location}</small>
                    </p>
                  </div>
                </div>

                <div className="centerit">
                  <div className="chat-container">
                    <main>
                      <div className="messages" id="messages">
                        <div>
                          {messages.map((m) => (
                            <Messages
                              message={m}
                              own={m.senderId === userDetails._id}
                              senderId={conversationId}
                              currentChat={currentChat}
                            />
                          ))} 
                          <div ref={messagesEndRef} />
                        </div>
                      </div>
                      <div className="bottom-bar">
                        <div className="options">
                          <ul>
                            {/* <li>
                              <span>
                                <i className="icon image"></i>
                              </span>
                            </li> */}
                            {/* <li>
                              <span>
                                <i className="icon paper-clip"></i>
                              </span>
                            </li> */}
                            <li>
                              <span onClick={handleShowEmojiOpen}>
                                <i className="icon smile"></i>
                              </span>
                            </li>
                          </ul>
                        </div>
                        <form className="d-flex w-100" onSubmit={handleSubmit}>
                          <input
                            type="text"
                            className="msg-input"
                            onChange={(e) => setNewMessage(e.target.value)}
                            value={newMessage}
                            placeholder="New Message"
                            autoFocus
                          ></input>
                          <button type="submit" className="send-button">
                            <i className="icon send"></i>
                          </button>
                        </form>
                      </div>
                      {isEmojiOpen && (
                        <Picker
                          onEmojiClick={onEmojiClick}
                          pickerStyle={{ width: "100%" }}
                        />
                      )}
                    </main>
                  </div>
                </div>
              </div>
            ) : (
              <div className="message_wrap h-100">
                <div className="chat-container align-items-center justify-content-center">
                  <div className="center_no_chat">
                    <QuestionAnswerIcon />
                    <h5>Your Messages</h5>
                    <p>Start messaging to your friends and get connected!</p>
                  </div>
                </div>
              </div>
            )}
          </Col>
          <Col md={3}></Col>
        </Row>
      </Container>
    </>
  );
};

export default Messanger;

import axios from "axios";

export const joinStream = async (id) => {
  try {
    const { data } = await axios.get(`stream/joinStream/${id}?returnJson=true`);
    return data;
  } catch (err) {
    return err.response;
  }
};
export const endStream = async (id) => {
  try {
    const { data } = await axios.post(`stream/endStream/${id}`);
    return data;
  } catch (err) {
    return err.response;
  }
};
export const postCommentStream = async (id, message) => {
  try {
    const { data } = await axios.post(
      `stream/chat?type=stream&streamId=${id}`,
      {
        message: message,
      }
    );
    return data;
  } catch (err) {
    return err.response;
  }
};
export const getCommentStream = async (id) => {
  try {
    const { data } = await axios.get(`chats?type=stream&streamId=${id}`);
    return data;
  } catch (err) {
    return err.response;
  }
};
export const getStreamRefresh = async (id) => {
  try {
    const { data } = await axios.get(`/stream/${id}`);
    return data;
  } catch (err) {
    return err.response;
  }
};
export const updateStreamRefresh = async (id) => {
  try {
    const { data } = await axios.put(`/stream/${id}`);
    return data;
  } catch (err) {
    return err.response;
  }
};

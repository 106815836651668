import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { Container, Nav, Navbar } from "react-bootstrap";

export default function HomeNavbar() {
  return (
    <>
      <header>
        <Navbar bg="dark" variant="dark">
          <Container>
            <Nav className="w-100 justify-content-center">
              <Link
                className="nav-link"
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Home
              </Link>
              {/* <Link
                className="nav-link"
                activeClass="active"
                to="streaming"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Live Stream
              </Link>
              <Link
                className="nav-link"
                activeClass="active"
                to="rvtToken"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                RVT Token
              </Link> */}
              <Link
                className="nav-link"
                activeClass="active"
                to="rvtToken"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                FANS and INFLUENCERS
              </Link>
              {/*  */}
              {/* <Link
                className="nav-link"
                activeClass="active"
                to="games"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Game
              </Link> */}
              {/* <Link
                className="nav-link"
                activeClass="active"
                to="streaming"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Streaming
              </Link>
              <Link
                className="nav-link"
                activeClass="active"
                to="company"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Company
              </Link>
              <Link
                className="nav-link"
                activeClass="active"
                to="product"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Product
              </Link> */}
            </Nav>
          </Container>
        </Navbar>
      </header>
    </>
  );
}

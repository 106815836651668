import React, { useEffect, useState } from "react";
import moment from "moment";

function Timer({ streamData }) {
  const [streamDuration, setStreamDuration] = useState("");

  useEffect(() => {
    if (streamData.startTime) {
      setInterval(() => {
        let startTime = moment
          .utc(streamData.startTime)
          .local()
          .format("MM/DD/YYYY, HH:mm:ss");
        let endTime = moment().format("MM/DD/YYYY, HH:mm:ss");
        let now = endTime;
        let then = startTime;

        setStreamDuration(
          moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss")
        );
      }, 1000);
    }
  }, [streamData?.startTime]);

  return (
    <>
      <span>{streamDuration}</span>
    </>
  );
}

export default Timer;

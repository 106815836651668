import React, { useEffect, useState, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Oven from "../../assets/img/oven.png";
import StarIcon from "@material-ui/icons/Star";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Button } from "@material-ui/core";
import ProductFooter from "./ProductFooter";
import { useHistory } from "react-router";

import {
  getProductDetail,
  getUserAddressById,
  placeBidProduct,
  placeOrder,
} from "./ProductService";
import { useParams } from "react-router-dom";
import { useUser } from "../../context/UserProvider";
import { LowBalanceModal } from "../Shared/LowBalanceModal";
import SimpleSnackbar from "../../components/UI/SnakBar";
import { getCountryByCode } from "../../utils/common";
import useQuery from "../../hooks/useQueryHook";
import moment from "moment";

/**
 * @author
 * @function Orders
 **/

const Orders = (props) => {
  let history = useHistory();
  const { productId } = useParams();
  const { userData } = useUser();
  const query = useQuery();
  const [addressDetails, setAddressDetails] = useState({});
  const [productDetails, setProductDetails] = useState({});
  const [showBalanceAddModal, setBalanceAddModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseBalanceAddModal = () => setBalanceAddModal(false);
  const handleShowBalanceAddModal = () => setBalanceAddModal(true);

  const placeOrderHandler = async () => {
    if (userData.balance < productDetails.amount) {
      handleShowBalanceAddModal();
      return;
    }
    const payload = {
      quantity: 1,
      addressId: addressDetails._id,
    };
    const res = await placeOrder(productDetails._id, payload);

    if (res && res.status) {
      history.push(
        `/product/detail/order/success/${productDetails._id}-${addressDetails._id}`
      );
    } else {
      setOpen(true);
      setMessage(res?.response?.data?.message);
    }
  };

  const getUserAddressByIdHandler = useCallback(async () => {
    const id = productId.split("-")[1];
    const res = await getUserAddressById(id);

    if (res && res.status) {
      setAddressDetails(res.data);
    }
  }, [productId]);

  const getProductDetailHandler = useCallback(async () => {
    const id = productId.split("-")[0];
    const res = await getProductDetail(id);
    if (res && res.status) {
      setProductDetails(res.data);
    }
  }, [productId]);

  useEffect(() => {
    getUserAddressByIdHandler();
    getProductDetailHandler();
  }, [getProductDetailHandler, getUserAddressByIdHandler]);

  const estimatedDate = moment().add(10, "days").format("dddd, MMM Do YYYY");

  return (
    <>
      <div className="orders_wrap border-top">
        <div className="head">
          <Container fluid>
            <h5>Order Now</h5>
            <p>
              Shipping to : <strong>{addressDetails.fullName}</strong> -{" "}
              {addressDetails.address} - {addressDetails.pincode}{" "}
              {addressDetails.city}, {getCountryByCode(addressDetails.country)}
            </p>
            <small>
              Estimated delivery : <span>{estimatedDate}</span>
            </small>
          </Container>
        </div>
        <div className="product_details_col">
          <Container fluid>
            <Row>
              <Col md={5}>
                <div className="p_image">
                  {productDetails?.mediaLinks?.slice(0, 1).map((img) => {
                    return <img src={img.location} alt="" />;
                  })}
                </div>
              </Col>
              <Col md={7}>
                <div className="details_right">
                  <h5 className="brand">{productDetails.brandName}</h5>
                  <h4>{productDetails.productName}</h4>
                  <h2>
                    <i className="icon revit_coin"></i> {productDetails.amount}
                  </h2>
                  {/* <p className="delivery">Free Delivery</p> */}

                  {/* <ul className="star">
                    <li>
                      <StarIcon className="full" />
                    </li>
                    <li>
                      <StarIcon className="full" />
                    </li>
                    <li>
                      <StarIcon className="full" />
                    </li>
                    <li>
                      <StarOutlineIcon className="outline" />
                    </li>
                    <li>
                      <StarOutlineIcon className="outline" />
                    </li>
                  </ul>

                  <p className="review">
                    <small>4,377 Ratings & 63 Reviews</small>
                  </p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="deliver_to_wrap">
          <Container fluid>
            <div className="col_row">
              <div className="col_left">
                <h5>Deliver to:</h5>
                <p>
                  <span>{addressDetails.fullName}</span>
                  <br />

                  <p>
                    {addressDetails.address} - {addressDetails.pincode}{" "}
                    {addressDetails.city},{" "}
                    {getCountryByCode(addressDetails.country)}
                  </p>
                </p>
              </div>
              <div className="col_right">
                <table>
                  <tr>
                    <td>Item:</td>
                    <td>
                      <i className="icon revit_coin"></i>
                      {productDetails.amount}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Delivery:</td>
                    <td>
                      <i className="icon revit_coin"></i>21.00
                    </td>
                  </tr> */}
                  <tr>
                    <td>Total:</td>
                    <td>
                      <i className="icon revit_coin"></i>
                      {productDetails.amount}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Wallet Balance:</td>
                    <td>
                      <i className="icon revit_coin"></i>0.00
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      <strong> Order Total:</strong>
                    </td>
                    <td>
                      <span className="d-flex align-items-center">
                        <i className="icon revit_coin"></i>
                        {productDetails.amount}
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </Container>
        </div>
        {/* <div className="pay_with_wrap">
          <h5>
            <span>Pay with :</span> Axis Bank Debit Card ending in 3210
          </h5>
          <ChevronRightIcon />
        </div>
        <div className="pay_with_wrap billing">
          <h5>
            <span>Billing Address :</span> Same as delivery address
          </h5>
          <ChevronRightIcon />
        </div> */}
        <div className="select_address_button">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            onClick={() => {
              placeOrderHandler();
            }}
          >
            Place Your Order
          </Button>
        </div>
        <ProductFooter />
      </div>
      <LowBalanceModal
        show={showBalanceAddModal}
        close={handleCloseBalanceAddModal}
      />
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default Orders;

import { Button } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import DP from "../../assets/img/followers.png";
import LocalStorageService from "../../utils/LocalStorageService";
import { userWhoLikedFeed } from "./FeedService";

/**
 * @author
 * @function Followers
 **/

const UserListOnComment = (props) => {
  const { feedId } = props;
  const userDetails = LocalStorageService.getItem("userDetail");
  const [userlistOfLiked, setUserListOfLiked] = useState([]);

  const userWhoLikedFeedHandler = useCallback(async () => {
    const res = await userWhoLikedFeed(feedId);

    if (res && res.status) {
      setUserListOfLiked(res.data.likedBy);
    }
  }, [feedId]);

  useEffect(() => {
    userWhoLikedFeedHandler();
  }, [userWhoLikedFeedHandler]);
  return (
    <>
      <p className="followers_text">{userlistOfLiked.length} Users</p>
      <div className="followers_wrap">
        <div className="body">
          <ul>
            {userlistOfLiked.map((item) => {
              return (
                <li key={item._id}>
                  <div className="user_wrap">
                    {item.profilePic ? (
                      <img src={item.profilePic} alt="" />
                    ) : (
                      <span className="dp_name">{item.name.charAt(0)}</span>
                    )}
                    <div className="content">
                      <h6
                        onClick={() =>
                          (window.location.href = `/profile/${item._id}`)
                        }
                      >
                        {item.name}
                      </h6>
                      <p>Canberra, Australia</p>
                    </div>
                  </div>
                  {userDetails._id !== item._id && (
                    <div className="button_wrap">
                      <Button variant="contained" size="small" color="primary">
                        Follow
                      </Button>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default UserListOnComment;

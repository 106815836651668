import axios from "axios";

export const getBuyerOrders = async (type = "") => {
  try {
    const { data } = await axios.get(
      `user/profile/transactions?page=1&type=BUY_SALE${type}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getAllMyBids = async () => {
  try {
    const { data } = await axios.get(`user/profile/bids`);
    return data;
  } catch (err) {
    return err;
  }
};
export const cancelBid = async (productId) => {
  try {
    const { data } = await axios.post(`store/product/bid/${productId}/cancel`);
    return data;
  } catch (err) {
    return err;
  }
};
export const updateStatus = async (id, payload) => {
  try {
    const { data } = await axios.post(
      `store/product/${id}/updateStatus`,
      payload
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getStoreDetails = async () => {
  try {
    const { data } = await axios.get(`store/details`);
    return data;
  } catch (err) {
    return err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBuyerOrders,
  updateStatus,
  getStoreDetails,
  getAllMyBids,
};

import { Button } from "@material-ui/core";
import React, { useRef } from "react";
import ProfilePicture from "../../components/UI/ProfilePictureDPUpload";
// import 'profile-picture/build/ProfilePicture.css'

/**
 * @author
 * @function UploadProfilePicture
 **/

const UploadProfilePicture = (props) => {
  const { getUserPicture, closeModal } = props;
  const profilePictureRef = useRef(null);

  const handleUpload = () => {
    const PP = profilePictureRef.current;
    const imageAsDataURL = PP.getImageAsDataUrl();

    const block = imageAsDataURL.split(";");
    const contentType = block[0].split(":")[1];
    const realData = block[1].split(",")[1];
    const blob = b64toBlob(realData, contentType);

    getUserPicture("profile", blob, imageAsDataURL);
    closeModal();
  };
  const b64toBlob = (b64Data, contentType, sliceSize) => {
    const sliceSizeReduced = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (
      let offset = 0;
      offset < byteCharacters.length;
      offset += sliceSizeReduced
    ) {
      const slice = byteCharacters.slice(offset, offset + sliceSizeReduced);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType || "" });

    return blob;
  };
  return (
    <>
      <ProfilePicture
        ref={profilePictureRef}
        useHelper={true}
        debug={true}
        frameFormat="circle"
        frameSize={170}
        framHeight={170}
        cropSize={170}
        cropHeight={170}
        minImageSize={50}
      />
      <div className="buttons">
        <Button variant="outlined" color="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className="ml-57"
          onClick={handleUpload}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default UploadProfilePicture;

import React from "react";
import { Modal } from "react-bootstrap";

/**
 * @author
 * @function CustomModal
 **/

const CustomModal = (props) => {
  const {
    show,
    handleClose,
    title,
    children,
    size,
    className,
    isEmojiOpen,
    handleCloseEmojiOpen,
  } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size={size}
        centered
        className={className}
      >
        <Modal.Header>
          <Modal.Title>
            {isEmojiOpen ? "Select Emoji!" : title}

            <span
              className="close"
              onClick={isEmojiOpen ? handleCloseEmojiOpen : handleClose}
            >
              <i className="icon cancel"></i>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};

export default CustomModal;

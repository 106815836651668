import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import DatePicker from "../../components/UI/DatePicker";
import {
  addProductAPI,
  getCateories,
  getProductDetail,
  updateProduct,
} from "./ProductService";
import { useUploadFiles } from "../../context/UploadFilesProvider";
import moment from "moment";
import SimpleSnackbar from "../../components/UI/SnakBar";
import isEmpty from "is-empty";

/**
 * @author
 * @function AddProduct
 **/

const AddProduct = (props) => {
  let history = useHistory();
  const { id } = useParams();
  const {
    uploadFilesHandler,
    uploadedFiles,
    removeFiles,
    imgUrl,
    setImgUrl,
    onSubmitUploadFilesHandler,
  } = useUploadFiles();

  const [productDetails, setProductDetails] = useState({});
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [uploadedFileLinks, setUploadedFileLinks] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const cancelHandler = () => {
    history.push("/my-product");
  };

  const handlerChange = (e) => {
    const { name, value } = e.target;

    if (value !== "-1") {
    }
    setProductDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    const files = e.target.files;

    for (const file of files) {
      uploadFilesHandler(file, );
    }
  };

  function guidGenerator() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  }

  const resetProductDetails = () => {
    setProductDetails((prev) => ({
      ...prev,
      productName: "",
      brandName: "",
      productBidAmount: "",
      amount: "",
      discription: "",
    }));

    setImgUrl([]);
  };

  const getCateoriesHandler = async () => {
    const res = await getCateories();

    if (res && res.status) {
      setCategories(res.data);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const {
      itemType,
      productName,
      brandName,
      productType,
      productBidAmount,
      amount,
      discription,
      bidExpireyDate,
    } = productDetails;
    const payload = {
      productName,
      streamId: guidGenerator(),
      brandName,
      productType: itemType,
      description: discription,
      amount: itemType === "AUCTION" ? productBidAmount : amount,
      links: uploadedFiles,
      productCategory: productType,
    };
    setIsLoading(true);
    if (itemType === "AUCTION") {
      payload.bidExpireyDate = bidExpireyDate ? bidExpireyDate : "";
    }
    if (id) {
      payload.links = uploadedFiles;
      const res = await updateProduct(id, payload);

      if (res && res.status) {
        setIsLoading(false);
        history.push("/my-product");
        resetProductDetails();
      } else {
        setOpen(true);
        setMessage(res?.response?.data?.message || "Something went wrong");
        setIsLoading(false);
      }
    } else {
      const res = await addProductAPI(payload);

      if (res && res.status) {
        setIsLoading(false);
        history.push("/my-product");
      } else {
        setOpen(true);
        setMessage(res?.response?.data?.message || "Something went wrong");
        setIsLoading(false);
      }
    }
  };

  const getProductDetailHandler = async () => {
    const res = await getProductDetail(id);

    if (res && res.status) {
      const {
        productName,
        brandName,
        productType,
        amount,
        description,
        mediaLinks,
        productCategory,
        links,
        bidExpireyDate,
      } = res.data;
      setProductDetails({
        productName,
        brandName,
        itemType: productType,
        amount,
        discription: description,
        productType: productCategory,
        bidExpireyDate,
        productBidAmount: amount,
      });
      setUploadedFileLinks(links);
      onSubmitUploadFilesHandler(links);

      let tempUrl = [];

      for (const url of mediaLinks) {
        tempUrl.push(url.location);
      }

      setImgUrl(tempUrl);
    }
  };

  const handleDateChange = (date) => {
    let dateFormatted = moment(date).format("YYYY-MM-DD");

    setProductDetails((prevState) => ({
      ...prevState,
      bidExpireyDate: dateFormatted,
    }));
  };

  useEffect(() => {
    getCateoriesHandler();
    if (id) {
      getProductDetailHandler();
    }
    document.body.classList.add("is-hide-contact");
    return () => {
      document.body.classList.remove("is-hide-contact");
      setImgUrl([]);
    };
  }, []);

  const isDisabledBtn = () => {
    let isValid = false; 
    
    if (
      productDetails?.itemType === "-1" ||
      isEmpty(productDetails?.productName) ||
      imgUrl.length === 0 ||
      isEmpty(productDetails?.discription)
    ) {
      isValid = true;
    }

    if (productDetails.itemType === "AUCTION") {
      if (isEmpty(productDetails.productBidAmount)) {
        isValid = true;
      }
    } else {
      if (isEmpty(productDetails.amount)) {
        isValid = true;
      }
    }

    return isValid;
  };

  useEffect(() => {
    if (id) {
      return;
    }
    if (productDetails.itemType === "AUCTION") {
      resetProductDetails();
    } else {
      resetProductDetails();
    }
  }, [id, productDetails.itemType]);

  return (
    <>
      <div className="add_wrap my_product_wrap">
        <div className={`head`}>
          <h5>Add Product</h5>
        </div>
        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Label>Store/Auction *</Form.Label>
            {id ? (
              productDetails.itemType === "SALE" ? (
                <p>PRODUCT</p>
              ) : (
                <p>{productDetails?.itemType}</p>
              )
            ) : (
              <Form.Control
                as="select"
                name="itemType"
                value={productDetails?.itemType}
                onChange={(e) => {
                  handlerChange(e);
                }}
              >
                <option value="-1">--Select Item Type--</option>
                <option value="SALE">Store</option>
                <option value="AUCTION">Auction</option>
              </Form.Control>
            )}
          </Form.Group>

          <Row>
            <Col>
              <Form.Label>Product Name *</Form.Label>
              <Form.Control
                placeholder="Enter product name"
                name="productName"
                onChange={handlerChange}
                value={productDetails?.productName}
                style={{
                  width:
                    productDetails.itemType === "AUCTION" ? "100%" : "100%",
                }}
              />
            </Col>
            {productDetails.itemType !== "AUCTION" && (
              <Col>
                <Form.Label>Price</Form.Label>
                <Form.Control
                  placeholder="Enter product Amount"
                  name="amount"
                  onChange={handlerChange}
                  value={productDetails?.amount}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Col>
            )}
          </Row>

          <Form.Group style={{ marginTop: "20px" }}>
            <Form.Label>Description *</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="discription"
              onChange={handlerChange}
              value={productDetails?.discription}
            />
          </Form.Group>

          <Form.Group>
            <Row>
              <Col>
                {productDetails.itemType === "AUCTION" ? (
                  <>
                    <Form.Label>Product Bids *</Form.Label>
                    <Form.Control
                      placeholder="Enter product bid"
                      name="productBidAmount"
                      onChange={handlerChange}
                      value={productDetails?.productBidAmount}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </>
                ) : null}
              </Col>
              {productDetails.itemType === "AUCTION" && (
                <Col>
                  <Form.Label>Product Bids Expire Date *</Form.Label>
                  <DatePicker
                    onDateChange={handleDateChange}
                    setSelectedDate={handleDateChange}
                    selectedDate={productDetails.bidExpireyDate}
                  />
                </Col>
              )}
            </Row>
          </Form.Group>

          <Form.Group style={{ marginTop: "20px" }}>
            <Row>
              <Col md={imgUrl.length > 0 ? 6 : 12}>
                <Form.Label> Product Image/Video *</Form.Label>

                <label
                  style={{ cursor: "pointer" }}
                  htmlFor="upload-photo"
                  className="w-100"
                >
                  <input
                    style={{ display: "none" }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    accept="image/*"
                  />

                  <div className="drag_wrap">
                    <i className="icon upload"></i>
                    <p>Select files</p>
                  </div>
                </label>
              </Col>
              {imgUrl.length > 0 && (
                <Col>
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor="upload-photo"
                    className="w-100"
                  >
                    <input
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      multiple
                      onChange={handleFileChange}
                      accept="image/*"
                    />

                    <p className="add_more">
                      <i className="icon add"></i> Add more images
                    </p>
                  </label>

                  {imgUrl.map((url, i) => {
                    return (
                      <div className="files_wrap">
                        <span
                          className="remove_file"
                          onClick={() => removeFiles(i)}
                        >
                          <i className="icon cancel"></i>
                        </span>
                        <img src={url} alt="" />
                      </div>
                    );
                  })}
                </Col>
              )}
            </Row>
          </Form.Group>

          <div className="form_buttons">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isDisabledBtn()}
            >
              Save 
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className="ml-99"
              onClick={cancelHandler}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default AddProduct;

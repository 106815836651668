import React from "react";

import { Route, Switch } from "react-router";
import GamesContainer from "../../containers/Games";
import GamePlay from "../../containers/Games/GamePlay";
import Messanger from "../../containers/Messanger";
import Privacy from "../../containers/Privacy";
import Address from "../../containers/Products/Address";
import Orders from "../../containers/Products/Orders";
import OrderSuccess from "../../containers/Products/OrderSuccess";

import ProductDetails from "../../containers/Products/ProductDetails";

import HeaderComponent from "../shared/Header";
import PageNotFound from "../shared/PageNotFound";

const FullLayout = () => {
  return (
    <>
      <HeaderComponent />

      <div className="full_wrapper">
        <Switch>
          <Route exact path="/product/detail/:id" component={ProductDetails} />
          <Route
            exact
            path="/product/detail/address/:productId"
            component={Address}
          />
          <Route
            exact
            path="/product/detail/order/:productId"
            component={Orders}
          />

          <Route
            exact
            path="/product/detail/order/success/:productId"
            component={OrderSuccess}
          />
          <Route exact path="/messages" component={Messanger} />
          <Route
            exact
            path="/messages/user/:conversationId"
            component={Messanger}
          />

          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default FullLayout;

import "date-fns";
import React, { useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default function MaterialUIPickers(props) {
  const { onDateChange, fromEditProfile, selectedDate, setSelectedDate } =
    props;
  // The first commit of Material-UI

  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onDateChange(date);
    setIsOpen(false);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        autoOk
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        value={selectedDate}
        onChange={handleDateChange}
        keyboardIcon={<i className="icon calendar"></i>}
        disablePast={fromEditProfile ? false : true}
        KeyboardButtonProps={{
          onFocus: (e) => {
            setIsOpen(true);
          },
        }}
        PopoverProps={{
          disableRestoreFocus: true,
          onClose: () => {
            setIsOpen(false);
          },
        }}
        InputProps={{
          onFocus: () => {
            setIsOpen(true);
          },
        }}
        open={isOpen}
      />
    </MuiPickersUtilsProvider>
  );
}

import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Game from "../Game";

/**
 * @author
 * @function GamePlay
 **/

const GamePlay = (props) => {
  useEffect(() => {
    document.body.classList.add("is-hide-contact");
    return () => {
      document.body.classList.remove("is-hide-contact");
    };
  }, []);
  return (
    <>
      <div className="games_wrapper pt-0 game_play_wrapper">
        <Container>
          <Row>
            <Col md={12}>
              <Game />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default GamePlay;

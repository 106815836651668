import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ProductFooter from "./ProductFooter";
import { useHistory, useParams } from "react-router";
import { getUserAddress, placeBidProduct } from "./ProductService";
import CustomModal from "../../components/UI/Modal";
import AddAddress from "./AddAddress";
import { getCountryByCode } from "../../utils/common";
import useQuery from "../../hooks/useQueryHook";
import SimpleSnackbar from "../../components/UI/SnakBar";

/**
 * @author
 * @function Address
 **/

const Address = (props) => {
  let history = useHistory();
  const { productId } = useParams();
  const query = useQuery();
  const [value, setValue] = useState("add1");
  const [valueShipping, setValueShipping] = useState("add_shipping1");
  const [userAddressList, setUserAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({
    billing: null,
    shipping: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  const handleBillingChange = (event, data) => {
    setValue(event.target.value);
  };
  const selectBillingAddressHandler = (data) => {
    setSelectedAddress((prevState) => ({ ...prevState, billing: data }));
  };
  const selectShippingAddressHandler = (data) => {
    setSelectedAddress((prevState) => ({ ...prevState, shipping: data }));
  };
  const handleShippingChange = (event, data) => {
    setValueShipping(event.target.value);
  };

  const selectAddressHandler = () => {
    if (query.get("bid")) {
      placeBidProductHandler();
    } else {
      history.push(
        `/product/detail/order/${productId}-${selectedAddress.billing._id}`
      );
    }
  };

  const placeBidProductHandler = async () => {
    const payload = {
      bidAmount: parseInt(query.get("bid")),
      addressId: selectedAddress.billing._id,
    };
    const res = await placeBidProduct(productId, payload);
    if (res && res.status) {
      history.push(`/product/detail/${productId}?bidPlaced=true`);
    } else {
      setOpen(true);
      setMessage(res?.response?.data?.message);
    }
  };

  const getUserAddressHandler = async () => {
    const res = await getUserAddress();

    if (res && res.status) {
      setUserAddressList(res.data);
      res.data.forEach((element, i) => {
        if (i === 0) {
          setSelectedAddress({
            billing: element,
            shipping: element,
          });
        }
      });
    }
  };

  useEffect(() => {
    getUserAddressHandler();
  }, []);

  return (
    <>
      <div className="address_wrapper border-top">
        <Container fluid>
          <FormControl component="fieldset">
            <FormLabel component="legend">Choose a Billing Address</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="address"
              value={value}
              onChange={handleBillingChange}
            >
              {userAddressList.map((a, i) => (
                <FormControlLabel
                  value={`add${i + 1}`}
                  control={<Radio />}
                  onChange={() => selectBillingAddressHandler(a)}
                  label={
                    <>
                      <p>{a.fullName}</p>
                      <small>
                        {a.address}, - {a.pincode} {a.city},{" "}
                        {getCountryByCode(a.country)}
                      </small>
                    </>
                  }
                />
              ))}

              {/* <FormControlLabel
                value='add2'
                control={<Radio />}
                label={
                  <>
                    <p>Alien Lee</p>
                    <small>
                      Flat No. 32/40, Building No. 430, Main Street, Chattarpur
                      - 110074 NEW DELHI, INDIA
                    </small>
                  </>
                }
              /> */}
            </RadioGroup>
          </FormControl>
        </Container>
      </div>
      <div className="add_new_address_wrap">
        <h5 onClick={handleOpenModal}>Add New Address</h5>
        <ChevronRightIcon />
      </div>
      {/* <div className="address_wrapper ">
        <Container fluid>
          <FormControl component="fieldset">
            <FormLabel component="legend">Choose a Shipping Address</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="address"
              value={valueShipping}
              onChange={handleShippingChange}
            >
              {userAddressList.map((a, i) => (
                <FormControlLabel
                  value={`add_shipping${i + 1}`}
                  onChange={() => selectShippingAddressHandler(a)}
                  control={<Radio />}
                  label={
                    <>
                      <p>{a.fullName}</p>
                      <small>
                        {a.address}, - {a.pincode} {a.city}, INDIA
                      </small>
                    </>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Container>
      </div> */}

      <div className="select_address_button">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          onClick={selectAddressHandler}
        >
          Select your address
        </Button>
      </div>
      <ProductFooter />
      <CustomModal
        show={showModal}
        handleClose={handleCloseModal}
        title="Add Address"
        size="lg"
      >
        <AddAddress
          fromAddress
          handleCloseModal={handleCloseModal}
          getUserAddressHandler={getUserAddressHandler}
        />
        {/* <Photos /> */}
      </CustomModal>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default Address;

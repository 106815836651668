import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getBuyerOrders } from "../../Service";

/**
 * @author
 * @function SettlementDetails
 **/

export const SettlementDetails = (props) => {
  const [orderList, setOrderList] = useState([]);
  useEffect(() => {
    document.body.classList.add("is-hide-contact");
    return () => {
      document.body.classList.remove("is-hide-contact");
    };
  }, []);

  const getBuyerOrdersHandler = async () => {
    const res = await getBuyerOrders(`&subType=SELL`);

    if (res.status) {
      for (const data of res.data) {
        setOrderList(data.data);
      }
    }

   
  };

  useEffect(() => {
    getBuyerOrdersHandler();
  }, []);

  return (
    <>
      <div className="settlement_wrapper">
        <div className="head">
          <h5>Settlement Details</h5>
        </div>
        <div className="body">
          {orderList.length === 0 && (
            <h5 className="text-center ">No Data Found</h5>
          )}
          {orderList.length > 0 && (
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Product Name</th>
                  <th>Order Amount</th>
                  <th>Amount Recieved</th>
                  <th>Escrow Amount</th>
                  <th>Buyer Details</th>
                </tr>
              </thead>
              <tbody>
                {orderList.map((p) => (
                  <tr key={p._id}>
                    <td>{p._id}</td>
                    <td>{p?.product?.productName}</td>
                    <td>
                      {" "}
                      <p class="p_price">
                        <i class="icon revit_coin"></i> {p?.product?.amount}
                      </p>
                    </td>
                    <td>{p?.data?.amount60Percent}</td>
                    <td>{p?.data?.amount40Percent}</td>
                    <td>
                      <p className="mb-0">
                        {p.buyer.email}
                        <br />
                        {p?.data?.addressDetails.map((item, i) => {
                          if (i === 0) {
                            return (
                              <Link to={`/profile/${p.buyer._id}`}>{item}</Link>
                            );
                          }
                          return <p>{item}</p>;
                        })}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

import React, { createContext, useContext } from "react";
import { useState } from "react";

const NotificationContext = createContext();

export function useNotifications() {
  return useContext(NotificationContext);
}

export default function NotificationProvider({ children }) {
  const [allNotifications, setAllNotifications] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [unreadMessagesUserList, setUnreadMessagesUserList] = useState([]);

  const onSubmitNotificationHandler = (data) => {
    setAllNotifications(data);
  };

  const onSubmitMessageCount = (data) => {
    setMessageCount(data);
  };

  const onSubmitUnreadMessageUserList = (data) => {
    setUnreadMessagesUserList(data);
  };

  const values = {
    allNotifications,
    messageCount,
    unreadMessagesUserList,
    onSubmitNotificationHandler,
    onSubmitMessageCount,
    onSubmitUnreadMessageUserList,
  };
  return (
    <NotificationContext.Provider value={values}>
      {children}
    </NotificationContext.Provider>
  );
}

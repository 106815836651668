import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "@material-ui/core";
import LocalStorageService from "../../utils/LocalStorageService";
import { followUser, unFollowUser } from "./ProfileService";
import DefaultCoverPic from "../../assets/img/default_cover.jpg";

import { useUser } from "../../context/UserProvider";
import Loader from "../../components/SkeletonLoaders/Loader";
import isEmpty from "is-empty";

export default function ProfileHead({
  handleShowEditModal,
  handleShowFollowersModal,
  handleShowPhotosModal,
  handleShowStreamModal,
  handleShowFollowingModal,
  loggedInUserDetails,
  getUserProfileHandler,
}) {
  const { userOfData, isDataLoading } = useUser();
  const userDetails = LocalStorageService.getItem("userDetail");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const isOtherUser = userOfData?._id === userDetails?._id ? false : true;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const followUserHandler = async (id) => {
    const res = await followUser(id);

    if (res && res.status) {
      setOpen(true);
      setMessage("You have started following");
      getUserProfileHandler();
    } else {
      setOpen(true);
      setMessage("Something went wrong");
    }
  };
  const unFollowUserHandler = async (id) => {
    const res = await unFollowUser(id);

    if (res && res.status) {
      setOpen(true);
      setMessage("You have unfollowed");
      getUserProfileHandler();
    } else {
      setOpen(true);
      setMessage("Something went wrong");
    }
  };

  useEffect(() => {
    if (!isEmpty(userOfData)) {
      setIsDataLoaded(true);
    }
  }, [userOfData]);

  return (
    <>
      {!isDataLoaded ? (
        <Loader height="400px" width="100%" lines={1} />
      ) : (
        <div
          className="profile_banner"
          style={{
            backgroundImage: `url(${
              userOfData?.coverPic ? userOfData?.coverPic : DefaultCoverPic
            })`,
          }}
        ></div>
      )}

      <div className="profile_head">
        <div className="profile_dp">

        {
        isDataLoading ? (
         <Loader width="200px" lines={5} />
          ) : (
          userOfData?.profilePic ? (
           <img src={userOfData?.profilePic} alt="" loading="lazy" />
           ) : (
         <span className="dp_name">{userOfData?.name?.charAt(0)}</span>
         )
       )
      }

          {/* {userOfData?.profilePic ? (
            <img src={userOfData?.profilePic} alt="" />
          ) : (
            <span className="dp_name">{userOfData?.name?.charAt(0)}</span>
          )} */}
        </div>
        <div className="profile_content">
  <div className={isOtherUser ? "centered_content_wrap" : "content_wrap"}>
    {isDataLoading ? (
      <Loader width="200px" lines={2} />
    ) : (
      <>
        <h2>
          @{userOfData?.username ? userOfData?.username : "username"}
        </h2>
        <p>{userOfData?.location}</p>
      </>
    )}
  </div>
  {!isOtherUser && (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleShowEditModal}
    >
      Edit
    </Button>
  )}
</div>

      </div>
      <div className="profile_head_bottom">
        {isOtherUser ? (
          <>
            {loggedInUserDetails?.following?.some(
              (p) => p === userOfData?._id
            ) ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => unFollowUserHandler(userOfData?._id)}
              >
                Unfollow
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => followUserHandler(userOfData?._id)}
              >
                Follow
              </Button>
            )}
          </>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleShowStreamModal}
          >
            Start Stream
          </Button>
        )}

        <ul>
          <li>
            {isDataLoading ? (
              <Loader width="100px" lines="1" />
            ) : (
              <NavLink
                activeClassName="active"
                to={`/profile/${userOfData?._id}/products`}
              >
                {userOfData?.productCount > 0 && (
                  <>
                    <span>{userOfData?.productCount}</span> Products
                  </>
                )}
              </NavLink>
            )}
          </li>
          <li onClick={handleShowFollowersModal}>
            {isDataLoading ? (
              <Loader width="100px" lines="1" />
            ) : (
              <>
                <span>{userOfData?.followerCount} </span>Followers
              </>
            )}
          </li>
          <li onClick={handleShowFollowingModal}>
            {isDataLoading ? (
              <Loader width="100px" lines="1" />
            ) : (
              <>
                <span>{userOfData?.following?.length} </span>Following
              </>
            )}
          </li>

          <li onClick={handleShowPhotosModal}>Photos</li>
        </ul>
      </div>
    </>
  );
}

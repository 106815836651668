

import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";

import validator from "validator";

import {
  addAddressAPI,
  getUserAddressById,
  updateUserAddress,
  getStateCityByZipCode,
} from "./ProductService";
import SimpleSnackbar from "../../components/UI/SnakBar";

import { countryList } from "../../utils/common";
import isEmpty from "is-empty";







import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const AddAddress = (props) => {
  const { fromAddress, handleCloseModal, getUserAddressHandler } = props;
  let history = useHistory();

  const [addressDetails, setAddressDetails] = useState({});
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const { id } = useParams();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const cancelHandler = () => {
    if (fromAddress) {
      handleCloseModal();
      return;
    }
    history.push("/address");
  };

  const handlerChange = (e) => {
    const { name, value } = e.target;

    setAddressDetails((prevState) => ({ ...prevState, [name]: value }));

    if (name === "country") {
      setCountry(value);
    }
  };

  const getUserAddressByIdHandler = async () => {
    const res = await getUserAddressById(id);

    if (res && res.status) {
      const { fullName, mobileNo, address, pincode, city, state, country } =
        res.data;
      setAddressDetails({
        fullName,
        mobileNumber: mobileNo,
        address,
        pinCode: pincode,
        city,
        state,
        country,
      });
      setCountry(country);
      setState(state);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { fullName, mobileNumber, pinCode, address, city, state, country } =
      addressDetails;
    const payload = {
      fullName,
      city,
      state,
      mobileNo: mobileNumber,
      pincode: pinCode,
      address,
      country,
    };
    if (id) {
      const resUpdate = await updateUserAddress(id, payload);

      if (resUpdate && resUpdate.status) {
        setOpen(true);
        setMessage("Address updated successfully");
        history.push("/address");
      } else {
        setOpen(true);
        setMessage(resUpdate?.response?.data?.message);
      }
    } else {
      const res = await addAddressAPI(payload);

      if (res && res.status) {
        setOpen(true);
        setMessage("Address added successfully");
        if (fromAddress) {
          handleCloseModal();
          getUserAddressHandler();
          return;
        }
        history.push("/address");
      } else {
        setOpen(true);
        setMessage(res?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getUserAddressByIdHandler();
    }
    document.body.classList.add("is-hide-contact");
    return () => {
      document.body.classList.remove("is-hide-contact");
    };
  }, []);

  const getStateCityByZipCodeHandler = async (code) => {
    const res = await getStateCityByZipCode(code);
    // Handle the response as needed
  };

  return (
    <>
      <div
        className={`add_wrap my_product_wrap p-5 ${
          fromAddress ? "add_address" : ""
        }`}
      >
        <div className={`head`}>
          <h5>Add Address</h5>
        </div>
        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>Full Name *</Form.Label>
                <Form.Control
                  placeholder="Enter full name"
                  name="fullName"
                  onChange={handlerChange}
                  value={addressDetails.fullName}
                  onKeyPress={(event) => {
                    if (!/[a-zA-Z[0-9\s]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Col>
              <Col>
                <Form.Label>Mob. No. *</Form.Label>
                <Form.Control
                  placeholder="Enter mobile number"
                  name="mobileNumber"
                  onChange={handlerChange}
                  value={addressDetails.mobileNumber}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>Country *{console.log(country)}</Form.Label>
                <CountryDropdown
                  classes="form-control"
                  defaultOptionLabel="Select a country."
                  value={country}
                  onChange={(val) => handlerChange({ target: { name: "country", value: val } })}
                />
              </Col>
              <Col>
                <Form.Label>State *</Form.Label>
                <RegionDropdown
                  classes="form-control"
                  blankOptionLabel="No country selected."
                  defaultOptionLabel="Now select a State"
                  country={country}
                  value={state}
                  onChange={(val) => {
                    handlerChange({ target: { name: "state", value: val } });
                    setState(val);
                  }}
                  blacklist={{
                    US: [
                      "American Samoa",
                      "District of Colorado",
                      "Micronesia",
                      "Guam",
                      "Marshal Islands",
                      "Palau",
                      "Virgin Islands",
                      "North Mariana islands",
                      "Northern",
                      "Armed Forces America",
                      "Armed Forces Europe, Canada, Africa and Middle East",
                      "Armed Forces Pacific",
                      "District of Columbia",
                      "Marshall Islands",
                    ],
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>Address *</Form.Label>
            <Form.Control
              name="address"
              onChange={handlerChange}
              as="textarea"
              rows={4}
              value={addressDetails.address}
              onKeyPress={(event) => {
                if (!/[a-zA-Z[0-9\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Group>
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>City *</Form.Label>
                <Form.Control
                  placeholder="Enter city"
                  name="city"
                  onChange={handlerChange}
                  value={addressDetails.city}
                  onKeyPress={(event) => {
                    if (!/[a-zA-Z\s]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Col>
             

              <Col>
                <Form.Label>Zip Code *</Form.Label>
                <Form.Control
                  placeholder="Enter zip code"
                  name="pinCode"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    getStateCityByZipCodeHandler(e.target.value);
                    handlerChange(e);
                  }}
                  value={addressDetails.pinCode}
                />
              </Col>
            </Row>
          </Form.Group>
          <div className="form_buttons">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                isEmpty(addressDetails.fullName) ||
                isEmpty(addressDetails.mobileNumber) ||
                isEmpty(addressDetails?.country) ||
                addressDetails?.country === "-1" ||
                isEmpty(addressDetails.pinCode) ||
                isEmpty(addressDetails.address) ||
                isEmpty(addressDetails.city) ||
                isEmpty(state)
              }
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className="ml-99"
              onClick={cancelHandler}
              style={{padding:"0 50px"}}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default AddAddress;



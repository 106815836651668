import React from "react";
import { Col, Row } from "react-bootstrap";
import { Route, Switch } from "react-router";

import ContactList from "../../containers/Shared/ContactList";
import LeftMenu from "../../containers/Shared/LeftMenu";

import HeaderComponent from "../shared/Header";

import PageNotFound from "../shared/PageNotFound";

import { Dashboard } from "../../containers/Dashboard/Ecommerce";
import { MyOrders } from "../../containers/Dashboard/Ecommerce/MyOrders";
import { SettlementDetails } from "../../containers/Dashboard/Ecommerce/SettlementDetails";
import { StreamDashboard } from "../../containers/Dashboard/Stream";
import { SellerOrders } from "../../containers/Dashboard/Ecommerce/SellerOrders";

const DashboardLayout = () => {
  return (
    <>
      <HeaderComponent />
      <div className="wrapper">
        <div className="container-fluid">
          <div className="feed_wrapper">
            <Row>
              <Col md={2}>
                <LeftMenu isFeed />
              </Col>
              <Col md={10} className="middle">
                <Switch>
                  <Route
                    exact
                    path="/ecommerce/dashboard"
                    component={Dashboard}
                  />
                  <Route
                    exact
                    path="/stream/dashboard/:userId"
                    component={StreamDashboard}
                  />
                  <Route
                    path="/ecommerce/settlement-details"
                    component={SettlementDetails}
                  />
                  <Route
                    exact
                    path="/ecommerce/dashboard/my-orders"
                    component={MyOrders}
                  />
                  <Route
                    exact
                    path="/ecommerce/dashboard/track-orders"
                    component={SellerOrders}
                  />
                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;

import React from "react";
import { Table } from "react-bootstrap";
import smartTruncate from "smart-truncate";

/**
 * @author
 * @function AllParticipants
 **/

export const AllParticipants = (props) => {
  const { participantList } = props;

  return (
    <>
      {participantList.length > 0 ? (
        <>
          <div className="followers_wrap p-0 participants_wrap">
            <div className="body p-0">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Viewer</th>
                    <th>Amount</th>
                    <th>Transaction ID</th>
                  </tr>
                </thead>
                <tbody>
                  {participantList.map((item, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <div className="media">
                          {item.viewer.profilePic ? (
                            <img src={item.viewer.profilePic} alt="" />
                          ) : (
                            <span className="dp_name">
                              {item.viewer.name.charAt(0)}
                            </span>
                          )}
                          <div>
                            <h6
                              onClick={() =>
                                (window.location.href = `/profile/${item.viewer._id}`)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <p>
                                @
                                {item?.viewer.username
                                  ? item?.viewer.username
                                  : "username"}
                              </p>
                            </h6>

                            <p>{item?.viewer.location}</p>
                          </div>
                        </div>
                      </td>
                      <td> {item.amount}</td>
                      <td>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://tronscan.org/#/transaction/${item.resp}`}
                        >
                          {smartTruncate(item.resp, 18, {
                            position: 8,
                          })}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      ) : (
        <p className="followers_text">No participants</p>
      )}
    </>
  );
};

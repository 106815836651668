import axios from "axios";

export const fetchUserStream = async () => {
  try {
    const { data } = await axios.get(`stream`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getAllStream = async () => {
  try {
    const { data } = await axios.get(`stream/all`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getMyStream = async () => {
  try {
    const { data } = await axios.get(`stream`);
    return data;
  } catch (err) {
    return err;
  }
};
export const cancelStream = async (id) => {
  try {
    const { data } = await axios.post(`stream/cancelStream/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};
export const deleteStream = async (id) => {
  try {
    const { data } = await axios.delete(`stream/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getStreamAttendee = async (id) => {
  try {
    const { data } = await axios.get(
      `user/profile/transactions?type=STREAM&streamId=${id}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getAttendedStreams = async (id) => {
  try {
    const { data } = await axios.get(
      `user/profile/transactions?type=STREAM&subType=JOINED`
    );
    return data;
  } catch (err) {
    return err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchUserStream,
  getAllStream,
  cancelStream,
  deleteStream,
  getMyStream,
  getStreamAttendee,
  getAttendedStreams,
};

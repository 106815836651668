import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";

/**
 * @author
 * @function Input
 **/

const Input = (props) => {
  const { id, label, value, placeholder, onChange, icon, name, type, ...rest } =
    props;
  return (
    <div className="input_wrap">
      <TextField
        id={id}
        label={label}
        placeholder={placeholder}
        value={value}
        name={name}
        type={type}
        onChange={onChange}
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
        variant="filled"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <i className={`icon ${icon}`}></i>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default Input;

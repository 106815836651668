import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { useUser } from "../../../context/UserProvider";
import { getStoreDetails } from "../Service";

/**
 * @author
 * @function Dashboard
 **/

export const Dashboard = (props) => {
  let history = useHistory();
  const { userLoggedInData, userData } = useUser();
  const [storeDetails, setStoreDetails] = useState({});
  useEffect(() => {
    document.body.classList.add("is-hide-contact");
    return () => {
      document.body.classList.remove("is-hide-contact");
    };
  }, []);

  const goToPage = (page) => {
    history.push(`/${page}`);
  };

  const getStoreDetailsHandler = async () => {
    const res = await getStoreDetails();

   
    if (res.status) {
      setStoreDetails(res.data);
    }
  };

  useEffect(() => {
    getStoreDetailsHandler();
  }, []);


  return (
    <>
      <div className="dashboard_wrapper">
        <Row className="mb-4">
          <Col md={6}>
            <Row className="w-100">
              <Col md={6} className="h-b-right text-center">
                <Card.Title>Amount Recieved</Card.Title>
                <h2>{storeDetails?.productBuy?.totalAmount}</h2>
              </Col>
              <Col md={6} className="text-center">
                <Card.Title>Escrow Amount</Card.Title>
                <h2>{storeDetails?.productBuy?.escrow}</h2>
              </Col>
            </Row>
          </Col>
          <Col md={6} className="d-flex align-items-center">
            <Link to={"/ecommerce/settlement-details"}>View Details</Link>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={6}>
            <Card>
              <Card.Body>
                <div className="head">
                  <Card.Title>My Products</Card.Title>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => goToPage("my-product")}
                    type="submit"
                    size="small"
                  >
                    View
                  </Button>
                </div>

                <ul>
                  <li>
                    <p>{storeDetails?.nonAuctionItems}</p>
                    Products
                  </li>
                  <li>
                    <p>{storeDetails?.product?.totalAuction}</p>
                    Auction
                  </li>
                  <li>
                    <p>{storeDetails?.product?.totalActiveAuctions}</p>
                    Active Bids
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Body>
                <div className="head">
                  <Card.Title>Order Recieved</Card.Title>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => goToPage("ecommerce/dashboard/track-orders")}
                    type="submit"
                    size="small"
                  >
                    View
                  </Button>
                </div>

                <ul>
                  <li>
                    <p>{storeDetails?.order?.pending}</p>
                    Active Orders
                  </li>
                  <li>
                    <p>{storeDetails?.order?.shipped}</p>
                    Shipped
                  </li>
                  <li>
                    <p>{storeDetails?.order?.delivered}</p>
                    Delivered
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

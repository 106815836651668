import { Button } from '@material-ui/core'
import React from 'react'
import DP from '../../assets/img/follower.png'

/**
 * @author
 * @function RemoveFollower
 **/

const RemoveFollower = (props) => {
  return (
    <>
      <div className="remove_wrapper">
        <div className="body">
          <img src={DP} alt="dp" />
          <h5>Remove Follower ?</h5>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry
          </p>
        </div>

        <div className="buttons">
          <Button variant="outlined" color="primary">
            Remove
          </Button>
          <Button variant="outlined" color="secondary" className="ml-74">
            Cancel
          </Button>
        </div>
      </div>
    </>
  )
}

export default RemoveFollower

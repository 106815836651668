import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ContactListLoader = () => {
  return (
    <>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div className="contact_list">
          <ul>
            <li>
              <div className="image_wrap">
                <Skeleton circle={true} height={30} width={30} />
              </div>
              <div className="content" style={{ width: "100%" }}>
                <Skeleton height={20} width="100%" />
              </div>
            </li>
            <li>
              <div className="image_wrap">
                <Skeleton circle={true} height={30} width={30} />
              </div>
              <div className="content" style={{ width: "100%" }}>
                <Skeleton height={20} width="100%" />
              </div>
            </li>
            <li>
              <div className="image_wrap">
                <Skeleton circle={true} height={30} width={30} />
              </div>
              <div className="content" style={{ width: "100%" }}>
                <Skeleton height={20} width="100%" />
              </div>
            </li>
            <li>
              <div className="image_wrap">
                <Skeleton circle={true} height={30} width={30} />
              </div>
              <div className="content" style={{ width: "100%" }}>
                <Skeleton height={20} width="100%" />
              </div>
            </li>
          </ul>
        </div>
      </SkeletonTheme>
    </>
  );
};

export default ContactListLoader;

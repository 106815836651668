import React from "react";
import FreeDeliveryImg from "../../assets/img/free_delivery.png";
import SupportImg from "../../assets/img/support.png";
import PaymentImg from "../../assets/img/Payment.png";
import TrustedImg from "../../assets/img/Trusted.png";

export default function ProductFooter() {
  return (
    <>
      {/* <div className="product_footer">
        <ul>
          <li>
            <img src={FreeDeliveryImg} alt="" />
          </li>
          <li>
            <img src={SupportImg} alt="" />
          </li>
          <li>
            <img src={PaymentImg} alt="" />
          </li>
          <li>
            <img src={TrustedImg} alt="" />
          </li>
        </ul>
      </div> */}
    </>
  );
}

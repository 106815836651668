import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DP from "../../assets/img/dp.png";
import FormInput from "../../components/UI/FormInput";
import DatePicker from "../../components/UI/DatePicker";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";
import validator from "validator";
import CustomModal from "../../components/UI/Modal";
import UploadPicture from "./UploadPicture";
import {
  userProfile,
  userProfileUpdate,
  validateUsername,
} from "./ProfileService";
import moment from "moment";
import isEmpty from "is-empty";

import UploadProfilePicture from "./UploadProfilePicture";
import { useUser } from "../../context/UserProvider";
import { isNumber } from "../../utils/common";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

/**
 * @author
 * @function EditProfile
 **/

const EditProfile = (props) => {
  const { closeModal, userId } = props;
  const { getUserOfData } = useUser();
  const [userProfileDetails, setUserProfileDetails] = useState({
    fullName: "",
    username: "",
    company: "",
    gender: "",
    dob: moment(new Date()).format("YYYY-MM-DD"),
    mobile: "",
    email: "",
    location: "",
  });
  const [selectedDate, handleDateChange] = useState(new Date());
  const [userPictureData, setUserPictureData] = useState({
    profile: "",
    cover: "",
  });
  const [picUrlData, setPicUrlData] = useState({
    profile: "",
    cover: "",
  });
  const [mobileError, setMobileError] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");

  const [showUploadPictureModal, setShowUploadPictureModal] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const handleCloseUploadPictureModal = () => setShowUploadPictureModal(false);
  const handleShowUploadPictureModal = () => setShowUploadPictureModal(true);
  const [showUploadProfilePictureModal, setShowUploadProfilePictureModal] =
    useState(false);

  const handleCloseUploadProfilePictureModal = () =>
    setShowUploadProfilePictureModal(false);
  const handleShowUploadProfilePictureModal = () =>
    setShowUploadProfilePictureModal(true);

  const getUserDetailsHandler = async () => {
    const res = await userProfile();

    const {
      name,
      email,
      location,
      username,
      coverPic,
      profilePic,
      gender,
      dob,
      mobile,
      country,
      state,
    } = res.data;
    setUserProfileDetails({
      fullName: name,
      email,
      username: username,
      location,
      gender,
      mobile,
    });
    handleDateChange(dob);
    setCountry(country);
    setRegion(state);

    setPicUrlData({
      cover: coverPic,
      profile: profilePic,
    });
  };

  useEffect(() => {
    getUserDetailsHandler();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsInvalid(false);
    setUserProfileDetails((prevState) => ({ ...prevState, [name]: value }));
    setMobileError("");
  };

  // const handleDateChange = (date) => {
  //   setUserProfileDetails((prevState) => ({ ...prevState, dob: date }));
  // };

  const getUserPictureHandler = (name, file, url) => {
    setUserPictureData((prevState) => ({ ...prevState, [name]: file }));
    setPicUrlData((prevState) => ({ ...prevState, [name]: url }));
  };

  const onSubmit = async () => {
    const {
      fullName,
      email,
      location,
      username,
      gender,
      company,
      mobile,
      dob,
    } = userProfileDetails;
    // const payload = {
    //   name: fullName,
    //   occupation: designation,
    //   location: location,
    //   mobile,
    //   gender,
    // }

    const formData = new FormData();
    formData.append("name", fullName);
    formData.append("username", username);
    formData.append("location", location);
    formData.append("country", country);
    formData.append("state", region);
    formData.append("dob", selectedDate);
    formData.append("gender", gender);
    formData.append("mobile", mobile);
    if (userPictureData.cover) {
      formData.append("coverPic", userPictureData.cover);
    } else {
      formData.append("coverPic", null);
    }
    if (userPictureData.profile) {
      formData.append("profilePic", userPictureData.profile);
    } else {
      formData.append("profilePic", null);
    }
    if (
      isEmpty(userProfileDetails.email) ||
      isEmpty(userProfileDetails.location) ||
      !selectedDate
    ) {
      setIsInvalid(true);
      return;
    }

    const res = await userProfileUpdate(formData);

    if (res.status) {
      closeModal();
      getUserOfData(userId);
    }
  };

  const validateUserNameHandler = async () => {
    const { username } = userProfileDetails;
    const res = await validateUsername(username);
  };

  return (
    <>
      <div className="edit_profile_wrap">
        <div
          className="edit_profile_banner"
          style={{ backgroundImage: `url(${picUrlData.cover})` }}
        >
          <div class="layer"></div>
          <span className="pic_change" onClick={handleShowUploadPictureModal}>
            <i className="icon change"></i>
          </span>
        </div>
        <div className="edit_profile_dp">
          <div class="layer"></div>
          <img src={picUrlData.profile} />
          <span
            className="pic_change"
            onClick={handleShowUploadProfilePictureModal}
          >
            <i className="icon change"></i>
          </span>
        </div>

        <div className="edit_profile_body">
          <FormInput
            label="Full Name"
            value={userProfileDetails.fullName}
            name="fullName"
            onChange={handleChange}
            placeholder="Enter full name"
          />
          <FormInput
            label="Username"
            value={userProfileDetails.username}
            name="username"
            onChange={handleChange}
            onBlur={validateUserNameHandler}
            placeholder="Enter username"
          />

          {/* <FormInput
            label="Company *"
            value={userProfileDetails.company}
            name="company"
            onChange={handleChange}
          /> */}

          <Row>
            <Col>
              <FormInput
                label="Mobile No."
                value={userProfileDetails.mobile}
                name="mobile"
                onChange={handleChange}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Enter mobile no."
                onBlur={(e) => {
                  if (e.target.value.length !== 10) {
                    setMobileError("Invalid Mobile Number");
                  } else {
                    setMobileError("");
                  }
                }}
              />
              {mobileError && (
                <p style={{ marginTop: "-20px" }} className="error">
                  {mobileError}
                </p>
              )}
              {/* <FormInput
                label="Gender *"
                value={userProfileDetails.gender}
                name="gender"
                onChange={handleChange}
              /> */}
              {/* <Form.Group>
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={userProfileDetails?.gender}
                  onChange={handleChange}
                >
                  <option value="-1">--Select--</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHER">Other</option>
                </Form.Control>
              </Form.Group> */}
            </Col>
            <Col className="date_picker">
              <Form.Group>
                <Form.Label>
                  Date of Birth <span className="text-danger">*</span>
                </Form.Label>
                {/* <DatePicker onDateChange={handleDateChange} fromEditProfile /> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    clearable
                    value={selectedDate}
                    onChange={(date) => handleDateChange(date)}
                    format="MM-dd-yyyy"
                    placeholder="mm-dd-yyyy"
                    disableFuture={true}
                    maxDate={moment().subtract(18, "years")}
                  />
                </MuiPickersUtilsProvider>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormInput
                label={`E-mail address`}
                value={userProfileDetails.email}
                onChange={handleChange}
                name="email"
                placeholder="Enter e-mail address"
                isInvalid={isInvalid}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  Country <span className="text-danger">*</span>
                </Form.Label>
                <CountryDropdown
                  classes="form-control"
                  defaultOptionLabel="Select a country."
                  value={country}
                  onChange={(val) => setCountry(val)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>
                  State <span className="text-danger">*</span>
                </Form.Label>
                <RegionDropdown
                  classes="form-control"
                  blankOptionLabel="No country selected."
                  defaultOptionLabel="Now select a State"
                  country={country}
                  value={region}
                  onChange={(val) => setRegion(val)}
                  blacklist={{
                    US: [
                      "American Samoa",
                      "District of Colorado",
                      "Micronesia",
                      "Guam",
                      "Marshal Islands",
                      "Palau",
                      "Virgin Islands",
                      "North Mariana islands",
                      "Northern",
                      "Armed Forces America",
                      "Armed Forces Europe, Canada, Africa and Middle East",
                      "Armed Forces Pacific",
                      "District of Columbia",
                      "Marshall Islands",
                    ],
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <FormInput
            label={`Location `}
            value={userProfileDetails.location}
            name="location"
            placeholder="Enter location"
            onChange={handleChange}
            onKeyPress={(event) => {
              if (/[^a-zA-Z0-9\s]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            isInvalid={isInvalid}
          />
          <div className="form_buttons">
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              disabled={mobileError}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className="ml-99"
              onClick={closeModal}
              style={{padding:"20px 50px"}}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      <CustomModal
        show={showUploadPictureModal}
        handleClose={handleCloseUploadPictureModal}
        title="Change Cover Picture"
        size="lg"
        className="profile_modal"
      >
        <UploadPicture
          getUserPicture={getUserPictureHandler}
          closeModal={handleCloseUploadPictureModal}
        />
      </CustomModal>
      <CustomModal
        show={showUploadProfilePictureModal}
        handleClose={handleCloseUploadProfilePictureModal}
        title="Change Profile Picture"
        size="lg"
        className="profile_modal"
      >
        <UploadProfilePicture
          getUserPicture={getUserPictureHandler}
          closeModal={handleCloseUploadProfilePictureModal}
        />
      </CustomModal>
    </>
  );
};

export default EditProfile;

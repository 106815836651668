import React from "react";
import { Col, Row } from "react-bootstrap";
import { Route, Switch } from "react-router";

import StickyBox from "react-sticky-box";
import ContactList from "../../containers/Shared/ContactList";
import LeftMenu from "../../containers/Shared/LeftMenu";
import FeedHome from "../../containers/Feed";
import Messanger from "../../containers/Messanger";
import Game from "../../containers/Game";

import HeaderComponent from "../shared/Header";
import SearchResult from "../../containers/Search";
import Streaming from "../../containers/Streaming";
import MyAddress from "../../containers/Products/MyAddress";
import AddAddress from "../../containers/Products/AddAddress";
import MyProduct from "../../containers/Products/MyProduct";
import AddProduct from "../../containers/Products/AddProduct";
import Notifications from "../../containers/Notifications";
import VideoFeeds from "../../containers/Feed/VideoFeeds";
import PageNotFound from "../shared/PageNotFound";
import GamePlay from "../../containers/Games/GamePlay";
import ChangePassword from "../../containers/ChangePassword";
import KYCUpdate from "../../containers/Profile/KYCUpdate";

const FeedLayout = () => {
  return (
    <>
      <HeaderComponent />
      <div className="wrapper">
        <div className="container-fluid">
          <div className="feed_wrapper">
            <Row>
              <Col md={2}>
                <LeftMenu isFeed />
              </Col>
              <Col md={7} className="middle">
                <Switch>
                  <Route exact path="/feed" component={FeedHome} />
                  <Route path="/videos" component={FeedHome} />

                  <Route exact path="/search/:query" component={SearchResult} />
                  <Route exact path="/streams/all" component={Streaming} />
                  <Route exact path="/address" component={MyAddress} />
                  <Route exact path="/kycupdate" component={KYCUpdate} />
                  <Route
                    exact
                    path="/change-password"
                    component={ChangePassword}
                  />
                  <Route exact path="/address/add" component={AddAddress} />
                  <Route
                    exact
                    path="/address/update/:id"
                    component={AddAddress}
                  />
                  <Route exact path="/my-product" component={MyProduct} />
                  <Route exact path="/my-product/add" component={AddProduct} />
                  <Route
                    exact
                    path="/my-product/update/:id"
                    component={AddProduct}
                  />
                  <Route
                    exact
                    path="/notifications"
                    component={Notifications}
                  />
                  <Route path="/game/poker" component={GamePlay} />

                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </Col>
              <Col md={3} className="right_side">
                <ContactList />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedLayout;

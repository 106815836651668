/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-multi-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";

const zerofill = (num) => (num < 10 && num >= 0 ? `0${num}` : num);

const SvgCircle = (props) => {
  const { className, done, max, radius, strokeWidth } = props;
  const size = (radius + strokeWidth) * 2;
  const length = Math.ceil(2 * radius * Math.PI);
  const remainingLength =
    length - Math.ceil(2 * radius * Math.PI) * (done / max);

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle
          className="circle"
          r={radius}
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          strokeDasharray={length}
          strokeDashoffset={remainingLength}
          strokeLinecap="square"
          strokeWidth={strokeWidth}
          fill="none"
        />
        <circle
          className="circle--bg"
          r={radius}
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          stroke="#AARRGGBB"
          strokeLinecap="square"
          strokeWidth={strokeWidth}
          fill="none"
        />
      </g>
    </svg>
  );
};

SvgCircle.defaultProps = {
  done: 0,
  max: 24,
  radius: 36,
  strokeWidth: 4,
};

export default class CountDownTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  // componentWillMount() {
  //   this.getTimeUntil(this.props.deadline)
  // }

  componentDidMount() {
    this.timerId = setInterval(
      () => this.getTimeUntil(this.props.deadline),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  getTimeUntil(deadline) {
    const time = Date.parse(deadline) - Date.parse(new Date());
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const days = Math.floor(time / (1000 * 60 * 60 * 24));

    if (seconds < 0) {
      window.location.reload(false);
    }

    setTimeout(() => {
      this.setState({ days, hours, minutes, seconds });
    }, 500);
  }

  render() {
    const { days, hours, minutes, seconds } = this.state;

    return (
      <div className="clock">
        <div className="clock__display">
          <SvgCircle className="clock__circle" max={365} done={days} />
          <div className="clock__text clock__text--days">
            <span className="clock__amount">{zerofill(days)}</span>
            <span className="clock__unit">days</span>
          </div>
        </div>
        <div className="clock__display">
          <SvgCircle max={24} done={hours} />
          <div className="clock__text clock__text--hours">
            <span className="clock__amount">{zerofill(hours)}</span>
            <span className="clock__unit">hours</span>
          </div>
        </div>
        <div className="clock__display">
          <SvgCircle max={60} done={minutes} />
          <div className="clock__text clock__text--minutes">
            <span className="clock__amount">{zerofill(minutes)}</span>
            <span className="clock__unit">minutes</span>
          </div>
        </div>
        <div className="clock__display">
          <SvgCircle max={60} done={seconds} />
          <div className="clock__text clock__text--seconds">
            <span className="clock__amount">{zerofill(seconds)}</span>
            <span className="clock__unit">seconds</span>
          </div>
        </div>
      </div>
    );
  }
}

SvgCircle.propTypes = {
  className: PropTypes.string.isRequired,
  done: PropTypes.number,
  max: PropTypes.number,
  radius: PropTypes.number,
  strokeWidth: PropTypes.number,
};

CountDownTimer.propTypes = {
  deadline: PropTypes.string.isRequired,
};

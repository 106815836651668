import React from "react";

/**
 * @author
 * @function Game
 **/

const Game = (props) => {
  return (
    <>
      <div id="unity-container" className="unity-desktop">
        <canvas id="unity-canvas" width={650} height={670}></canvas>
        <div id="unity-loading-bar">
          <div id="unity-logo"></div>
          <div id="unity-progress-bar-empty">
            <div id="unity-progress-bar-full"></div>
          </div>
        </div>
        <div id="unity-warning"> </div>
        <div id="unity-footer">
          <div id="unity-webgl-logo"></div>
          <div id="unity-fullscreen-button"></div>
          <div id="unity-build-title">Poker</div>
        </div>
      </div>
    </>
  );
};

export default Game;

import isEmpty from "is-empty";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";

import { useState } from "react";
import { userProfileOf } from "../containers/Profile/ProfileService";
import LocalStorageService from "../utils/LocalStorageService";

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export default function UserProvider({ children }) {
  const [userData, setUserData] = useState({});
  const [userOfData, setUserOfData] = useState({});
  const [userLoggedInData, setUserLoggedInData] = useState({});
  const [isDataLoading, setDataLoading] = useState(false);

  const onSubmitUserHandler = (data) => {
    setUserData(data);
    setUserLoggedInData(data);
    onSubmitUserOfHandler(data);
  };

  const onSubmitUserOfHandler = (data) => {
    setUserOfData(data);
  };

  const getUserOfData = useCallback(async (id) => {
    setDataLoading(true);

    let uId;
    if (id) {
      uId = id;
    } else {
      uId = localStorage.getItem("_id");
    }
    const res = await userProfileOf(uId);
    if (res && res.status) {
      onSubmitUserOfHandler(res.data);

      setDataLoading(false);
    } else {
      setDataLoading(false);
    }
  }, []);

  useEffect(() => {
    const user = LocalStorageService.getItem("userDetail");

    if (user) {
      setUserData(user);
    }
  }, []);

  const values = {
    userData,
    userOfData,
    userLoggedInData,
    isDataLoading,
    onSubmitUserHandler,
    onSubmitUserOfHandler,
    getUserOfData,
  };
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}

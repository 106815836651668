import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { userProfile, userSuggestions } from "../Profile/ProfileService";

import PostList from "../Shared/PostList";
import WritePost from "../Shared/WritePost";
import {
  postFeedComment,
  feedLike,
  feedPost,
  feedUnLike,
  getAllPost,
  uploadImages,
  feedShare,
  getCommentsFeed,
  deleteFeedPost,
  getFeedById,
  updateFeed,
} from "./FeedService";
import AppContext from "../../context";
import { useUploadFiles } from "../../context/UploadFilesProvider";
import useOutsideClick from "../../hooks/useOutsideClick";

/**
 * @author
 * @function FeedHome
 **/

const FeedHome = (props) => {
  const context = useContext(AppContext);
  const {
    uploadedFiles,
    uploadFilesHandler,
    removeFiles,
    setUploadedFiles,
    imgUrl,
    setImgUrl,
  } = useUploadFiles();
  const { setUserDataHandler } = context;
  const [feedData, setFeedData] = useState({
    content: "",
  });
  const [feedImages, setFeedImages] = useState([]);

  const [postData, setPostData] = useState([]);
  const [showWritePostModal, setShowWriteModal] = useState(false);
  const [userPrfoileDetails, setUserProfileDetails] = useState({});
  const [commentData, setCommentData] = useState({});
  const [userSuggestionsList, setUserSuggestionsList] = useState([]);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEmojiComment, setOpenEmojiComment] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const [feedIdToUpdate, setFeedIdToUpdate] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const emojiWrapRef = useRef();

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenToast(false);
  };

  const handleCloseWritePostModal = () => {
    setFeedIdToUpdate("");
    setShowWriteModal(false);
    setFeedData({ content: "" });
  };
  const handleShowWritePostModal = () => setShowWriteModal(true);

  const handleShowEmojiOpen = () => setIsEmojiOpen(true);
  const handleCloseEmojiOpen = () => setIsEmojiOpen(false);
  const handleClickCommentEmoji = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenEmojiComment((prev) => !prev);
  };

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
    setFeedData((prevState) => ({
      ...prevState,
      content: prevState.content + emojiObject.emoji,
    }));
    handleCloseEmojiOpen();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFeedData((prevState) => ({ ...prevState, [name]: value }));
  };

  const feedPostHandler = async () => {
    const { content } = feedData;

    const payload = {
      description: content,
      links: uploadedFiles,
    };
    const res = await feedPost(payload);

    if (res.status) {
      handleCloseWritePostModal();
      getFeedHandler();
      setUploadedFiles([]);
      setImgUrl([]);
      setFeedData({ content: "" });
    }
  };
  const feedPostUpdateHandler = async () => {
    const { content } = feedData;

    const payload = {
      description: content,
      links: uploadedFiles,
    };
    let res;
    if (feedIdToUpdate) {
      res = await updateFeed(payload, feedIdToUpdate);
      if (res.status) {
        handleCloseWritePostModal();
        getFeedHandler();
        setUploadedFiles([]);
        setImgUrl([]);
        setFeedData({ content: "" });
      }
    } else {
      feedPostHandler();
    }
  };

  const userSuggestionsHandler = async () => {
    const res = await userSuggestions();

    if (res && res.status) {
      res.data.forEach((el) => {
        el.isFollowing = false;
      });
      setUserSuggestionsList(res.data);
    }
  };

  const getFeedHandler = async () => {
    const res = await getAllPost("ALL");

    if (res.status) {
      res.data.forEach((element) => {
        element.isCommentBoxOpen = false;
        element.isCommentShow = false;
      });

      setPostData(res.data);
    }
  };

  const commentShowHandler = async (id) => {
    let commentListTemp = [];
    const res = await getCommentsFeed(id);

    if (res && res.status) {
      commentListTemp = res.data.comments.reverse();
    }
    const tempData = [...postData];
    tempData.forEach((element) => {
      if (element._id === id) {
        element.isCommentShow = true;
        element.commentData = commentListTemp;
      }
    });
    setPostData(tempData);
  };
  const getUserProfileHandler = useCallback(async () => {
    const res = await userProfile();

    if (res && res.status) {
      setUserProfileDetails(res.data);
      setUserDataHandler(res.data);
    }
  }, [setUserDataHandler]);

    const uploadImagesHandler = async (file) => {
    setFeedImages((prevState) => [...prevState, file]);
    uploadFilesHandler(file, "PROFILE");
  };

  const feedLikeHandler = async (id) => {
    const res = await feedLike(id);

    if (res.message === "Already Liked") {
      await feedUnLike(id);
    }
    getFeedHandler();
  };

  const commentOpenHandler = async (data) => {
    const tempData = [...postData];
    setCommentId(data._id);

    tempData.forEach((element) => {
      if (element._id === data._id) {
        if (element.isCommentBoxOpen) {
          element.isCommentBoxOpen = false;
        } else {
          element.isCommentBoxOpen = true;
        }
      }
    });

    setPostData(tempData);
  };

  const handleChangeComment = (e, id) => {
    const { name, value } = e.target;
    const tempData = [...postData];

    tempData.forEach((element) => {
      if (element._id === id) {
        setCommentData((prevState) => ({ ...prevState, [id]: value }));
      }
    });
  };

  const postCommentHandler = async (data) => {
    setCommentData({ [data._id]: "" });
    const res = await postFeedComment(data._id, commentData[data._id]);

    if (res && res.status) {
      commentShowHandler(data._id);
    }
  };

  const feedShareHandler = async (feedId) => {
    const res = await feedShare(feedId);
    if (res.status) {
      setOpenToast(true);
      setToastMessage("You have shared this post!");
    } else {
      setOpenToast(true);
      setToastMessage("Someting went wrong!");
    }
    getFeedHandler();
  };

  useEffect(() => {
    userSuggestionsHandler();
    getFeedHandler();
    getUserProfileHandler();

    return () => setUploadedFiles([]);
  }, [getUserProfileHandler]);

  const deleteFeedPostHandler = async (id) => {
    await deleteFeedPost(id);
    getFeedHandler();
  };

  const onCommentEmojiClick = (event, emojiObject) => {
    setCommentData((prevState) => ({
      ...prevState,
      [commentId]: prevState[commentId]
        ? prevState[commentId] + emojiObject.emoji
        : "" + emojiObject.emoji,
    }));
    setOpenEmojiComment(false);
  };

  const getFeedByIdHandler = async (id) => {
    const res = await getFeedById(id);
   
    if (res.status) {
      if (res.data.mediaLinks.length > 0) {
        for (const links of res.data.mediaLinks) {
          setUploadedFiles((prevState) => [...prevState, links._id]);
          setImgUrl((prevState) => [...prevState, links.location]);
        }
      } else {
        setUploadedFiles([]);
        setImgUrl([]);
      }

      setFeedData({ content: res.data.description });
    }

    setFeedIdToUpdate(id);
  };

  useOutsideClick(emojiWrapRef, () => {
    //setOpenEmojiComment(false);
  });

  return (
    <>
      <h2 className="welcome_text">Welcome back, {userPrfoileDetails.name}!</h2>
      <WritePost handleShowWritePostModal={handleShowWritePostModal} />
      <PostList
        postData={postData}
        userSuggestionsList={userSuggestionsList}
        setUserSuggestionsList={setUserSuggestionsList}
        feedLikeHandler={feedLikeHandler}
        commentOpenHandler={commentOpenHandler}
        postCommentHandler={postCommentHandler}
        handleChangeComment={handleChangeComment}
        userSuggestionsHandler={userSuggestionsHandler}
        commentData={commentData}
        feedShareHandler={feedShareHandler}
        commentShowHandler={commentShowHandler}
        deleteFeedPostHandler={deleteFeedPostHandler}
        handleClickCommentEmoji={handleClickCommentEmoji}
        anchorEl={anchorEl}
        openEmojiComment={openEmojiComment}
        onCommentEmojiClick={onCommentEmojiClick}
        handleCloseWritePostModal={handleCloseWritePostModal}
        handleShowWritePostModal={handleShowWritePostModal}
        showWritePostModal={showWritePostModal}
        isEmojiOpen={isEmojiOpen}
        handleShowEmojiOpen={handleShowEmojiOpen}
        handleCloseEmojiOpen={handleCloseEmojiOpen}
        uploadImagesHandler={uploadImagesHandler}
        uploadedFile={imgUrl}
        removeFileHandler={removeFiles}
        onEmojiClick={onEmojiClick}
        handleChange={handleChange}
        feedData={feedData}
        onSubmit={feedPostUpdateHandler}
        getFeedByIdHandler={getFeedByIdHandler}
        openToast={openToast}
        handleCloseToast={handleCloseToast}
        toastMessage={toastMessage}
        emojiWrapRef={emojiWrapRef}
      />
    </>
  );
};

export default FeedHome;

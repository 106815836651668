import { Button } from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import ShopImg from "../../assets/img/shop.png";
import StarIcon from "@material-ui/icons/Star";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Dropdown } from "react-bootstrap";
import moment from "moment";

import { useHistory } from "react-router";
import { deleteProduct, getAllProductByUser } from "./ProductService";
import { Link } from "react-router-dom";
import LocalStorageService from "../../utils/LocalStorageService";
import { useProducts } from "../../context/ProductsProvider";

/**
 * @author
 * @function MyProduct
 **/

const MyProduct = (props) => {
  const { onSubmitProductsHandler } = useProducts();
  const [isEditForUserOnly, setEditForUserOnly] = useState(true);
  const { userIdOfProduct } = props;
  const userDetails = LocalStorageService.getItem("userDetail");
  let history = useHistory();
  const [allProducts, setAllProducts] = useState([]);

  const addProductHandler = () => {
    history.push("/my-product/add");
  };

  // const getAllProductHandler = async () => {
  //   const res = await getAllProduct();

  //   if (res && res.status) {
  //     setAllProducts(res.data.reverse());
  //   } else {
  //     setAllProducts([]);
  //   }
  // };

  const getAllProductByUserHandler = useCallback(async () => {
    let id;
    if (userIdOfProduct) {
      id = userIdOfProduct;
    } else {
      id = userDetails._id;
    }
    const res = await getAllProductByUser(id);
    if (res && res.status) {
      setAllProducts(res.data.reverse());
      onSubmitProductsHandler(res.data.reverse());
    } else {
      setAllProducts([]);
    }
  }, [userDetails._id, userIdOfProduct]);

  const deleteProductHandler = async (data) => {
    // const payload = {
    //   description: 'string',
    //   links: data.links,
    //   id: data.id,
    // }

    await deleteProduct(data.id);
    getAllProductByUserHandler();
  };

  useEffect(() => {
    getAllProductByUserHandler();
  }, [getAllProductByUserHandler]);

  useEffect(() => {
    if (userIdOfProduct) {
      if (userIdOfProduct !== userDetails._id) {
        setEditForUserOnly(false);
      }
    }
  }, [userDetails._id, userIdOfProduct]);

  useEffect(() => {
    document.body.classList.add("is-hide-contact");
    return () => {
      document.body.classList.remove("is-hide-contact");
    };
  }, []);

  const goToProductDetailHandler = (id) => {
    // history.push(`/product/detail/${id}`);
    window.open(`/product/detail/${id}`, "_blank");
  };

  return (
    <>
      <div className="my_product_wrap">
        <div className={`head`}>
          <h5>Product List</h5>
          {isEditForUserOnly && (
            <Button
              variant="outlined"
              color="primary"
              onClick={addProductHandler}
              className="add-product"
            >
              <i className="icon add_2"></i> Add product
            </Button>
          )}
        </div>
        <>
          {allProducts.length === 0 && (
            <div className="no_products">
              <h5>You have not added any product yet!</h5>
            </div>
          )}
          <div className="body">
            <ul>
              {allProducts.map((item) => {
                return (
                  <li className="list">
                    <div className="image_wrap">
                      {item.productType === "AUCTION" && (
                        <p className="auction_type ribbon"></p>
                      )}
                      {item.mediaLinks.slice(0, 1).map((url) => {
                        return <img src={url.location} alt="" />;
                      })}
                    </div>
                    <div className="details">
                      {isEditForUserOnly && (
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <div className="action">
                              <MoreVertIcon />
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {!moment(item.bidExpireyDate).isBefore(
                              moment()
                            ) && (
                              <Link
                                to={`/my-product/update/${item.id}`}
                                className="dropdown-item"
                              >
                                <i className="icon edit"></i> Edit
                              </Link>
                            )}

                            <Dropdown.Item
                              href="javascript:void(0)"
                              onClick={() => deleteProductHandler(item)}
                            >
                              <i className="icon delete"></i> Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}

                      <a
                        href={`/product/detail/${item._id}`}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        <div className="p_detail">{item.productName}</div>
                      </a>
                      <p className="p_brand">Brand: {item.brandName}</p>
                      <ul className="star">
                        <li>
                          <StarIcon className="full" />
                        </li>
                        <li>
                          <StarIcon className="full" />
                        </li>
                        <li>
                          <StarIcon className="full" />
                        </li>
                        <li>
                          <StarOutlineIcon className="outline" />
                        </li>
                        <li>
                          <StarOutlineIcon className="outline" />
                        </li>
                      </ul>
                      <p className="p_price">
                        <i className="icon revit_coin"></i> {item.amount}
                      </p>
                      <p className="p_desc">{item.description}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      </div>
    </>
  );
};

export default MyProduct;

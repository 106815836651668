import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { Card, Col, Nav, Row, Tab, Table } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import CustomModal from "../../../components/UI/Modal";
import { useUser } from "../../../context/UserProvider";
import VisibilityIcon from "@material-ui/icons/Visibility";

import {
  getMyStream,
  getStreamAttendee,
  getAttendedStreams,
} from "../../Streaming/StreamingService";
import { AllParticipants } from "./AllParticipants";

/**
 * @author
 * @function Dashboard
 **/

export const StreamDashboard = (props) => {
  const { userData } = useUser();

  // let history = useHistory();
  const [hostedStreamList, setHostedStreamList] = useState([]);
  const [attendedStreamList, setAttendedStreamList] = useState([]);
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [participantList, setParticipantList] = useState([]);
  const [totalEarned, setTotalEarned] = useState(0);
  const [totalSpent, setTotalSpent] = useState(0);

  const handleCloseFollowersModal = () => setShowFollowersModal(false);
  const handleShowFollowersModal = () => setShowFollowersModal(true);

  const getAllStreamHandler = useCallback(async () => {
    const res = await getMyStream();


    if (res && res.status) {
      
      let tempList = [];
      for (const data of res.data) {
       
        if (data.totalEarning) {
          tempList.push(data.totalEarning);
        }
      }

     

      const totalEarning = tempList.reduce((a, b) => a + b, 0);
    
      setTotalEarned(totalEarning);
      setHostedStreamList(res.data);
    } else {
      setHostedStreamList([]);
    }
  }, []);

 

  const getStreamAttendeeHandler = async (id) => {
    const res = await getStreamAttendee(id);
    if (res.status) {
      handleShowFollowersModal();
      for (const data of res.data) {
      
        setParticipantList(data.data);
      }
    }

   
  };

  const getAttendedStreamsHandler = async () => {
    const res = await getAttendedStreams();

    if (res.status) {
      for (const data of res.data) {
        setAttendedStreamList(data.data);
       
        for (const innerData of data?.data) {
          if (innerData.data) {
            for (const innerChild of innerData?.data?.joinedEvents) {
            
              if (innerChild.amount) {
                setTotalSpent((prevState) => prevState + innerChild.amount);
              }
            }
          }
        }
      }
    }

   
  };

  useEffect(() => {
    getAllStreamHandler();
  }, [getAllStreamHandler]);

  useEffect(() => {
    getAttendedStreamsHandler();
  }, []);

  //   const totalDurations = (attendedStreamList)
  //  .reduce((prev, cur) => moment.duration(cur.startTime).add(prev.streamEndedAt),
  //   moment.duration(attendedStreamList))

 

  return (
    <>
      <div className="dashboard_wrapper">
        <Row className="mb-4">
          <Col md={6}>
            <Row className="w-100">
              <Col md={6} className="h-b-right text-center">
                <Card.Title>Amount Earned</Card.Title>
                <h2 className="total">
                  <i className="icon revit_coin"></i> {totalEarned}
                </h2>
              </Col>
              <Col md={6} className="text-center">
                <Card.Title>Amount Spent</Card.Title>
                <h2 className="total">
                  <i className="icon revit_coin"></i> {totalSpent}
                </h2>
              </Col>
            </Row>
          </Col>
          {/* <Col md={6} className="d-flex align-items-center">
            <Link to={"/ecommerce/settlement-details"}>View Details</Link>
          </Col> */}
        </Row>
        <hr />
        <div className="body">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="first">Hosted Streams</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Attended Streams</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                {hostedStreamList.length > 0 ? (
                  <Table striped bordered hover variant="dark">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Stream Title</th>

                        <th>Stream Duration</th>
                        <th>Stream Cost</th>
                        <th>Total Participant</th>
                        <th>Total Earning</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hostedStreamList.map((h, i) => {
                        let startTime = moment
                          .utc(h.startTime)
                          .local()
                          .format("MM/DD/YYYY, HH:mm:ss");
                        let endTime = moment(h.streamEndedAt).format(
                          "MM/DD/YYYY, HH:mm:ss"
                        );
                        let now = endTime;
                        let then = startTime;

                        const streamDuration = moment
                          .utc(moment(now).diff(moment(then)))
                          .format("HH:mm:ss");

                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{h.title}</td>

                            <td>
                              {h.streamEnded || h.streamStarted
                                ? streamDuration
                                : "00:00:00"}
                            </td>
                            <td>{h.price === 0 ? "Free" : h.price}</td>
                            <td style={{ cursor: "pointer" }}>
                              {h.totalParticipant > 0 && (
                                <Button
                                  onClick={() =>
                                    getStreamAttendeeHandler(h._id)
                                  }
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <VisibilityIcon /> {h.totalParticipant}
                                </Button>
                              )}
                            </td>
                            <td>{h.totalEarning}</td>
                            <td>
                              {h.streamStarted && !h.streamEnded && (
                                <span>Running</span>
                              )}
                              {!h.streamStarted && !h.streamEnded && (
                                <span className="orange">Scheduled</span>
                              )}
                              {h.streamStarted && h.streamEnded && (
                                <span className="red">Ended</span>
                              )}
                              {!h.streamStarted && h.streamEnded && (
                                <span className="orange" style={{ padding: 5 }}>
                                  Stream didn't start
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <h5 className="text-center">
                    You have not hosted stream yet!
                  </h5>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                {attendedStreamList.length > 0 ? (
                  <Table striped bordered hover variant="dark">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Stream Title</th>

                        <th>Start - End</th>
                        <th>
                          Duration
                          <p className="mb-0">(hh:mm:ss)</p>
                        </th>
                        <th>Stream Cost</th>
                        <th>Total Spent</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendedStreamList?.map((p, i) => {
                        let timeTemp = moment
                          .utc(p.totalDuration * 1000)
                          .format("HH:mm:ss");
                        const totalDuration = timeTemp;

                     
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{p?.stream?.title}</td>
                            <td>
                              {" "}
                              {moment(p?.stream?.startTime).format(
                                "MMM Do YYYY, h:mm:ss a"
                              )}{" "}
                              -{" "}
                              {p?.stream?.streamEndedAt
                                ? moment(p?.stream?.streamEndedAt).format(
                                    "MMM Do YYYY, h:mm:ss a"
                                  )
                                : "NA"}
                            </td>
                            <td>{totalDuration}</td>

                            <td>{p?.stream?.price}</td>

                            <td>{p?.amount === 0 ? "Free" : p?.amount}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <h5 className="text-center">
                    You have not attended any streams!
                  </h5>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      <CustomModal
        show={showFollowersModal}
        handleClose={handleCloseFollowersModal}
        title="Participants List"
      >
        <AllParticipants participantList={participantList} />
      </CustomModal>
    </>
  );
};

import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";

import { Link } from "react-router-dom";

import { useUser } from "../../context/UserProvider";

/**
 * @author
 * @function WritePost
 **/

const WritePost = (props) => {
  const { userOfData } = useUser();
  const { handleShowWritePostModal } = props;

  return (
    <>
      <div className="write_post_wrap">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              {userOfData?.profilePic ? (
                <img src={userOfData?.profilePic} alt="" />
              ) : (
                <span className="dp_name">{userOfData?.name?.charAt(0)}</span>
              )}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Write your article..."
            aria-label="Write your article..."
            aria-describedby="basic-addon1"
            onClick={handleShowWritePostModal}
            readOnly
          />
        </InputGroup>
        <div className="actions_wrap">
          <ul>
            <li>
              <Link to={`/streams/all`}>
                <i className="icon live-streaming"></i>
                Live Streaming
              </Link>
            </li>
            <li onClick={handleShowWritePostModal}>
              <i className="icon live-streaming"></i>
              Photo/ Video
            </li>
            {/* <li>
              <i className="icon live-streaming"></i>
              Activity
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default WritePost;

import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Game from "../Game";
import ChatStreaming from "../ChatStreaming";

import { useParams } from "react-router-dom";
import CommentBox from "../ChatStreaming/CommentBox";
import { LiveStream } from "../LiveStream";

/**
 * @author
 * @function GamesContainer
 **/

const GamesContainer = (props) => {
  const { streamId } = useParams();

  useEffect(() => {
    document.body.classList.add("is_game_screen");
    return () => document.body.classList.remove("is_game_screen");
  }, []);

  return (
    <>
      <div className="games_wrapper">
        <Row>
          <Col md={6}>
            <Game />
          </Col>
          <Col md={6}>
            <div className="stream_right from_games">
              <LiveStream fromGames streamId={streamId} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GamesContainer;

import React from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

/**
 * @author
 * @function SwitchButton
 **/

const SwitchButton = (props) => {
  const { isChecked, change, label } = props
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isChecked}
            onChange={change}
            name='checkedB'
            color='primary'
          />
        }
        label={label}
      />
    </>
  )
}

export default SwitchButton

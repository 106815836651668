import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PhotoDP from "../../assets/img/photos.png";
import { useUploadFiles } from "../../context/UploadFilesProvider";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { getUploadedPhotos, getUploadedPhotosByUserId } from "./ProfileService";
import { useUser } from "../../context/UserProvider";

/**
 * @author
 * @function Photos
 **/

  const Photos = (props) => {
  const { userData } = useUser();
  const { userOfDetails } = props;
  const { uploadFilesHandler, uploadedFiles } = useUploadFiles();
  const [photoList, setPhotoList] = useState([]);


  const getUploadedPhotosHanlder = async () => {
    const res = await getUploadedPhotos(userOfDetails?._id);

    if (res && res.status) {
      setPhotoList(res.data.reverse());
    }
  };

  // const handleFileChange = (e) => {
  //   const files = e.target.files;
    
  //   for (const file of files) {
  //     uploadFilesHandler(file);
  //   }

  //   //uploadImagesHandler(files)
    
  // };

  const handleFileChange = async (e) => {
    const files = e.target.files;
  
    
    const uploadPromises = [];
    for (const file of files) {
      const uploadPromise = uploadFilesHandler(file);
      uploadPromises.push(uploadPromise);
    }
    try {
      await Promise.all(uploadPromises);
      await getUploadedPhotosHanlder();
    } catch (error) {
      console.error("Error uploading files:", error);
  
    }
  };
  
  useEffect(() => {
    getUploadedPhotosHanlder();
  }, []);

  return (
    <>
      <div className="photos_wrapper">
        <div className="head">
          <p>{photoList.length} Photos</p>
          {userOfDetails._id === userData._id && (
            <label style={{ cursor: "pointer" }} htmlFor="upload-photo">
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                multiple
                onChange={handleFileChange}
              />

              <span>
                <i className="icon add"></i> Add Photos
              </span>
            </label>
          )}
        </div>
        <div className="body">
          <SimpleReactLightbox>
            <SRLWrapper>
              <Row>
                {photoList.map((photo) => {
                  return (
                    <Col md={3}>
                      <img src={photo.location} alt="a" />
                    </Col>
                  );
                })}
              </Row>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
      </div>
    </>
  );
};

export default Photos;

import axios from "axios";

export const feedPost = async (payload) => {
  try {
    const { data } = await axios.post(`post/upload`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const deleteFeedPost = async (id) => {
  try {
    const { data } = await axios.post(`feed/${id}/delete`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getAllPost = async (type) => {
  try {
    const { data } = await axios.get(`post?type=${type}`);
    return data;
  } catch (err) {
    return err;
  }
};
export const uploadImages = async (file) => {
  try {
    const { data } = await axios.post(`uploadFile`, file);
    return data;
  } catch (err) {
    return err;
  }
};
export const feedLike = async (id) => {
  try {
    const { data } = await axios.post(`feed/${id}/like`);
    return data;
  } catch (err) {
    return err;
  }
};
export const feedUnLike = async (id) => {
  try {
    const { data } = await axios.post(`feed/${id}/unlike`);
    return data;
  } catch (err) {
    return err;
  }
};
export const postFeedComment = async (id, comment) => {
  try {
    const { data } = await axios.post(`feed/${id}/comment`, { comment });
    return data;
  } catch (err) {
    return err;
  }
};
export const feedShare = async (id) => {
  try {
    const { data } = await axios.get(`feed/${id}/share`);
    return data;
  } catch (err) {
    return err;
  }
};
export const userWhoLikedFeed = async (id) => {
  try {
    const { data } = await axios.get(`feed/${id}/like`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getCommentsFeed = async (id) => {
  try {
    const { data } = await axios.get(`feed/${id}/comment`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getFeedById = async (id) => {
  try {
    const { data } = await axios.get(`feed/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};
export const updateFeed = async (payload, id) => {
  try {
    const { data } = await axios.post(`feed/${id}`, payload);
    return data;
  } catch (err) {
    return err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  feedPost,
  getAllPost,
  uploadImages,
  feedLike,
  feedUnLike,
  postFeedComment,
  feedShare,
  userWhoLikedFeed,
  getCommentsFeed,
  deleteFeedPost,
  getFeedById,
  updateFeed,
};

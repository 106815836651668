import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";
import { useStreamComment } from "../context/StreamCommentProvider";

const NEW_CHAT_MESSAGE_EVENT = "sendChat"; // Name of the event
const STREAM_CHAT_MESSAGE_EVENT = "stream"; // Name of the event
const SOCKET_SERVER_URL = "https://api.revitstream.com";

const useChat = (roomId) => {
  const [messages, setMessages] = useState([]); // Sent and received messages
  const socketRef = useRef();
  const { postCommentHandler } = useStreamComment();

  useEffect(() => {
    // Creates a WebSocket connection
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { roomId },
      path: "/chat/socket.io",
    });

    // Listens for incoming messages
    socketRef.current.on(NEW_CHAT_MESSAGE_EVENT, (message) => {
      const incomingMessage = {
        ...message,
      };
      setMessages((messages) => [...messages, incomingMessage]);
    });
    socketRef.current.on(STREAM_CHAT_MESSAGE_EVENT, (message) => {
      postCommentHandler(message);
    });

    // Destroys the socket reference
    // when the connection is closed
    return () => {
      socketRef.current.disconnect();
    };
  }, [roomId]);

  // Sends a message to the server that
  // forwards it to all users in the same room
  const sendMessage = (messageBody, type) => {
    if (type === "STREAM") {
      socketRef.current.emit(STREAM_CHAT_MESSAGE_EVENT, {
        message: messageBody.message,
        senderId: messageBody.senderId,
        streamId: messageBody.streamId,
      });
    } else {
      socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, {
        message: messageBody.message,
        senderId: messageBody.senderId,
        receiverId: messageBody.receiverId,
      });
    }
  };

  return { messages, sendMessage, setMessages };
};

export default useChat;

import React from "react";
import { Container } from "react-bootstrap";
import { Route, Switch } from "react-router";
import ChatStreaming from "../../containers/ChatStreaming";
import GamesContainer from "../../containers/Games";
import { LiveStream } from "../../containers/LiveStream";

import HeaderComponent from "../shared/Header";
import PageNotFound from "../shared/PageNotFound";

/**
 * @author
 * @function StreamLayout
 **/

const StreamLayout = (props) => {
  return (
    <>
      <HeaderComponent />
      <Container fluid>
        <Switch>
          <Route
            exact
            path="/streaming/:streamId/:role"
            component={LiveStream}
          />
          <Route
            exact
            path="/streaming/livestream/:streamId/:role"
            component={LiveStream}
          />
          <Route
            path="/streaming/game/:streamId/:role"
            component={GamesContainer}
          />

          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </Container>
    </>
  );
};

export default StreamLayout;

import { useEffect, useState } from "react";
import { getStreamRefresh } from "../containers/ChatStreaming/StreamingService";

const useAttendeeList = (streamId) => {
  const [attendedStreamList, setAttendedStreamList] = useState([]);

  useEffect(() => {
    const getStreamRefreshHandler = async () => {
      const res = await getStreamRefresh(streamId);

      if (res?.status) {
        for (const data of res?.data) {
          setAttendedStreamList(data?.attendieList);
        }
      }
    };
    const interval = setInterval(() => getStreamRefreshHandler(), 2000);
    return () => {
      clearInterval(interval);
    };
  }, [streamId]);

  return attendedStreamList;
};

export default useAttendeeList;

import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import Logo from "../../assets/img/Logo.png";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQueryHook";
import { emailVerificationAPI } from "./Service";

/**
 * @author
 * @function EmailVerification
 **/

export const EmailVerification = (props) => {
  let history = useHistory();
  const query = useQuery();
  const [isEmailVerified, setIsTokenVerified] = useState(false);
  const loginHandler = () => {
    history.push("/login");
  };

  const emailVerificationAPIHandler = useCallback(async () => {
    const res = await emailVerificationAPI(query.get("token"));
   
    if (res?.response.status === 401) {
      return;
    }
    setIsTokenVerified(true);
  }, [query]);

  useEffect(() => {
    emailVerificationAPIHandler();
  }, [emailVerificationAPIHandler]);

  return (
    <>
      <div className="email_verification">
        <img src={Logo} />
        <h2>Welcome!</h2>
        {isEmailVerified ? (
          <p>You have successfully verified your email</p>
        ) : (
          <p>
            We're excited to have you get started. Your email is successfully
            verified, kindly login to continue.
          </p>
        )}

        <Button variant="contained" color="primary" onClick={loginHandler}>
          Login
        </Button>
      </div>
    </>
  );
};

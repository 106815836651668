import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../../assets/img/Logo.png";
import LoginImg from "../../../assets/img/login_img.png";
import Input from "../../../components/UI/Input";
import AuthFooter from "../../../components/shared/AuthFooter";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { userSignup } from "../AuthService";
import SimpleSnackbar from "../../../components/UI/SnakBar";
import { useOffline } from "../../../context/OfflineProvider";
import AlertBox from "../../../components/UI/AlertBox";

/**
 * @author
 * @function SignupScreen
 **/

const SignupScreen = (props) => {
  const { isOffline } = useOffline();
  let history = useHistory();
  const [checked, setChecked] = useState(true);
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    email: "",
    password: "",
    
  });
  const [error, setError] = useState("");
  const [passworderror, setPasswordError] = useState(" ");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [values, setValues] = useState({
    showPassword: false,
  });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatch, setPasswordMatch]= useState(false)
  const handleCloseSuccessAlert = () => setShowSuccessAlert(false);
  const handleOpenSuccessAlert = () => setShowSuccessAlert(true);

  useEffect(() => {
    return () => {
      handleCloseSuccessAlert();
    };
  }, []);
  
  const handleCinfirmPassword=(e)=>{
    setConfirmPassword(e.target.value)
    // if(userDetails.password === confirmPassword){
    //   setPasswordError(" ");
    // }
    // else(userDetails.password !== confirmPassword){
    //   setPasswordError("Enter Same Password ");
    // }
   
   
  }
  useEffect(() => {
    if(userDetails.password !== confirmPassword ){
      setPasswordError("Enter Same Password")
      setPasswordMatch(false)
    }
    else{
      setPasswordError(" ")
      setPasswordMatch(true)
    }
  }, [userDetails.password, confirmPassword])
  
  const handleClickShowPassword = () => {
    setValues((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCheckChange = (event) => {
   
    setChecked(event.target.checked);
  };

  const changeHandler = (e) => {
    const { value, name } = e.target;
    setUserDetails((prevState) => ({ ...prevState, [name]: value }));
    setError("");
   
  };
  const isSignupEnabled= passwordMatch && checked

  
  const userSignupHandler = async () => {
    const { fullName, email, password } = userDetails;
    const payload = {
      email,
      password,
      confirmPassword: password,
      name: fullName,
      country: "india",
    };
    const res = await userSignup(payload);

    if (res.status) {
      setOpen(true);
      setMessage("Signup successfull");
      handleOpenSuccessAlert();
    } else {
      setError(res?.response.data.message);
    }
    //    const resData = res
    //    if (resData.data.status) {
    //    } else {
    //     setError(resData.data.message)
    //  }
  };

  const gotToSigninHandler = () => {
    history.push("/login");
    handleCloseSuccessAlert();
  };

  return (
    <>
      <div className="login_wrapper signup_wrapper">
        <div className="head">
          <Link to="/">
            <img src={Logo} className="logo" alt="logo" />
          </Link>
        </div>
        <div className="middle_content">
          <Container>
            <Row>
              <Col md={12}>
                <div className="form_wrap">
                  <h1>Welcome to Revit Stream and Revit Community</h1>
                  <p>
                    {/* Come join the LT Community! */}
                    Let’s set up your account.
                  </p>
                  <AlertBox
                    onShow={showSuccessAlert}
                    onHide={handleCloseSuccessAlert}
                  />
                  <div className="input_box signup">
                    <Input
                      label="Full Name"
                      placeholder="enter your name"
                      icon="user"
                      name="fullName"
                      onChange={changeHandler}
                      onKeyPress={(event) => {
                        if (!/[a-zA-Z[0-9\s]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      id="fullName"
                    />
                    <Input
                      label="Email ID"
                      placeholder="enter your email"
                      icon="email"
                      name="email"
                      onChange={changeHandler}
                      type="email"
                      id="email"
                    />
                    {/* <Input
                      label="Mobile Number"
                      placeholder="enter your number"
                      icon="mobile"
                      name="mobile"
                      onChange={changeHandler}
                      type="text"
                    /> */}
                    {/* <Input
                      label="Password"
                      placeholder="enter your password"
                      icon="lock"
                      name="password"
                      onChange={changeHandler}
                      type="password"
                    /> */}
                    <div className="input_wrap">
                      <TextField
                        label="Password"
                        placeholder="enter your password"
                        id="password"
                        name="password"
                        type={values.showPassword ? "text" : "password"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={changeHandler}
                        variant="filled"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {values.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                              {/* <i className={`icon ${icon}`}></i> */}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="input_wrap">
                      <TextField
                        label="Confirm Password"
                        placeholder="enter your confirm password"
                        id="password"
                        name="confirmpassword"
                        value={confirmPassword}
                        type={values.showPassword ? "text" : "password"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleCinfirmPassword}
                        variant="filled"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {values.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                              {/* <i className={`icon ${icon}`}></i> */}
                            </InputAdornment>
                          ),
                        }}
                      />
                      {console.log("password is", userDetails.password)}
                      {console.log("confirm password is", confirmPassword)}
                      
                    </div>
                    {confirmPassword!==userDetails.password &&
                      <p className="error">{passworderror}</p>
                      }


                  </div>
                  <div className="remomber_forgot_wrap signup">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleCheckChange}
                          name="checkedB"
                          color="primary"
                          id="agreeTerms"
                        />
                      }
                      label={
                        <span>
                          You are agreeing to the
                          <>
                            <Link to="/terms">Terms of Services</Link>
                          </>
                          and
                          <>
                            <Link to="/privacy">Privacy Policy</Link>
                          </>
                        </span>
                      }
                    />
                  </div>

                  <div className="button_wrap">
                    {error && <p className="error ">{error}</p>}
                    
                    <div className="d-flex justify-content-between">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={userSignupHandler}
                        disabled={!isSignupEnabled}
                        id="signup"
                      >
                        Sign Up
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={gotToSigninHandler}
                        id="login"
                      >
                        Login
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer_content"></div>
        <AuthFooter />
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default SignupScreen;

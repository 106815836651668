import React from 'react'
import Icon from '../Icon/Icon'

import style from '../Message/Message.module.scss'
import Status from './Status'

const StatusMessage = {
  [Status.EMPTY]: (
    <p className={style.message__text}>
      <i className="icon drag"></i> Drag to reposition photo
    </p>
  ),
  [Status.LOADING]: <Icon name="loading" size={48} />,
  [Status.DRAGOVER]: (
    <>
      <Icon name="upload" size={48} />
      <p className={style.message__text}>Drop your photo</p>
    </>
  ),
  [Status.INVALID_FILE_TYPE]: (
    <>
      <p className={style.message__text}>Only images allowed.</p>
      <p className={style.message__text}>
        <i className="icon drag"></i> Drag to reposition photo
      </p>
    </>
  ),
  [Status.INVALID_IMAGE_SIZE]: (
    <>
      <p className={style.message__text}>
        Your photo must be larger than 350px.
      </p>
      <p className={style.message__text}>
        <i className="icon drag"></i> Drag to reposition photo
      </p>
    </>
  ),
  [Status.LOADED]: null,
}

export default StatusMessage

import React, { useState, useEffect } from "react";

import { Col, Container, Row } from "react-bootstrap";
import { Route, Switch, useParams } from "react-router";

import ProfileScreen from "../../containers/Profile";
import EditProfile from "../../containers/Profile/EditProfile";
import Followers from "../../containers/Profile/Followers";
import Photos from "../../containers/Profile/Photos";
import ProfileHead from "../../containers/Profile/ProfileHead";
import RemoveFollower from "../../containers/Profile/RemoveFollower";
import StreamAccount from "../../containers/Profile/StreamAccount";
import ContactList from "../../containers/Shared/ContactList";
import LeftMenu from "../../containers/Shared/LeftMenu";
import HeaderComponent from "../shared/Header";
import CustomModal from "../UI/Modal";

import { userProfile } from "../../containers/Profile/ProfileService";

import Following from "../../containers/Profile/Following";
import ProductOfUser from "../../containers/Profile/ProductsOfUser";
import PageNotFound from "../shared/PageNotFound";
import { useUser } from "../../context/UserProvider";

const ProfileLayout = () => {
  const { id } = useParams();

  const { getUserOfData, userOfData } = useUser();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showStreamModal, setShowStreamModal] = useState(false);
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [showFollowingModal, setShowFollowingModal] = useState(false);
  const [showRemoveFollowerModal, setShowRemoveFollowerModal] = useState(false);
  const [showPhotosModal, setShowPhotosModal] = useState(false);

  const [loggedInUserDetails, setLoggedInDetails] = useState({});

  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowEditModal = () => setShowEditModal(true);
  const handleCloseStreamModal = () => setShowStreamModal(false);
  const handleShowStreamModal = () => setShowStreamModal(true);
  const handleCloseFollowersModal = () => setShowFollowersModal(false);
  const handleShowFollowersModal = () => setShowFollowersModal(true);
  const handleCloseFollowingModal = () => setShowFollowingModal(false);
  const handleShowFollowingModal = () => setShowFollowingModal(true);
  const handleCloseRemoveFollowerModal = () =>
    setShowRemoveFollowerModal(false);
  const handleShowRemoveFollowerModal = () => setShowRemoveFollowerModal(true);
  const handleClosePhotosModal = () => setShowPhotosModal(false);
  const handleShowPhotosModal = () => setShowPhotosModal(true);

  const getUserProfileHandler = async () => {
    const res = await userProfile();

    if (res && res.status) {
      setLoggedInDetails(res.data);
    }
  };

  useEffect(() => {
    getUserProfileHandler();
  }, []);

  

  return (
    <>
      <HeaderComponent />
      <div className="wrapper">
        <div className="content_wrapper">
          <div className="profile_wrapper">
            <ProfileHead
              handleShowEditModal={handleShowEditModal}
              handleShowFollowersModal={handleShowFollowersModal}
              handleShowFollowingModal={handleShowFollowingModal}
              handleShowPhotosModal={handleShowPhotosModal}
              handleShowStreamModal={handleShowStreamModal}
              loggedInUserDetails={loggedInUserDetails}
              getUserProfileHandler={getUserProfileHandler}
            />
            <div className="profile_body">
              <Container fluid>
                <Row>
                  <Col md={2} className="left_side">
                    {/* <div className="start_stream_wrap">
                      {!isOtherUser && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleShowStreamModal}
                        >
                          START STREAM
                        </Button>
                      )}
                    </div> */}
                    <LeftMenu fromProfile userOfDetails={userOfData} />
                  </Col>
                  <Col md={7} className="middle">
                    <Switch>
                      <Route
                        exact
                        path="/profile/:id"
                        component={ProfileScreen}
                      />
                      <Route
                        exact
                        path="/profile/:id/products"
                        component={ProductOfUser}
                      />
                      <Route path="*">
                        <PageNotFound />
                      </Route>
                    </Switch>
                  </Col>
                  <Col md={3} className="right_side">
                    <ContactList />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        show={showEditModal}
        handleClose={handleCloseEditModal}
        title="Edit Profile"
        size="lg"
      >
        <EditProfile userId={id} closeModal={handleCloseEditModal} />
      </CustomModal>
      <CustomModal
        show={showStreamModal}
        handleClose={handleCloseStreamModal}
        title="Stream Account"
        size="md"
      >
        <StreamAccount handleCloseModal={handleCloseStreamModal} />
      </CustomModal>
      <CustomModal
        show={showFollowersModal}
        handleClose={handleCloseFollowersModal}
        title="Followers"
      >
        <Followers
          userId={id}
          onRemoveFollowerHandler={handleShowRemoveFollowerModal}
        />
      </CustomModal>
      <CustomModal
        show={showFollowingModal}
        handleClose={handleCloseFollowingModal}
        title="Following"
      >
        <Following
          userId={id}
          onRemoveFollowerHandler={handleShowRemoveFollowerModal}
        />
      </CustomModal>
      <CustomModal
        show={showRemoveFollowerModal}
        handleClose={handleCloseRemoveFollowerModal}
        className="remove_follower_modal"
      >
        <RemoveFollower />
      </CustomModal>
      <CustomModal
        show={showPhotosModal}
        handleClose={handleClosePhotosModal}
        title="Photos"
        size="lg"
      >
        <Photos userOfDetails={userOfData} />
      </CustomModal>
    </>
  );
};

export default ProfileLayout;

import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Routes from "./routes";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/app.scss";
import "./assets/styles/responsive.scss";

import UploadFilesProvider from "./context/UploadFilesProvider";
import StreamCommentProvider from "./context/StreamCommentProvider";

import NotificationContext from "./context/NotificationProvider";
import UserProfilePicProvider from "./context/UserProfilePicProvider";
import OfflineProvider from "./context/OfflineProvider";
import OfflineContainer from "./containers/Offline";
import UserProvider from "./context/UserProvider";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <CssBaseline />
        <ToastContainer />
        <OfflineProvider>
          <UserProvider>
            <StreamCommentProvider>
              <UploadFilesProvider>
                <NotificationContext>
                  <UserProfilePicProvider>
                    <Routes />
                  </UserProfilePicProvider>
                </NotificationContext>
              </UploadFilesProvider>
            </StreamCommentProvider>
          </UserProvider>
        </OfflineProvider>
      </BrowserRouter>
    </>
  );
}

export default App;

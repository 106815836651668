import { Button } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import Logo from "../../assets/img/logo_home.png";
import GameImg from "../../assets/img/game_banner.png";
import StreamingLaptopImg from "../../assets/img/streaming_laptop.png";
import OurCompanyImg from "../../assets/img/our_company.png";
import RvtIcon from "../../assets/img/rvt.png";
import { useHistory } from "react-router";
import HomeNavbar from "../Shared/HomeNavbar";
import LocalStorageService from "../../utils/LocalStorageService";
import { userLogin } from "../Auth/AuthService";
import { sha256 } from "js-sha256";
import AuthFooter from "../../components/shared/AuthFooter";

/**
 * @author
 * @function Home
 **/

const Home = (props) => {
  let history = useHistory();
  const loginHandler = () => {
    history.push("/login");
  };
  const signupHandler = () => {
    history.push("/signup");
  };

  const userLoginHandler = useCallback(
    async (data) => {
      const payload = {
        email: data.email,
        password: data.passowrd,
        mobile: "",
      };
      const res = await userLogin(payload);
      if (res && res?.status) {
        localStorage.setItem("token", res.data.token);
        LocalStorageService.setItem("userDetail", res.data.user);
        history.push("/feed");
      }
    },
    [history]
  );

  useEffect(() => {
    const rememberObj = LocalStorageService.getItem("remeberMe");
   
    if (rememberObj) {
      userLoginHandler(rememberObj);
    }
  }, [userLoginHandler]);

  return (
    <>
      <div className="home_wrapper">
        <div id="home">
          <HomeNavbar />

          <section className="banner">
            <div className="banner_content">
              <div className="logo_wrap"  >
                <img src={Logo} alt="Logo" className="logo-img" />
              </div>
              <p>
                We talked to YouTube influencers who are discouraged with
                changing monetization methods. Instagram influencers who are
                tired of pushing their customers to multiple sites to sell
                products, and TikTok influencers that are tired of changing
                rules and their cancel culture. We built a platform that
                combines the ability to stream content to unlimited viewers,
                giving the content creator the ability to charge by the minute.
                Create a store to sell their products or an auction to sell
                limited production items.
              </p>
              <div style={{display:"flex", gap:"10px", justifyContent:"center"}}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={loginHandler}
                className=""
              >
                Signin
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                className="signup-btn"
                onClick={signupHandler}
              >
                Signup
              </Button>
              </div>
              
            </div>

            <div className="layer"></div>
          </section>
        </div>

        {/* <section className="section games" id="games">
          <Container fluid>
            <Row>
              <Col md={6}>
                <h2 className="title">Poker Game</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem
                  Ipsum.Contrary to popular belief, Lorem Ipsum is not simply
                  random text. It has roots in a piece of classical Latin
                  literature from 45 BC It is a long established fact that a
                  reader will be distracted by the readable content of a page
                  when looking at its layout. The point of using Lorem Ipsum is
                  that it has a more-or-less normal distribution of letters, as
                  opposed to using 'Content here, content here', making it look
                  like readable English. Many desktop publishing packages and
                  web page editors now use Lorem Ipsum as their default model
                  text, and a search for 'lorem ipsum' will uncover many web
                  sites still in their infancy. Various versions have evolved
                  over the years, sometimes by accident, sometimes on purpose
                  (injected humour and the like).Lorem Ipsum is simply dummy
                  text of the printing and typesetting industry. Lorem Ipsum has
                  been the industry's standard dummy text ever since the 1500s,
                  when an unknown printer took a galley of type and scrambled.
                </p>
              </Col>
              <Col
                md={6}
                className="text-center d-flex align-items-center justify-content-center"
              >
                <img src={GameImg} alt="" />
              </Col>
            </Row>
          </Container>
        </section> */}
        {/* <section className="section streaming" id="streaming">
          <h2 className="title">Streaming</h2>
          <img src={StreamingLaptopImg} alt="" />
        </section> */}
        {/* <section className="section our_company" id="rvtToken">
          <Container fluid>
            <Row>
              <Col
                md={6}
                className="text-center d-flex align-items-center justify-content-center"
              >
                <img src={RvtIcon} alt="" />
              </Col>
              <Col md={6}>
                <h2 className="title">RVT Token</h2>
                <p>
                  The Revitstream contains the RVT token, which is a deployed on
                  Tron Blockchain. It is an TRC20 token that provides a standard
                  ERC20 token interface. It also contains logic for burning and
                  authorized minting of RVT.
                  <br />
                  <br />A customer can sign up on our site, buy crypto tokens
                  for one dollar a piece, 10 dollar minimum, to view their
                  favorite content providers, and use those tokens to watch them
                  live and purchase items from their store or auction. Using
                  tokens avoids re-occurring billing that can end up in
                  chargebacks and solves the problem of thousands of micro
                  transactions per stream.
                </p>
              </Col>
            </Row>
          </Container>
        </section> */}
        <section className="section influencer_fans" id="rvtToken">
          <Container fluid>
            <Row>
              <Col md={6}>
                <h2 className="title fans">FANS </h2>
                <p>
                  For The Fans Watch LIVE streams of your favorite influencer.
                  RevitStream gives you access to the creative process and the
                  content before everyone else. Connect via your bank; no cash
                  wallet, no credit card. Buy RVT tokens, spend what you want,
                  sell back what you don’t. Seamless transfer between FIAT and
                  digital currency; one in the same. You are in the driver’s
                  seat. Sit back and watch it happen - while it’s happening.
                  <br />
                  <br />
                </p>
                <h2 className="title influencer">INFLUENCERS</h2>
                <p>
                  {" "}
                  Don’t just get paid for your content, get paid to create it.
                  RevitStream is a pay-per-view live streaming service that
                  gives you control: You set the bar, You choose your cost per
                  minute, You build a store to sell merch, You create auctions
                  to sell one-off items, You see what hits hard, You see the
                  market. Get paid with the registered RVT tethered token (TRC20
                  blockchain). No contracts, no 1099’s and a seamless transfer
                  between FIAT and digital currency. Add a revenue stream that
                  will never compete with your other businesses.
                </p>
              </Col>
              <Col
                md={6}
                className="text-center d-flex align-items-center justify-content-center home-image"
              >
                <img src={OurCompanyImg} alt="" style={{width:"70%"}} />
              </Col>
            </Row>
          </Container>
        </section>
        <AuthFooter />
      </div>
    </>
  );
};

export default Home;

import axios from "axios";

export const getAllNotifications = async () => {
  try {
    const { data } = await axios.get(`notification`);
    return data;
  } catch (err) {
    return err;
  }
};
export const markReadAllNotifications = async () => {
  try {
    const { data } = await axios.post(`notification/markAllRead`);
    return data;
  } catch (err) {
    return err;
  }
};

export const deleteNotification= async  (notificationId)  =>{
  try{
    const {data} = await axios.delete(`notification/${notificationId}`)
    return data;
  }
  catch(err){
    return err;
  }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllNotifications,
  markReadAllNotifications,
  
};























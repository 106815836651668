import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Form, Row, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import CustomModal from "../../../../components/UI/Modal";
import { getBuyerOrders, updateStatus } from "../../Service";
import { Button } from "@material-ui/core";
import SimpleSnackbar from "../../../../components/UI/SnakBar";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

/**
 * @author
 * @function SellerOrders
 **/

export const SellerOrders = (props) => {
  const history = useHistory();
  const [orderList, setOrderList] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseStatusModal = () => setShowStatusModal(false);
  const handleShowStatusModal = (data) => {
    setProductDetails(data);
    setShowStatusModal(true);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setProductDetails((prev) => ({ ...prev, updateStatusType: value }));
  };

  const getBuyerOrdersHandler = async () => {
    const res = await getBuyerOrders(`&subType=SELL`);

    if (res.status) {
      for (const data of res.data) {
        setOrderList(data.data);
      }
    }

    
  };

  useEffect(() => {
    getBuyerOrdersHandler();
  }, []);

  const updateStatusHandler = async () => {
    const { product, updateStatusType } = productDetails;
    const payload = {
      status: updateStatusType,
      transactionId: productDetails._id,
    };
    const res = await updateStatus(product._id, payload);
   
    if (res.status) {
      setMessage("Status updated successfully");
      setOpen(true);
      handleCloseStatusModal();
      getBuyerOrdersHandler();
    }
  };



  return (
    <>
      <div className="dashboard_wrapper seller_order_wrapper">
        <div className="head">
          <h5>Order recieved</h5>
        </div>

        <div className="body">
          {orderList.length === 0 && (
            <h5 className="text-center ">No Data Found</h5>
          )}
          {orderList.length > 0 && (
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Product Name & Price</th>
                  <th>Product Image</th>

                  <th>Buyer</th>
                  <th>Estimated Delivery</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {orderList.map((p, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      {p?.product?.productName}
                      <p class="p_price">
                        <i class="icon revit_coin"></i> {p?.product?.amount}
                      </p>
                    </td>

                    <td>
                      {" "}
                      {p?.product?.mediaLinks?.map((url) => (
                        <img src={url.location} alt="" />
                      ))}
                    </td>

                    <td>
                      <p className="mb-0">
                        {p.buyer.email}
                        <br />
                        {p?.data?.addressDetails.map((item, i) => {
                          if (i === 0) {
                            return (
                              <Link to={`/profile/${p.buyer._id}`}>{item}</Link>
                            );
                          }
                          return <p className="m-0">{item}</p>;
                        })}
                      </p>
                    </td>
                    <td>03th Sep 2021</td>
                    <td>
                      {" "}
                      <Badge
                        variant={
                          p?.status === "FAILED"
                            ? "danger"
                            : p.status === "CANCELLED"
                            ? "danger"
                            : p.status === "ACCEPTED"
                            ? "success"
                            : p.status === "PENDING"
                            ? "warning"
                            : ""
                        }
                      >
                        {p?.status}
                      </Badge>
                    </td>
                    <td>
                      {p?.status !== "FAILED" && p.status !== "CANCELLED" && (
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={() => {
                            handleShowStatusModal(p);
                          }}
                        >
                          Edit Status
                        </Button>
                      )}
                      <p>
                        <QuestionAnswerIcon
                          style={{ cursor: "pointer", marginTop: "10px" }}
                          onClick={() => {
                            history.push(`/messages/user/${p.buyer._id}`);
                          }}
                        />
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
      <CustomModal
        show={showStatusModal}
        handleClose={handleCloseStatusModal}
        title="Status Update"
        size="md"
      >
        <div className="status_wrapper">
          <Form.Group>
            <Form.Label>Status *</Form.Label>
            <Form.Control
              as="select"
              name="status"
              value={productDetails?.updateStatusType}
              onChange={handleChange}
            >
              <option value="-1">None</option>
              <option value="PENDING">Pending</option>
              <option value="PLACED">Placed</option>
              <option value="SHIPPED">Shipped</option>
              <option value="DELIVERED">Delivered</option>
              <option value="CANCELLED">Cancelled</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Button
              variant="contained"
              color="primary"
              onClick={updateStatusHandler}
            >
              Update
            </Button>
          </Form.Group>
        </div>
      </CustomModal>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

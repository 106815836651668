import axios from "axios";

export const getAllMessages = async (sender, receiver) => {
  try {
    const { data } = await axios.get(
      `chats?senderId=${sender}&receiverId=${receiver}`
    );
    return data;
  } catch (err) {
    return err.response;
  }
};

export const getAllContacts = async () => {
  try {
    const { data } = await axios.get(`user/linkedUsers`);
    return data;
  } catch (err) {
    return err.response;
  }
};

export const getFollowers = async (id) => {
  try {
    const { data } = await axios.get(`user/profile/${id}/followers`);
    return data;
  } catch (err) {
    return err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllMessages,
  getAllContacts,
  getFollowers
};

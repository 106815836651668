import React, { createContext, useContext } from "react";
import { useState } from "react";
import { uploadImages } from "../containers/Feed/FeedService";

const UploadFilesContext = createContext();

export function useUploadFiles() {
  return useContext(UploadFilesContext);
}

export default function ContactsProvider({ children }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imgUrl, setImgUrl] = useState([]);

  const uploadFilesHandler = async (file, type) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("purpose", type);
    const res = await uploadImages(formData);

    if (res.status) {
      setUploadedFiles((prevState) => [...prevState, res.data._id]);
      setImgUrl((prevState) => [...prevState, res.data.location]);
    }
  };
  const removeFiles = (index) => {
    const temp = [...uploadedFiles];
    const tempUrl = [...imgUrl];

    if (index > -1) {
      temp.splice(index, 1);
      tempUrl.splice(index, 1);
    }
    setUploadedFiles(temp);
    setImgUrl(tempUrl);
  };

  const onSubmitUploadFilesHandler = (files) => {
    setUploadedFiles(files);
  };

  const values = {
    uploadedFiles,
    uploadFilesHandler,
    removeFiles,
    setUploadedFiles,
    imgUrl,
    setImgUrl,
    onSubmitUploadFilesHandler,
  };
  return (
    <UploadFilesContext.Provider value={values}>
      {children}
    </UploadFilesContext.Provider>
  );
}

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import SimpleSnackbar from "../../components/UI/SnakBar";

import {
  deleteUserAddress,
  getUserAddress,
  makeDefaultAddress,
} from "./ProductService";
import { getCountryByCode } from "../../utils/common";
import { Button } from "@material-ui/core";

/**
 * @author
 * @function MyAddress
 **/

const MyAddress = (props) => {
  let history = useHistory();
  const [userAddressList, setUserAddressList] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const goToaddressHandler = () => {
    history.push("/address/add");
  };

  const getUserAddressHandler = async () => {
    const res = await getUserAddress();

    if (res && res.status) {
      setUserAddressList(res.data);
    }
  };

  const deleteUserAddressHandler = async (id) => {
    const res = await deleteUserAddress(id);
    if (res && res.status) {
      getUserAddressHandler();
      setOpen(true);
      setMessage("Address deleted successfull");
    }
  };

  useEffect(() => {
    getUserAddressHandler();
  }, []);

  useEffect(() => {
    document.body.classList.add("is-hide-contact");
    return () => {
      document.body.classList.remove("is-hide-contact");
    };
  }, []);

  const makeDefaultAddressHandler = async (id) => {
    const res = await makeDefaultAddress(id);

    if (res.status) {
      getUserAddressHandler();
    }
  };

  return (
    <>
      <div className="my_address_wrap">
        <div className="head">
          <h5>My Address</h5>
        </div>

        <div className="body">
          <div className="add_address_btn" onClick={goToaddressHandler}>
            <i className="icon add_2"></i> Add a new Address
          </div>

          <div className="list">
            <ul className="address_ul">
              {userAddressList.length > 0 ? (
                <>
                  {userAddressList.map((address) => {
                    return (
                      <li key={address._id}>
                        <div className="action">
    

                          <ul>
                            <li>
                            {!address.isDefaultAddress && (
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={() =>
                                makeDefaultAddressHandler(address._id)
                              }
                              className="d-btn"
                            >
                              Make default
                            </Button>
                          )}
                            </li>
                            <li
                              onClick={() =>
                                deleteUserAddressHandler(address._id)
                              }
                            >
                              <i className="icon delete_2"></i>
                            </li>
                            <li
                              onClick={() =>
                                history.push(`/address/update/${address._id}`)
                              }
                            >
                              <i className="icon edit_pencil"></i>
                            </li>
                          </ul>
                        </div>
                        {address.isDefaultAddress && (
                          <div className="default">Default</div>
                        )}

                        <h5>
                          {address.fullName} <span>{address.mobileNo}</span>
                        </h5>
                        <p>
                          {address.address}, {(address.country)}
                          , {address.city}, {address.state}- {address.pincode}
                        </p>
                      </li>
                    );
                  })}
                </>
              ) : (
                "No Address Found"
              )}
            </ul>
          </div>
        </div>
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default MyAddress;

import React from "react";

/**
 * @author
 * @function Products
 **/

const Products = (props) => {
  const { productData, goToProductDetailHandler } = props;
  return (
    <>
      <div
        className="shop_card"
        onClick={() => goToProductDetailHandler(productData._id)}
      >
        <div className="top">
          <h5>{productData.productName}</h5>
          <p>{productData.brandName}</p>
        </div>
        <div className="middle">
          {productData.productType === "AUCTION" && (
            <p className="auction_type ribbon"></p>
          )}
          {productData.mediaLinks.slice(0, 1).map((img) => {
            return <img src={img.location} alt="" />;
          })}
        </div>
        <div className="bottom">
          <ul>
            <li>
              <i className="icon star"></i>
            </li>
            <li>
              <i className="icon star"></i>
            </li>
            <li>
              <i className="icon star"></i>
            </li>
            <li>
              <i className="icon star"></i>
            </li>
          </ul>
          <p>$ {productData.amount}</p>
        </div>
        <div className="sticky">
          <ul>
            <li>
              <span>
                <i className="icon fav"></i>
              </span>
            </li>
            <li>
              <span>
                <i className="icon zoom-in"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Products;

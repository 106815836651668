import axios from "axios";

export const getSearchData = async (type, query) => {
  try {
    const { data } = await axios.get(
      `search?type=${type}&searchQuery=${query}`
    );
    return data;
  } catch (err) {
    return err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getSearchData };

import React from "react";

/**
 * @author
 * @function AttendedStreamList
 **/

export const AttendedStreamList = (props) => {
  const { attendedStreamList } = props;
  return (
    <>
      <div className="stream_attendie_list_wrap">
        <ul className="stream_attendie_list">
          {attendedStreamList.map((list) => (
            <li>
              <div className="media">
                {list.profilePic ? (
                  <img src={list.profilePic} alt="" />
                ) : (
                  <span className="dp_name">{list.name.charAt(0)}</span>
                )}

                <div className="media_body">
                  <a
                    href={`/profile/${list._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @{list?.username ? list?.username : "username"}
                  </a>
                  <p>{list?.location}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import AgoraRTC from "agora-rtc-sdk-ng";
import isEmpty from "is-empty";
import {
  endStream,
  getStreamRefresh,
  joinStream,
  updateStreamRefresh,
} from "../ChatStreaming/StreamingService";
import { IoVolumeHigh } from "react-icons/io5";

import HomeIcon from "@material-ui/icons/Home";
import { useTimer } from "react-timer-hook";

import CommentBox from "../ChatStreaming/CommentBox";
import Products from "./Products";
import { useStreamComment } from "../../context/StreamCommentProvider";
import LeftMenu from "../Shared/LeftMenu";
import CustomModal from "../../components/UI/Modal";
import { AttendedStreamList } from "../ChatStreaming/AttendedStreamList";
import useAttendeeList from "../../hooks/useAttendeeList";
import Timer from "./Timer";
import { useUser } from "../../context/UserProvider";

/**
 * @author
 * @function LiveStream
 **/

let rtc = {
  // For the local audio and video tracks.
  localAudioTrack: null,
  localVideoTrack: null,
  client: null,
};
let prevTokenValidFor = null;
let isAudioAutoplayFailed = false;

export const LiveStream = (props) => {
  const { streamId, role } = useParams();
  let history = useHistory();
  const { commentList } = useStreamComment();
  const { userData } = useUser();
  const attendedStreamList = useAttendeeList(streamId);
  //const attendedStreamList = [];

  const [streamData, setStreamData] = useState({});

  const [isCommentVisible, setCommentVisibility] = useState(false);
  const [openShop, setOpenShop] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openGameStart, setOpenGameStart] = useState(false);
  const [streamStatus, setStreamStatus] = useState({
    isStarted: false,
    isEnded: false,
  });
  const [videoOptions, setVideoOptions] = useState({
    cam: true,
    mic: true,
  });

  const [waitingStreamData, setWaitingStreamData] = useState({});
  const [sellingProducts, setSellingProducts] = useState([]);

  const [finishStatus, setfinishStatus] = useState(false);

  const [showAttendiModal, setShowAttendiModal] = useState(false);
  const [isEndClick, setEndClick] = useState(false);
  const [tokenValidFor, setTokenValidFor] = useState(0);
  const [streamOptions, setStreamOptions] = useState({});
  const [streamerData, setStreamerData] = useState({});

  const handleCloseAttendiModal = () => setShowAttendiModal(false);
  const handleOpenAttendiModal = () => setShowAttendiModal(true);

  const closeCommentHandler = () => {
    document.body.classList.add("is_comment_off");
    setCommentVisibility(true);
  };
  const showCommentHandler = () => {
    document.body.classList.remove("is_comment_off");
    setCommentVisibility(false);
  };
  const openShopHandler = () => {
    closeMenuHandler();
    setOpenShop(true);
  };
  const closeShopHandler = () => setOpenShop(false);
  const toggleMenuHandler = () => setOpenMenu(!openMenu);
  const closeMenuHandler = () => setOpenMenu(false);
  const handleGameStartOpen = () => {
    closeMenuHandler();
    setOpenGameStart(true);
  };

  useEffect(() => {
    document.body.classList.add("hide_nav");
    document.body.classList.add("is_stream_page");

    return () => {
      document.body.classList.remove("hide_nav");
      document.body.classList.remove("is_stream_page");
    };
  }, []);

  const endStreamHandler = useCallback(async () => {
    const res = await endStream(streamId);

    if (res && res.status) {
      setStreamStatus({ isEnded: true });
      window.location.href = "/streams/all";
    }
  }, [streamId]);

  const onBackButtonEvent = useCallback(
    (e) => {
      e.preventDefault();
      if (!finishStatus) {
        if (
          window.confirm(
            `Do you want to ${role === "host" ? "end" : "leave"} stream?`
          )
        ) {
          setfinishStatus(true);
          // your logic
          props.history.push("/");
          if (role === "host") {
            endStreamHandler();
            // leaveStreamHandler();
          } else {
            // leaveStreamHandler();
          }
        } else {
          window.history.pushState(null, null, window.location.pathname);
          setfinishStatus(false);
        }
      }
    },
    [endStreamHandler, finishStatus, props.history, role]
  );

  useEffect(() => {
    if (streamStatus.isStarted) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", onBackButtonEvent);

      // window.addEventListener("beforeunload", onBackButtonEvent);
      window.addEventListener("unload", onBackButtonEvent);
    }

    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
      // window.addEventListener("beforeunload", onBackButtonEvent);
      window.addEventListener("unload", onBackButtonEvent);
    };
  }, [onBackButtonEvent, streamStatus.isStarted]);

  const joinStreamHandler = useCallback(async () => {
    const res = await joinStream(streamId);

    if (res && res.status) {
      setStreamStatus((prev) => ({ ...prev, isStarted: true }));
      //   join(res.data.appid, res.data.channel, res.data.token, res.data.uid);
      let options = {
        // Pass your app ID here.
        appId: res.data.appid,
        // Set the channel name.
        channel: res.data.channel,
        // Use a temp token
        token: res.data.token,
        // Uid
        uid: res.data.uid,
      };
      setStreamOptions(options);
      startBasicLiveStreaming(options);

      setStreamData(res.data.stream);
      setStreamerData(res.data.stream.user);
      setTokenValidFor(res.data.tokenValidFor);
      prevTokenValidFor = res.data.tokenValidFor;

      setSellingProducts(res?.data?.stream?.products);
    } else if (!res?.data?.streamStarted) {
      setWaitingStreamData(res.data);
      setStreamStatus((prev) => ({ ...prev, isStarted: false }));
    } else if (res?.data?.streamEnded) {
      setWaitingStreamData(res.data);
      setStreamStatus((prev) => ({ ...prev, isEnded: true }));
    } else {
      setWaitingStreamData(res.data);
    }
  }, [role, streamId]);

  const startBasicLiveStreaming = async (options) => {
    rtc.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

    if (role === "host") {
      rtc.client.setClientRole("host");
      await rtc.client.join(
        options.appId,
        options.channel,
        options.token,
        +options.uid
      );

      // Javascript
      // Enable log upload
      AgoraRTC.enableLogUpload();
      // Set the log output level as INFO
      AgoraRTC.setLogLevel(1);

      // Create an audio track from the audio sampled by a microphone.
      rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // Create a video track from the video captured by a camera.
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      // Publish the local audio and video tracks to the channel.
      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
      // Dynamically create a container in the form of a DIV element for playing the remote video track.
      //  const localPlayerContainer = document.getElementById("local_stream");
      // Specify the ID of the DIV container. You can use the `uid` of the remote user.
      // const remotePlayerContainer = document.createElement("div");
      // Specify the ID of the DIV container. You can use the `uid` of the remote user.
      // remotePlayerContainer.id = user.uid.toString();
      // remotePlayerContainer.classList.add("local_stream");

      // remotePlayerContainer.style.width = "75%";
      // remotePlayerContainer.style.height = "640px";
      // document.body.append(remotePlayerContainer);
      const localPlayerContainer = document.createElement("div");
      // Specify the ID of the DIV container. You can use the `uid` of the remote user.
      localPlayerContainer.id = options.uid;
      localPlayerContainer.classList.add("local_stream");

      localPlayerContainer.style.width = "78%";
      localPlayerContainer.style.height = "640px";
      document.body.append(localPlayerContainer);
      rtc.localVideoTrack.play(localPlayerContainer);
    }

    if (role === "audience") {
      rtc.client.setClientRole("audience");
      await rtc.client.join(
        options.appId,
        options.channel,
        options.token,
        +options.uid
      );

      // Javascript
      // Enable log upload
      AgoraRTC.enableLogUpload();
      // Set the log output level as INFO
      AgoraRTC.setLogLevel(1);

      rtc.client.on("user-published", async (user, mediaType) => {
        if (user.hasVideo) {
          setVideoOptions((prev) => ({ ...prev, cam: true }));
        }
        if (user.hasAudio) {
          setVideoOptions((prev) => ({ ...prev, mic: true }));
        }

        // Subscribe to a remote user.
        await rtc.client.subscribe(user, mediaType);

        // If the subscribed track is video.
        if (mediaType === "video") {
          // Get `RemoteVideoTrack` in the `user` object.
          const remoteVideoTrack = user.videoTrack;
          // Dynamically create a container in the form of a DIV element for playing the remote video track.
          // const remotePlayerContainer =
          //   document.getElementById("remote_video_");
          // Specify the ID of the DIV container. You can use the `uid` of the remote user.

          // setVideoOptions((prev) => ({ ...prev, cam: false }));
          const remotePlayerContainer = document.createElement("div");
          // Specify the ID of the DIV container. You can use the `uid` of the remote user.
          remotePlayerContainer.id = user.uid.toString();
          remotePlayerContainer.classList.add("local_stream");

          remotePlayerContainer.style.width = "78%";
          remotePlayerContainer.style.height = "640px";
          document.body.append(remotePlayerContainer);
          //local_stream
          // Play the remote video track.
          // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.

          remoteVideoTrack?.play(remotePlayerContainer);

          // Or just pass the ID of the DIV container.
          // remoteVideoTrack.play(playerContainer.id);
        }
        if (mediaType === "audio") {
          // Get `RemoteAudioTrack` in the `user` object.
          const remoteAudioTrack = user.audioTrack;
          // Play the audio track. No need to pass any DOM element.
          const btn = document.createElement("button");
          btn.classList.add("btn_reload_audio");
          const bottomMenuUl = document.getElementById("bottom_menu_wrap");

          AgoraRTC.onAudioAutoplayFailed = () => {
            if (isAudioAutoplayFailed) return;

            isAudioAutoplayFailed = true;

            btn.innerText = "unmute";
            btn.onClick = () => {
              isAudioAutoplayFailed = false;
              btn.remove();
            };

            bottomMenuUl.append(btn);
          };
          remoteAudioTrack.play();
        }
      });

      rtc.client.on("user-unpublished", (user) => {
        if (!user.hasVideo) {
          setVideoOptions((prev) => ({ ...prev, cam: false }));
        } else {
          setVideoOptions((prev) => ({ ...prev, cam: true }));
        }
        if (!user.hasAudio) {
          setVideoOptions((prev) => ({ ...prev, mic: false }));
        } else {
          setVideoOptions((prev) => ({ ...prev, mic: true }));
        }

        // Get the dynamically created DIV container.
        // const remotePlayerContainer = document.getElementById(user.uid);
        // // Destroy the container.
        // remotePlayerContainer.remove();
      });

      rtc.client.on("user-left", (user) => {
        const playerContainer = document.getElementById(user.uid);
        playerContainer?.remove();

        setStreamStatus({
          isEnded: true,
          isStarted: false,
        });
        // Get the dynamically created DIV container.
        // const remotePlayerContainer = document.getElementById(user.uid);
        // // Destroy the container.
        // remotePlayerContainer.remove();
      });
      rtc.client.on("connection-state-change", (currState, prevState) => {});
    }
  };

  useEffect(() => {
    if (!prevTokenValidFor) {
      joinStreamHandler();
    } else {
      let tokenTemp = tokenValidFor < 10 ? 0 : tokenValidFor - 8;
      setTimeout(() => {
        renewTokentreamHandler();
      }, tokenTemp * 1000);
    }
  }, [tokenValidFor]);

  const renewTokentreamHandler = async () => {
    //  joinStreamHandler();
    const res = await joinStream(streamId);
    setTokenValidFor(res.data.tokenValidFor);
    prevTokenValidFor = res.data.tokenValidFor;
    if (rtc.client) {
      await rtc.client.renewToken(res.data.token);
    }
  };

  const videoOffHandler = async () => {
    if (rtc?.localVideoTrack?.enabled) {
      // Temporarily turn off the camera captrue
      await rtc?.localVideoTrack?.setEnabled(false);

      setVideoOptions((prev) => ({ ...prev, cam: false }));
    } else {
      setVideoOptions((prev) => ({ ...prev, cam: true }));
      await rtc?.localVideoTrack?.setEnabled(true);
    }
  };

  const audioMuteHandler = async () => {
    if (rtc?.localAudioTrack?.enabled) {
      await rtc?.localAudioTrack?.setEnabled(false);

      setVideoOptions((prev) => ({ ...prev, mic: false }));
    } else {
      await rtc?.localAudioTrack?.setEnabled(true);

      setVideoOptions((prev) => ({ ...prev, mic: true }));
    }
  };

  const leaveStreamHandler = async () => {
    setEndClick(true);
    // Close all the local tracks.
    rtc?.localAudioTrack?.close();
    rtc?.localVideoTrack?.close();

    // // Traverse all remote users.
    // rtc.client.remoteUsers.forEach((user) => {
    //   // Destroy the dynamically created DIV containers.
    //   // Get the dynamically created DIV container.
    //   // Destroy the dynamically created DIV containers.
    //   const playerContainer = document.getElementById(user.uid);
    //   playerContainer && playerContainer.remove();
    // });

    // Leave the channel.
    await rtc?.client?.leave();

    if (role === "host") {
      endStreamHandler();
    } else {
      updateStreamRefreshHandler(streamId);
      history.push("/streams/all");
    }
  };

  const updateStreamRefreshHandler = async (id) => {
    const res = await updateStreamRefresh(id);
  };

  const StreamStartTimer = ({ expiryTimestamp }) => {
    const { seconds, minutes, hours, days } = useTimer({
      expiryTimestamp,
      onExpire: () => {
        setTimeout(() => {
          joinStreamHandler();
        }, 1000);
      },
    });
    return (
      <div className="stream_timer">
        <span>
          {days} <p>days</p>
        </span>
        <span>
          {hours} <p>hours</p>
        </span>
        <span>
          {minutes}
          <p>minutes</p>
        </span>
        <span>
          {seconds} <p>seconds</p>
        </span>
      </div>
    );
  };

  const goToProductDetailHandler = (id) => {
    window.open(`/product/detail/${id}`, "_blank");
  };

  useEffect(() => {
    const getStreamRefreshHandler = async () => {
      const res = await getStreamRefresh(streamId);

      if (res?.status) {
        console.log(res, "refreshStreamData");
        setStreamStatus({
          isEnded: res.data[0].streamEnded,
          isStarted: res.data[0].streamStarted,
        });
      }
    };
    const interval = setInterval(() => getStreamRefreshHandler(), 2000);
    return () => {
      clearInterval(interval);
    };
  }, [streamId]);

  return (
    <>
      <div className="chat_wrapper">
        {streamStatus.isStarted && !streamStatus.isEnded ? (
          <>
            <div
              className={`stream_row ${isCommentVisible ? "hide_comment" : ""}`}
            >
              <div className={`stream_chat `}>
                <div className="top_sec">
                  <div className="view_live">
                    <ul>
                      <li>
                        <div>
                          <span>
                            LIVE <Timer streamData={streamData} />
                          </span>
                        </div>
                      </li>
                      <li>
                        <span onClick={handleOpenAttendiModal}>
                          <i className="icon view"></i>{" "}
                          {attendedStreamList?.length}
                        </span>
                      </li>

                      <li>
                        {role === "audience" && !videoOptions.mic && (
                          <i
                            className={`icon ${
                              videoOptions.mic ? "mic" : "mic-off"
                            }`}
                          ></i>
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="comments_like" onClick={showCommentHandler} style={{marginLeft:"-32px"}}>
                    {/* <span>0 Likes</span> */}
                    <span >{commentList.length} Comments</span>
                  </div>
                </div>
                <div className="video_wrap">
                  {!videoOptions.cam && (
                    <>
                      <div className="no_video_caption user">
                        {streamerData?.profilePic ? (
                          <img src={streamerData?.profilePic} alt="" />
                        ) : (
                          <span className="dp_name">
                            {streamerData?.name?.charAt(0)}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                  {role === "host" ? (
                    <>
                      {/* <div
                        id="local_stream"
                        className="local_stream"
                        style={{ width: "100%", height: "650px" }}
                      /> */}
                      {/* {!rtc?.localVideoTrack?.isPlaying && (
                      <h5 className="no_video_caption">{userDetails.name}</h5>
                    )} */}

                      {/* {videoOptions.video ? (
                        <div
                          id='local_stream'
                          className='local_stream'
                          style={{ width: '100%', height: '650px' }}
                        />
                      ) : (
                       
                      )} */}
                    </>
                  ) : (
                    <>
                      {/* <div
                        id="remote_video_"
                        className="local_stream"
                        style={{ width: "100%", height: "650px" }}
                      /> */}
                      {/* {videoOptions.video ? (
                        <div
                          id="remote_video_"
                          className="local_stream"
                          style={{ width: "100%", height: "650px" }}
                        />
                      ) : (
                        <h5 className="no_video_caption">{userDetails.name}</h5>
                      )} */}
                    </>
                  )}
                </div>

                <div className="bottom_menu" id="bottom_menu_wrap">
                  <ul>
                    <li>
                      <span onClick={toggleMenuHandler}>
                        <i
                          className={`icon ${openMenu ? "close_menu" : "menu"}`}
                        ></i>
                      </span>
                    </li>
                    {role === "host" && (
                      <>
                        <li>
                          <span onClick={() => videoOffHandler()}>
                            <i
                              className={`icon ${
                                videoOptions.cam ? "web-cam" : "web-cam-off"
                              }`}
                            ></i>
                          </span>
                        </li>

                        <li>
                          <span onClick={() => audioMuteHandler()}>
                            <i
                              className={`icon ${
                                videoOptions.mic ? "mic" : "mic-off"
                              }`}
                            ></i>
                          </span>
                        </li>
                      </>
                    )}

                    {/* 
                    <li>
                      <span>
                        <i className="icon share"></i>
                      </span>
                    </li> */}
                    {sellingProducts?.length > 0 && (
                      <li onClick={openShopHandler}>
                        <span>
                          <i className="icon store"></i>
                        </span>
                      </li>
                    )}
                  </ul>
                  <button
                    onClick={() => leaveStreamHandler()}
                    className="btn btn-primary end_btn"
                  >
                    {role === "audience" ? "Leave" : "End"}
                  </button>
                </div>
              </div>
              <div
                className={`stream_comment ${isCommentVisible ? "d-none" : ""}`}
              >
                <CommentBox
                  closeCommentHandler={closeCommentHandler}
                  streamId={streamId}
                />
              </div>
            </div>
            <div className={`shop_wrapper ${openShop ? "d-flex" : "d-none"}`}>
              <h5 className="title">Product Item List</h5>
              <div className="show_row">
                {sellingProducts?.map((product) => (
                  <Products
                    productData={product}
                    goToProductDetailHandler={goToProductDetailHandler}
                  />
                ))}
              </div>

              <div className="close_show" onClick={closeShopHandler}>
                <span>
                  <i className="icon arrow-right"></i>
                </span>
              </div>
            </div>
            <div className={`menu_wrapper ${openMenu ? "d-flex" : "d-none"}`}>
              <LeftMenu
                isStreamingChat
                handleGameStartOpen={handleGameStartOpen}
                streamId={streamId}
                role={role}
                fromStream
              />
            </div>
            <div
              className={`game_start_wrapper w-100 ${
                openGameStart ? "d-flex" : "d-none"
              }`}
            ></div>
          </>
        ) : (
          <>
            <div className="stream_not_started_wrap">
              <div></div>
              <div className="content">
                {streamStatus.isEnded && <h5>Stream ended</h5>}
                {!streamStatus.isStarted && !streamStatus.isEnded && (
                  <h5>This Stream will start in</h5>
                )}

                {!streamStatus.isStarted && !streamStatus.isEnded && (
                  <>
                    <>
                      {waitingStreamData?.created_at && (
                        <>
                          <StreamStartTimer
                            expiryTimestamp={
                              new Date(waitingStreamData?.startTime)
                            }
                            onStartStream={startBasicLiveStreaming}
                          />
                        </>
                      )}
                    </>

                    <>
                      <h3 className="mt-5">Stream not started yet</h3>
                    </>
                  </>
                )}
              </div>

              <div className="user">
                {waitingStreamData?.user?.profilePic ||
                streamerData.profilePic ? (
                  <img src={waitingStreamData?.user?.profilePic} alt="" />
                ) : (
                  <span className="dp_name">
                    {waitingStreamData?.user?.name?.charAt(0) ||
                      streamerData.name?.charAt(0)}
                  </span>
                )}
                <p>{waitingStreamData?.user?.name}</p>
                <h5>{waitingStreamData?.title}</h5>
                <p className="cost">
                  {waitingStreamData?.price === 0 ? (
                    "Free"
                  ) : (
                    <>Cost: {waitingStreamData?.price} Per/Minute</>
                  )}
                </p>
                <div className="back_home">
                  <HomeIcon onClick={() => history.push("/streams/all")} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <CustomModal
        show={showAttendiModal}
        handleClose={handleCloseAttendiModal}
        title="Attendee List"
      >
        <AttendedStreamList attendedStreamList={attendedStreamList} />
      </CustomModal>
    </>
  );
};

import React, { useEffect, useState } from "react";
import moment from "moment";
import { getAllNotifications, deleteNotification } from "./NotificationService";
import { useNotifications } from "../../context/NotificationProvider";
import {Button} from '@material-ui/core'
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notifications = (props) => {
  const { allNotifications, setAllNotifications } = useNotifications();
  const [thisWeekList, setThisWeekList] = useState([]);
  
  const getAllNotificationsHandler = async () => {
    try {
      const res = await getAllNotifications();
      
      if (res && res.status) {
        let today = moment();
        let lastWeek = moment(today).subtract(7, 'd');
        
        
        const weekTemp = res.data.filter((data) =>
          moment(data.created_at).isBetween(lastWeek, today, null, '[]')
        );
        console.log(weekTemp);

        setThisWeekList(weekTemp);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  
  const deleteNotificationHandler = async (notificationId) => {
    try {
      const res = await deleteNotification(notificationId);
  
      if (res && res.status) {
        setThisWeekList((prevWeekList) =>
        prevWeekList.filter((notif) => notif._id !== notificationId)
        );
        
      
        getAllNotifications((prevNotifications) =>
          prevNotifications.filter((notif) => notif._id !== notificationId)
          )
          toast.success("Notification deleted successfully!",
          {
            position:toast.POSITION.BOTTOM_CENTER,
            style: {
              backgroundColor: 'yellow', 
              color: 'black'
            }
          });
      } else {
        toast.error("Failed to delete notification!");
        console.error("Failed to delete notification");
      }
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };
  
  console.log("Component rendered");
  
  useEffect(() => {
    getAllNotificationsHandler();
  
  }, []);

  return (
    <div className="notification_wrapper">
      {thisWeekList.length > 0 && <h5>This Week</h5>}
      <div className="noti_card">
        {thisWeekList.length === 0 && (
          <div className="no_notification">
            <h5>You have no notifications from the last week!</h5>
            <p>Start posting and creating streams to reach out to the audience.</p>
          </div>
        )}
        <ul>
          {thisWeekList.map((n) => (
            <li key={n._id}>
              <div className="media">
                {n?.data?.profilePic ? (
                  <img
                    src={n?.data?.profilePic}
                    style={{ cursor: "pointer" }}
                    alt=""
                    onClick={() => (window.location.href = `/profile/${n?.data?.userId}`)}
                  />
                ) : (
                  <span
                    className="dp_name"
                    style={{ cursor: "pointer" }}
                    onClick={() => (window.location.href = `/profile/${n?.data?.userId}`)}
                  >
                    {n?.data?.name?.charAt(0)}
                  </span>
                )}
                <div className="details">
                  <p>
                    <div
                      className="noti_message"
                      dangerouslySetInnerHTML={{ __html: n.message }}
                    />
                    <span>{moment(n.created_at).startOf("seconds").fromNow()}</span>
                  </p>
                </div>
              <div className="post_detail noti_delete_btn">
                <Button onClick={() => deleteNotificationHandler(n._id)}
                 variant="outlined"
                 size="small"
                 color="primary"
                 
                >Delete</Button>
              </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar /> */}
    </div>
  );
};

export default Notifications;

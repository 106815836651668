import React, { createContext, useContext, useEffect, useState } from "react";

const OfflineContext = createContext();

export function useOffline() {
  return useContext(OfflineContext);
}

export default function OfflineProvider({ children }) {
  const [isOffline, setIsOffline] = useState(false);

  const setOfflineHandler = (flag) => {
    setIsOffline(flag);
  };

  useEffect(() => {}, []);

  const values = {
    isOffline,
    setOfflineHandler,
  };
  return (
    <OfflineContext.Provider value={values}>{children}</OfflineContext.Provider>
  );
}

import React, { useEffect } from "react";
import { useProfilePic } from "../../context/UserProfilePicProvider";

/**
 * @author
 * @function Messages
 **/

const Messages = (props) => {
  const { profilePic, findUserProfilePicHandler, userName, senderId } =
    useProfilePic();
  const { message, own, currentChat } = props;

  useEffect(() => {
    findUserProfilePicHandler(senderId);
  }, [senderId]);

  return (
    <>
      <div className={`message ${own ? "fromme" : ""}`}>
        <div className={`user-head ${own ? "d-none" : ""} `}>
          {/* {own ? ( */}
            {/* <><img src={profilePic} alt="" /></> */}
          {/* // ) : profilePic ? (
          //   <img src={profilePic} alt="" />
          // ) : (
          //   <span class="dp_name">{userName?.charAt(0)}</span>
          // )} */}
          {currentChat?.profilePic ? (
                    <img src={currentChat?.profilePic} alt="" />
                  ) : (
                    <span class="dp_name">
                      {currentChat?.username?.charAt(0)}
                    </span>
                  )}
        </div>
        <div className="content">{message?.message}</div>
      </div>
    </>
  );
};

export default Messages;

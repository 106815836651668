import React, { useCallback, useEffect, useState } from "react";
import DP from "../../assets/img/followers.png";
import SimpleSnackbar from "../../components/UI/SnakBar";
import { useUser } from "../../context/UserProvider";
import LocalStorageService from "../../utils/LocalStorageService";
import { followUser, getFollowing, unFollowUser } from "./ProfileService";

/**
 * @author
 * @function Followers
 **/

const Following = (props) => {
  const { onRemoveFollowerHandler, userId } = props;
  const userDetails = LocalStorageService.getItem("userDetail");
  const { getUserOfData } = useUser();

  const [followingList, setFollowingList] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const getFollowingList = useCallback(async () => {
    const res = await getFollowing(localStorage.getItem("userId"));

    if (res && res.status) {
      setFollowingList(res.data.following);
    } else {
      setFollowingList([]);
    }
  }, []);

  const followUserHandler = async (id) => {
    const res = await followUser(id);

    if (res && res.status) {
      setOpen(true);
      setMessage("You have started following");
      getFollowingList();
    } else {
      setOpen(true);
      setMessage("Something went wrong");
    }
  };
  const unFollowUserHandler = async (id) => {
    const res = await unFollowUser(id);

    if (res && res.status) {
      setOpen(true);
      setMessage("You have unfollowed");
      getFollowingList();
      getUserOfData(userId);
    } else {
      setOpen(true);
      setMessage("Something went wrong");
    }
  };

  useEffect(() => {
    getFollowingList();
  }, [getFollowingList]);
  return (
    <>
      <p className="followers_text">{followingList.length} Following</p>
      <div className="followers_wrap">
        <div className="body">
          <ul>
            {followingList.map((item) => {
              return (
                <li key={item._id}>
                  <div className="user_wrap">
                    {item.profilePic ? (
                      <img src={item.profilePic} alt="" />
                    ) : (
                      <span className="dp_name">{item.name.charAt(0)}</span>
                    )}
                    <div className="content">
                      <h6
                        onClick={() =>
                          (window.location.href = `/profile/${item._id}`)
                        }
                      >
                        @{item?.username ? item?.username : "username"}
                      </h6>
                      <p>{item?.location}</p>
                    </div>
                  </div>
                  <div className="button_wrap">
                    {/* <Button variant='contained' size='small' color='primary'>
                      Unfollow
                    </Button> */}
                    <span
                      className="remove"
                      onClick={() => unFollowUserHandler(item._id)}
                    >
                      <i className="icon remove"></i>
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default Following;

import React, { useEffect, useState } from "react";

import StreamCard from "./StreamCard";
import {
  cancelStream,
  deleteStream,
  fetchUserStream,
  getAllStream,
} from "./StreamingService";
import { useLastLocation } from "react-router-last-location";
import { useHistory } from "react-router-dom";
import moment from "moment";
import EightPlusImg from "../../assets/img/icons/18plus.png";
import EditIcon from "@material-ui/icons/Edit";

import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import LocalStorageService from "../../utils/LocalStorageService";
import SwitchButton from "../../components/UI/SwitchButton";
import CustomModal from "../../components/UI/Modal";
import StreamAccount from "../../containers/Profile/StreamAccount";

import { Button, IconButton } from "@material-ui/core";
import { useUser } from "../../context/UserProvider";
import { LowBalanceModal } from "../Shared/LowBalanceModal";
import SimpleSnackbar from "../../components/UI/SnakBar";

/**
 * @author
 * @function Streaming
 **/

const Streaming = (props) => {
  const { userData } = useUser();
  let history = useHistory();
  const userDetails = LocalStorageService.getItem("userDetail");
  const lastLocation = useLastLocation();
  const [streamList, setStreamList] = useState();
  const [currentStreamList, setCurrentStreamList] = useState([]);
  const [scheduleStreams, setScheduleStreams] = useState([]);
  const [pastStreams, setPastStreams] = useState([]);
  const [myStreamsOpen, setMyStreamsOpen] = useState(false);
  const [showStreamModal, setShowStreamModal] = useState(false);
  const [showStreamBalanceAddModal, setShowStreamBalanceAddModal] =
    useState(false);
  const [editData, setEditData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseStreamModal = () => setShowStreamModal(false);
  const handleShowStreamModal = () => setShowStreamModal(true);
  const handleCloseStreamBalanceAddModal = () =>
    setShowStreamBalanceAddModal(false);
  const handleShowStreamBalanceAddModal = () =>
    setShowStreamBalanceAddModal(true);

    const getAllStreamHandler = async () => {
      const res = await getAllStream();
  
      if (res && res.status) {
        const allStreams = res.data;
  
      
        const lastWeekStreams = allStreams.filter((stream) => {
          const streamStartTime = moment(stream.startTime);
          const oneWeekAgo = moment().subtract(1, 'weeks');
          return streamStartTime.isAfter(oneWeekAgo);
        });
  
        setStreamList(lastWeekStreams);
  
        filterDataByTime(lastWeekStreams);
      } else {
        setStreamList([]);
      }
    };

  const filterDataByTime = (data) => {
    let currentTemp = [];

    for (const stream of data) {
      if (stream.streamStarted && !stream.streamEnded) {
        currentTemp.push(stream);
      } else if (stream.streamEnded) {
        setPastStreams((prevState) => [...prevState, stream]);
      }
    }

    setCurrentStreamList(currentTemp);

    const pastTemp = data.filter((stream) => stream.streamEnded);
    const scheduleTemp = data.filter(
      (stream) => !stream.streamEnded && !stream.streamStarted
    );

    setScheduleStreams(scheduleTemp);
    setPastStreams(pastTemp);
  };

  const myStreamChangehandler = (e) => {
    const { checked } = e.target;

    if (checked) {
      const tempData = [...streamList];
      const foundedData = tempData.filter((p) => p.userId === userDetails._id);

      filterDataByTime(foundedData);
    } else {
      getAllStreamHandler();
    }
    setMyStreamsOpen(checked);
  };

  useEffect(() => {
    getAllStreamHandler();
  }, []);

  const cancelStreamHandler = async (id) => {
    const res = await cancelStream(id);
    getAllStreamHandler();
  };

  const editStreamHandler = (data) => {
    handleShowStreamModal();
    setEditData(data);
    setIsEditMode(true);
  };

  const deleteStreamHandler = async (id) => {
    const res = await deleteStream(id);

    if (res && res.status) {
      setOpen(true);
      setMessage(res.message);
      getAllStreamHandler();
    } else {
      setOpen(true);
      setMessage(res?.response?.data?.message);
    }
  };

  // useEffect(() => {
  //   if (lastLocation?.pathname?.includes("profile")) {
  //     setMyStreamsOpen(true);
  //
  //     if (streamList) {
  //       const tempData = [...streamList];
  //       const foundedData = tempData.filter(
  //         (p) => p.userId === userDetails._id
  //       );

  //

  //       filterDataByTime(foundedData);
  //     }
  //   } else {
  //     setMyStreamsOpen(false);
  //   }
  // }, [lastLocation?.pathname, streamList, userDetails._id]);

  const joinStreamHandler = (stream) => {
    if (stream.price === 0) {
      history.push(
        `/streaming/${stream._id}/${
          userDetails._id === stream?.userId ? "host" : "audience"
        }`
      );
    } else if (
      stream.price > userData.balance &&
      (userData?.userType !== "admin" || userData?.userType !== "superAdmin")
    ) {
      handleShowStreamBalanceAddModal();
    } else {
      history.push(
        `/streaming/${stream._id}/${
          userDetails._id === stream?.userId ? "host" : "audience"
        }`
      );
    }
  };

  return (
    <>
      <div className="stream_wrapper">
        <div className="head">
          <div>
            {streamList?.length > 0 && (
              <SwitchButton
                isChecked={myStreamsOpen}
                change={myStreamChangehandler}
                label="Show my streams"
                className="show-stream"
                style={{fontSize:"10px"}}
              />
            )}
          </div>

          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={handleShowStreamModal}
            className="btn-create-strm"
          >
            Create Stream
          </Button>
        </div>

        {streamList?.length > 0 ? (
          <>
            {currentStreamList.length > 0 && (
              <div className="live_wrap streams_wrap pt-0">
                <h5 className="title">Current live stream</h5>

                <>
                  <ul>
                    {currentStreamList.map((stream) => {
                      return (
                        <li>
                          <div className="stream_card">
                            <div className="stream_media">
                              {stream?.user?.profilePic ? (
                                <img src={stream?.user?.profilePic} alt="" />
                              ) : (
                                <span className="dp_name">
                                  {stream?.user?.name.charAt(0)}
                                </span>
                              )}

                              <div className="stream_details">
                                <h5>{stream?.title}</h5>
                                <p
                                  onClick={() =>
                                    (window.location.href = `/profile/${stream.userId}`)
                                  }
                                >
                                  @
                                  {stream.user.username
                                    ? stream.user.username
                                    : "username"}
                                </p>
                              </div>
                            </div>
                            <div className="stream_options">
                              <div className="stream_cost">
                                {stream?.price === 0 ? (
                                  "Free"
                                ) : (
                                  <>
                                    <p>
                                      <i className="icon revit_coin"></i>{" "}
                                      {stream?.price} /minute
                                    </p>
                                  </>
                                )}
                              </div>
                              <div className="stream_action">
                                {stream.viewerProfile === "TIER_1" && (
                                  <span className="adult_only">
                                    <img src={EightPlusImg} alet="" />
                                  </span>
                                )}

                                <a
                                  href="#!"
                                  onClick={() => joinStreamHandler(stream)}
                                >
                                  <p>Join</p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </>
              </div>
            )}
            {scheduleStreams.length > 0 && (
              <div className="schedule_wrap streams_wrap">
                <h5 className="title">Scheduled live stream</h5>
                <ul>
                  {scheduleStreams.map((stream) => {
                    return (
                      <li>
                        <div className="stream_card">
                          <div className="stream_media">
                            {stream?.user?.profilePic ? (
                              <img src={stream?.user?.profilePic} alt="" />
                            ) : (
                              <span className="dp_name">
                                {stream?.user?.name.charAt(0)}
                              </span>
                            )}

                            <div className="stream_details">
                              <h5>{stream?.title}</h5>
                              <p
                                onClick={() =>
                                  (window.location.href = `/profile/${stream.userId}`)
                                }
                              >
                                @
                                {stream.user.username
                                  ? stream.user.username
                                  : "username"}
                              </p>
                            </div>
                          </div>
                          <div className="stream_options">
                            <div className="stream_cost">
                              {stream?.price === 0 ? (
                                "Free"
                              ) : (
                                <>
                                  <p>
                                    <i className="icon revit_coin"></i>{" "}
                                    {stream?.price} /minute
                                  </p>
                                </>
                              )}
                            </div>
                            <div className="stream_action">
                              {stream.viewerProfile === "TIER_1" && (
                                <span className="adult_only">
                                  <img src={EightPlusImg} alet="" />
                                </span>
                              )}
                              <div className="link_a_stream">
                                <Link
                                  to={`/streaming/${stream._id}/${
                                    userDetails._id === stream?.userId
                                      ? "host"
                                      : "audience"
                                  }`}
                                  className="link_a_stream"
                                >
                                  <p>
                                    {moment
                                      .utc(stream?.startTime)
                                      .local()
                                      .format("MMMM Do YYYY, h:mm:ss a")}
                                  </p>
                                </Link>
                                {userDetails._id === stream?.userId && (
                                  <>
                                    <div className="edit_cancel_btn">
                                      {/* <span
                                        className="cancel_stream ml-3"
                                        onClick={() =>
                                          cancelStreamHandler(stream._id)
                                        }
                                      >
                                        Cancel
                                      </span> */}

                                      {/* <span
                                        className="cancel_stream ml-2 edit_stream_btn"
                                        onClick={() =>
                                          editStreamHandler(stream)
                                        }
                                      >
                                        Edit 
                                      </span> DeleteIcon */}
                                      <IconButton
                                        className="edit_stream_btn"
                                        aria-label="delete"
                                        onClick={() =>
                                          editStreamHandler(stream)
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton
                                        className="edit_stream_btn delete_icon"
                                        aria-label="delete"
                                        onClick={() =>
                                          deleteStreamHandler(stream._id)
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {/* {pastStreams.length > 0 && (
              <div className="past_wrap streams_wrap">
                <h5 className="title">Completed stream</h5>
                <ul>
                  {pastStreams?.map((stream) => {
                    return (
                      <li>
                        <div className="stream_card">
                          <div className="stream_media">
                            {stream?.user?.profilePic ? (
                              <img src={stream?.user?.profilePic} alt="" />
                            ) : (
                              <span className="dp_name">
                                {stream?.user?.name.charAt(0)}
                              </span>
                            )}
                            <div className="stream_details">
                              <h5>{stream?.title}</h5>
                              <p
                                onClick={() =>
                                  (window.location.href = `/profile/${stream?.userId}`)
                                }
                              >
                                @
                                {stream?.user?.username
                                  ? stream?.user?.username
                                  : "username"}
                              </p>
                            </div>
                          </div>
                          <div className="stream_options">
                            <div className="stream_cost">
                              {stream?.price === 0 ? (
                                "Free"
                              ) : (
                                <>
                                  <p>
                                    <i className="icon revit_coin"></i>{" "}
                                    {stream?.price} /minute
                                  </p>
                                </>
                              )}
                            </div>
                            <div className="stream_action">
                              {stream.viewerProfile === "TIER_1" && (
                                <span className="adult_only">
                                  <img src={EightPlusImg} alet="" />
                                </span>
                              )}
                              <p>
                                {moment(stream.streamEndedAt)
                                  .startOf("sec")
                                  .fromNow()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )} */}
          </>
        ) : (
          <h5 className="text-center pt-4">No Stream Found</h5>
        )}
      </div>
      <CustomModal
        show={showStreamModal}
        handleClose={handleCloseStreamModal}
        title="Stream Account"
        size="md"
      >
        <StreamAccount
          handleCloseModal={handleCloseStreamModal}
          fromStreamList
          editData={editData}
          getAllStreamHandler={getAllStreamHandler}
          isEditMode={isEditMode}
        />
      </CustomModal>

      <LowBalanceModal
        show={showStreamBalanceAddModal}
        close={handleCloseStreamBalanceAddModal}
      />
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default Streaming;

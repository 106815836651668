import React, { createContext, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import {
  getCommentStream,
  postCommentStream,
} from "../containers/ChatStreaming/StreamingService";

const StreamCommentContext = createContext();

export function useStreamComment() {
  return useContext(StreamCommentContext);
}

export default function StreamCommentProvider({ children }) {
  const [commentList, setCommentList] = useState([]);
  const scrollRef = useRef();

  const postCommentHandler = async (chat, streamId) => {
    setCommentList((prevState) => [...prevState, chat]);
  };
  const getCommentHandler = async (streamId) => {
    const res = await getCommentStream(streamId);

    if (res && res.status) {
      setCommentList(res.data);
    }
  };

  const values = {
    postCommentHandler,
    getCommentHandler,
    commentList,
    scrollRef,
  };
  return (
    <StreamCommentContext.Provider value={values}>
      {children}
    </StreamCommentContext.Provider>
  );
}

import React from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { InputGroup, FormControl } from "react-bootstrap";

/**
 * @author
 * @function Conversation
 **/

const Conversation = (props) => {
  const { conversation } = props;
  return (
    <>
      <li className={conversation.isActive ? "active" : ""}>
        <div class="image_wrap">
          {conversation?.profilePic ? (
            <img src={conversation?.profilePic} alt="" />
          ) : (
            <span class="dp_name">{conversation?.username?.charAt(0)}</span>
          )}
        </div>
        <div class="content">
          {conversation?.unreadMessage && (
            <span className="notification_count">
              {conversation?.messageCount}
            </span>
          )}
          <h6>@{conversation?.username}</h6>
        </div>
      </li>
    </>
  );
};

export default Conversation;

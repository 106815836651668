import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Loader = ({ lines, width, height }) => {
  return (
    <>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div style={{ width: width, height: height }}>
          <Skeleton width="100%" height={height || 30} count={lines} />
        </div>
      </SkeletonTheme>
    </>
  );
};

export default Loader;

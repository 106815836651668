import React from "react";
import { Form } from "react-bootstrap";

/**
 * @author
 * @function FormInput
 **/

const FormInput = (props) => {
  const {
    onChange,
    type,
    placeholder,
    name,
    value,
    label,
    onKeyDown,
    isInvalid,
    required,
    ...rest
  } = props;
  return (
    <>
      <Form.Group>
        <Form.Label>
          {label} {required && <span className="text-danger">*</span>}
        </Form.Label>
        <Form.Control
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={value}
          {...rest}
        />
      </Form.Group>
    </>
  );
};

export default FormInput;

import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import AuthRoutes from "../routes/AuthRoutes";
import UnAuthRoutes from "../routes/UnAuthRoutes";
import SignIn from "../containers/Auth/Signin";
import ProfileLayout from "../components/Layout/ProfileLayout";
import SignupScreen from "../containers/Auth/Signup";
import ResetPasswordScreen from "../containers/Auth/ResetPassword";
import CreateNewPasswordScreen from "../containers/Auth/CreateNewPassword";
import StreamLayout from "../components/Layout/StreamLayout";
import FeedLayout from "../components/Layout/FeedLayout";
import FullLayout from "../components/Layout/FullLayout";
import PublicLayout from "../components/Layout/PublicLayout";
import PageNotFound from "../components/shared/PageNotFound";
import DashboardLayout from "../components/Layout/DashboardLayout";

const Routes = () => {
  return (
    <LastLocationProvider>
      <Switch>
        <AuthRoutes path={"/feed"} component={FeedLayout} />
        <AuthRoutes path={"/videos"} component={FeedLayout} />

        <AuthRoutes path={"/search"} component={FeedLayout} />
        <AuthRoutes path={"/streams"} component={FeedLayout} />
        <AuthRoutes path={"/profile"} component={ProfileLayout} />
        <AuthRoutes path={"/change-password"} component={FeedLayout} />
        <AuthRoutes path={"/address"} component={FeedLayout} />
        <AuthRoutes path={"/kycupdate"} component={FeedLayout} />
        <AuthRoutes path={"/my-product"} component={FeedLayout} />
        <AuthRoutes path={"/notifications"} component={FeedLayout} />

        <AuthRoutes path={"/product"} component={FullLayout} />
        <AuthRoutes path={"/messages"} component={FullLayout} />

        {/* {localStorage.getItem("token") && (
        <>
          {" "}
          <AuthRoutes path={"/terms"} component={FullLayout} />
          <AuthRoutes path={"/privacy"} component={FullLayout} />
        </>
      )} */}

        <AuthRoutes path={"/streaming"} component={StreamLayout} />
        <AuthRoutes path={"/game"} component={FeedLayout} />
        <AuthRoutes path={"/ecommerce"} component={DashboardLayout} />
        <AuthRoutes path={"/stream"} component={DashboardLayout} />
        <AuthRoutes path={"/my-orders"} component={FeedLayout} />
        <AuthRoutes path={"/settlement-details"} component={FeedLayout} />
        <UnAuthRoutes path={"/"} component={PublicLayout} />

        <Redirect to="/login" />
      </Switch>
    </LastLocationProvider>
  );
};

export default Routes;

import React, { Component } from 'react'

const AppContext = React.createContext()

class AppProvider extends Component {
  // Context state
  state = {
    test: 'this is just for testing',
    userData: {},
  }

  setUserDataHandler = (data) => {
    this.setState({ userData: data })
  }

  render() {
    const { children } = this.props
    const { state, setUserDataHandler } = this

    return (
      <AppContext.Provider
        value={{
          ...state,
          setUserDataHandler,
        }}
      >
        {children}
      </AppContext.Provider>
    )
  }
}

export default AppContext

export { AppProvider }

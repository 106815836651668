import React, { useEffect, useState, useCallback } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useTimer } from "react-timer-hook";
import ImageGallery from "react-image-gallery";
import StarIcon from "@material-ui/icons/Star";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import { Button } from "@material-ui/core";
import ProductImage from "../../assets/img/product.png";

import { useHistory } from "react-router";
import ProductFooter from "./ProductFooter";
import { editBid, getProductDetail, placeBidProduct } from "./ProductService";
import { useParams } from "react-router-dom";
import CustomModal from "../../components/UI/Modal";
import moment from "moment";
import SimpleSnackbar from "../../components/UI/SnakBar";
import useQuery from "../../hooks/useQueryHook";
import { useUser } from "../../context/UserProvider";

/**
 * @author
 * @function ProductDetails
 **/

const ProductDetails = (props) => {
  let history = useHistory();
  const { id } = useParams();
  const query = useQuery();
  const { userData } = useUser();
  const [productData, setProductData] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [isBidOpen, setIsBidOpen] = useState(false);
  const [bidAmount, setBidAmount] = useState("");
  const [isAuctionExpired, setIsAuctionExpired] = useState(false);
  const [showBidCountModal, setShowBidCountModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseBidCountModal = () => setShowBidCountModal(false);
  const handleOpenBidCountModal = () => setShowBidCountModal(true);

  const buyHandler = () => {
    history.push(`/product/detail/address/${productData._id}`);
  };

  const getProductDetailHandler = useCallback(async () => {
    const res = await getProductDetail(id);
    if (res && res.status) {
      setProductData(res.data);
      let tempImages = [];
      for (const data of res.data?.mediaLinks) {
        tempImages.push({
          original: data?.location,
          thumbnail: data?.location,
        });
      }
      setProductImages(tempImages);
    }
  }, [id]);

  useEffect(() => {
    getProductDetailHandler();
  }, [getProductDetailHandler]);

  const placeBidHandler = () => {
    if (bidAmount) {
      // placeBidProductHandler();

      if (bidAmount <= productData.amount) {
        setOpen(true);
        setMessage("Bid amount should be higher than current amount");
      } else {
        history.push(
          `/product/detail/address/${productData._id}?bid=${bidAmount}`
        );
      }
    } else {
      // alert("hello")
      setIsBidOpen(true);
    }
  };

  const placeBidProductHandler = async () => {
    const payload = {
      bidAmount: parseInt(bidAmount),
    };
    const res = await placeBidProduct(id, payload);

    getProductDetailHandler();
    setIsBidOpen(false);
    setBidAmount("");
  };

  const BidTimer = ({ expiryTimestamp }) => {
    const { seconds, minutes, hours, days } = useTimer({
      expiryTimestamp,
      onExpire: () => setIsAuctionExpired(true),
    });
    return (
      <div className="bid_timer_wrap">
        <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:
        <span>{seconds}</span>
      </div>
    );
  };
  const time = new Date(productData.bidExpireyDate);
  time.setSeconds(time.getSeconds()); // 10 minutes timer

  useEffect(() => {
    if (query.get("bidPlaced")) {
      setOpen(true);
      setMessage("Bid successfully placed!");
      setTimeout(() => {
        history.push(`/product/detail/${id}`);
      }, 500);
    }
  }, []);

  const editBidHandler = async () => {
    const payload = {
      bidAmount,
    };
    const res = await editBid(id, payload);

    if (res?.response) {
      setOpen(true);
      setMessage(res?.response?.data?.message);
      return;
    }
    if (res && res.status) {
      setOpen(true);
      setMessage("Bid Edited Successfully!");
      setTimeout(() => {
        history.push(`/ecommerce/dashboard/my-orders`);
      }, 500);
    } else {
      setOpen(true);
      setMessage(res.message);
    }
  };

  const isOwnProduct = productData?.userId === userData?._id;

  return (
    <>
      <div className="product_wrapper border-top">
        <div className="product_head ">
          <Container fluid>
            <Row>
              <Col md={6}>
                <ImageGallery
                  items={productImages}
                  showNav={false}
                  showPlayButton={false}
                  showFullscreenButton={false}
                />
              </Col>
              <Col md={6}>
                <div className="details_right">
                  <h5 className="brand">{productData.brandName}</h5>
                  <h4>{productData.productName}</h4>
                  <h2>
                    <i className="icon revit_coin"></i> {productData.amount}{" "}
                    {productData.productType === "AUCTION" && (
                      <p className="auction_type">
                        <i className="icon-auction auction_ribbon">
                          <span>LATEST BID</span>
                        </i>
                      </p>
                    )}
                    {productData.productType === "AUCTION" && (
                      <>
                        {isAuctionExpired ? (
                          <span className="text-danger">Auction Expired!</span>
                        ) : (
                          <BidTimer expiryTimestamp={time} />
                        )}
                      </>
                    )}
                  </h2>
                  {/* <p className="delivery">Free Delivery</p> */}

                  <ul className="star">
                    <li>
                      <StarIcon className="full" />
                    </li>
                    <li>
                      <StarIcon className="full" />
                    </li>
                    <li>
                      <StarIcon className="full" />
                    </li>
                    <li>
                      <StarOutlineIcon className="outline" />
                    </li>
                    <li>
                      <StarOutlineIcon className="outline" />
                    </li>
                  </ul>
                  {productData.productType !== "AUCTION" ? (
                    <p className="review">
                      <small>4,377 Ratings & 63 Reviews</small>
                    </p>
                  ) : (
                    <p className="review">
                      {productData.bidCount > 0 && (
                        <small
                          style={{ cursor: "pointer" }}
                          onClick={handleOpenBidCountModal}
                        >
                          Bid Count: {productData.bidCount}
                        </small>
                      )}
                    </p>
                  )}

                  <h6>Specifications</h6>

                  <table>
                    <tr>
                      {/* <td>
                        <ul className="dots">
                          <li>In The Box</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Roller Ring</li>
                          <li>Wire Rack</li>
                          <li>Crusty Plate</li>
                          <li>Glass Turn Table</li>
                          <li>User Manual</li>
                          <li>Microwave Oven</li>
                        </ul>
                      </td> */}
                      <td style={{ width: "7%" }}>
                        <ul className="dots">
                          <li>Brand</li>
                          {/* <li>Model Name</li> */}
                          {productData?.productCategory && <li>Type</li>}
                          {/* <li>Capacity</li> */}
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>{productData.brandName}</li>
                          {/* <li>MG23K3515AK</li> */}
                          <li>{productData?.productCategory}</li>
                          {/* <li>23 L</li> */}
                        </ul>
                      </td>
                      <td>
                        {/* <ul className="dots">
                          <li>Control Type</li>
                          <li>Color</li>
                          <li>Shade</li>
                        </ul> */}
                      </td>
                      <td>
                        {/* <ul>
                          <li>Tact (Buttons), Jog Dial</li>
                          <li>Black</li>
                          <li>Black</li>
                        </ul> */}
                      </td>
                    </tr>
                  </table>

                  {isBidOpen && (
                    <Form.Group className="amount_bid_group">
                      <Form.Control
                        type="text"
                        placeholder="Enter bid amount"
                        value={bidAmount}
                        onChange={(e) => setBidAmount(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Group>
                  )}

                  {!isOwnProduct && (
                    <>
                      {" "}
                      {query.get("isModify") ? (
                        <>
                          {bidAmount ? (
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              fullWidth
                              onClick={() => editBidHandler()}
                            >
                              Edit Bid
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              fullWidth
                              onClick={() => setIsBidOpen(true)}
                            >
                              Edit Bid
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          {productData.productType === "AUCTION" ? (
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              fullWidth
                              onClick={placeBidHandler}
                              disabled={isAuctionExpired}
                            >
                              {bidAmount ? "Submit" : "Place Bid"}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              fullWidth
                              onClick={buyHandler}
                            >
                              BUY NOW
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="product_body">
          <h2>Product Description</h2>
          <p>{productData?.description}</p>

          {/* <Row>
            <Col md={10}>
              <h5>Browning Plus Function</h5>
              <p>
                It makes your food not only taste delicious but, look delectable
                as well by giving it the right texture and colour. This function
                increases the cooking time by 30 seconds and utilizes the grill
                heater to make your food more brown and crisp.
              </p>
            </Col>
            <Col md={2}>
              <img src={ProductImage} alt="" />
            </Col>
          </Row> */}
          {/* <Row>
            <Col md={2}>
              <img src={ProductImage} alt="" />
            </Col>
            <Col md={10} className="text-left">
              <h5>Durable CERAMIC INSIDE</h5>
              <p>
                This oven's smooth surface with durable CERAMIC INSIDE is easy
                to clean. It is also rust and scratch-resistant, thereby
                enhancing its durability.
              </p>
            </Col>
          </Row> */}
          {/* <Row>
            <Col md={10}>
              <h5>Quick Defrost Function</h5>
              <p>
                From poultry to frozen vegetables, the Quick Defrost Function's
                defrost algorithm calculates the correct defrosting time for
                five food types. This way, you can defrost food easily without
                waiting for them to thaw for long.
              </p>
            </Col>
            <Col md={2}>
              <img src={ProductImage} alt="" />
            </Col>
          </Row> */}
          {/* <Row>
            <Col md={2}>
              <img src={ProductImage} alt="" />
            </Col>
            <Col md={10} className="text-left">
              <h5>Keep Warm Function</h5>
              <p>
                Have a lot of food to serve? You can keep everything at proper
                temperature with the help of the Keep Warm Function. It uses the
                grill heater to maintain the temperature to keep your food warm,
                without over-cooking it.
              </p>
            </Col>
          </Row> */}
          {/* <Row>
            <Col md={10}>
              <h5>ECO Mode</h5>
              <p>
                The ECO Mode uses minimum power to maintain the essential
                functions, while you are not cooking, so as to reduce energy
                consumption.
              </p>
            </Col>
            <Col md={2}>
              <img src={ProductImage} alt="" />
            </Col>
          </Row> */}
          {/* <Row>
            <Col md={2}>
              <img src={ProductImage} alt="" />
            </Col>
            <Col md={10} className="text-left">
              <h5>Deodorization Setting</h5>
              <p>
                You can keep your food fresh and tasty always. The Deodorization
                Setting forces air out of the interior cavity so that any odour
                prevalent is quickly let out.
              </p>
            </Col>
          </Row> */}
        </div>

        <div className="review_rating_wrap">
          <p className="r_title">Ratings & Reviews</p>
          <ul>
            <li>
              {productData?.totalRating === 0 ? (
                "No Rating!"
              ) : (
                <p>
                  <>
                    {productData?.totalRating > 0
                      ? productData?.totalRating
                      : "2.5"}
                    <ul className="star">
                      {productData?.totalRating == 1 ? (
                        <li>
                          <StarIcon className="full" />
                        </li>
                      ) : productData?.totalRating == 2 ? (
                        <>
                          {" "}
                          <li>
                            <StarIcon className="full" />
                          </li>
                          <li>
                            <StarIcon className="full" />
                          </li>
                        </>
                      ) : productData?.totalRating == 3 ? (
                        <>
                          {" "}
                          <li>
                            <StarIcon className="full" />
                          </li>
                          <li>
                            <StarIcon className="full" />
                          </li>
                          <li>
                            <StarIcon className="full" />
                          </li>
                        </>
                      ) : productData?.totalRating == 4 ? (
                        <>
                          {" "}
                          <li>
                            <StarIcon className="full" />
                          </li>
                          <li>
                            <StarIcon className="full" />
                          </li>
                          <li>
                            <StarIcon className="full" />
                          </li>
                          <li>
                            <StarIcon className="full" />
                          </li>
                        </>
                      ) : productData?.totalRating == 5 ? (
                        <>
                          {" "}
                          <li>
                            <StarIcon className="full" />
                          </li>
                          <li>
                            <StarIcon className="full" />
                          </li>
                          <li>
                            <StarIcon className="full" />
                          </li>
                          <li>
                            <StarIcon className="full" />
                          </li>
                          <li>
                            <StarIcon className="full" />
                          </li>
                        </>
                      ) : (
                        <>
                          {" "}
                          <li>
                            <StarIcon className="full" />
                          </li>{" "}
                          <li>
                            <StarIcon className="full" />
                          </li>
                          <li>
                            <StarOutlineIcon className="outline" />
                          </li>
                        </>
                      )}
                    </ul>
                  </>{" "}
                </p>
              )}

              {/* <small>744 Ratings & 63 Reviews</small> */}
            </li>
            {/* <li>
              <p>
                5 <StarIcon />
              </p>
              <small>446 People</small>
            </li>
            <li>
              <p>
                4 <StarIcon />
              </p>
              <small>176 People</small>
            </li>
            <li>
              <p>
                3 <StarIcon />
              </p>
              <small>45 People</small>
            </li>
            <li>
              <p>
                2 <StarIcon />
              </p>
              <small>24 People</small>
            </li> */}
            {/* <li>
              <p>
                1 <StarIcon />
              </p>
              <small>53 People</small>
            </li> */}
          </ul>
          {/* <Button
            variant="contained"
            color="secondary"
            
            type="submit"
          >
            Rate Product

          </Button> */}
          <p></p>
        </div>
        <div className="all_review_wrap">
          {productData?.commentCount === 0 && <h5>No Review!</h5>}
          {/* <ul>
            <li>
              <div className="rating_number">
                <span>
                  5 <StarIcon />
                </span>
              </div>

              <div className="comment">
                <h5>Deodorization Setting</h5>
                <p>
                  You can keep your food fresh and tasty always. The
                  Deodorization Setting forces air out of the interior cavity so
                  that any odour prevalent is quickly let out.Stylish and classy
                  looks only thing demo given by engineer was of no use he just
                  came out on n warm water for 30seconds
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="user_name">M.KARTHI KEYAN | 29 Sep, 2018</p>
                  <div className="like_dislike d-flex justify-content-between align-items-center">
                    <p>
                      18<i className="icon like_comment"></i>
                    </p>
                    <p>
                      18<i className="icon dislike_comment"></i>
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="rating_number">
                <span>
                  5 <StarIcon />
                </span>
              </div>

              <div className="comment">
                <h5>Deodorization Setting</h5>
                <p>
                  You can keep your food fresh and tasty always. The
                  Deodorization Setting forces air out of the interior cavity so
                  that any odour prevalent is quickly let out.Stylish and classy
                  looks only thing demo given by engineer was of no use he just
                  came out on n warm water for 30seconds
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="user_name">M.KARTHI KEYAN | 29 Sep, 2018</p>
                  <div className="like_dislike d-flex justify-content-between align-items-center">
                    <p>
                      18<i className="icon like_comment"></i>
                    </p>
                    <p>
                      18<i className="icon dislike_comment"></i>
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul> */}
        </div>

        <ProductFooter />
      </div>
      <CustomModal
        show={showBidCountModal}
        handleClose={handleCloseBidCountModal}
        title={`Bid Count (${productData?.bidCount})`}
        size="md"
      >
        <Table striped bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>User</th>
              <th>Amount</th>
              <th>Bid Date</th>
            </tr>
          </thead>
          <tbody>
            {productData?.bids?.map((p, i) => (
              <tr key={p.user._id}>
                <td>{i + 1}</td>
                <td>
                  <a style={{ color: "#000" }} href={`/profile/${p.user._id}`}>
                    @{p.user.username}
                  </a>
                </td>
                <td>{p.amount}</td>
                <td>{moment(p.createdAt).format("DD-MM-YYYY")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CustomModal>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default ProductDetails;

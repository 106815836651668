import axios from "axios";

export const userLogin = async (payLoad) => {
  try {
    const { data } = await axios.post(`auth/login`, payLoad);
    return data;
  } catch (err) {
    return err;
  }
};
export const userSignup = async (payLoad) => {
  try {
    const { data } = await axios.post(`auth/signup`, payLoad);
    return data;
  } catch (err) {
    return err;
  }
};
export const forgotPassword = async (payLoad) => {
  try {
    const { data } = await axios.post(`auth/forgotPassword`, payLoad);
    return data;
  } catch (err) {
    return err;
  }
};
export const resetPassword = async (payLoad) => {
  try {
    const { data } = await axios.post(`auth/resetPassword`, payLoad);
    return data;
  } catch (err) {
    return err;
  }
};
export const sendVerificationMail = async (email) => {
  try {
    const { data } = await axios.post(
      `auth/send-verification-email
    `,
      { email }
    );
    return data;
  } catch (err) {
    return err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userLogin,
  userSignup,
  forgotPassword,
  sendVerificationMail,
  resetPassword,
};

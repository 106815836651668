import React from "react";
import { Route, Switch } from "react-router";
import CreateNewPasswordScreen from "../../containers/Auth/CreateNewPassword";
import ResetPasswordScreen from "../../containers/Auth/ResetPassword";
import SigninScreen from "../../containers/Auth/Signin";
import SignupScreen from "../../containers/Auth/Signup";
import { EmailVerification } from "../../containers/EmailVerification";
import Home from "../../containers/Home";
import Privacy from "../../containers/Privacy";
import Terms from "../../containers/Terms";
import PageNotFound from "../shared/PageNotFound";

const PublicLayout = () => {
  return (
    <>
      <div className="wrapper">
        <div className="content_wrapper">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path={"/login"} component={SigninScreen} />
            <Route path={"/signup"} component={SignupScreen} />
            <Route path={"/reset-password"} component={ResetPasswordScreen} />
            <Route
              path={"/create-new-password"}
              component={CreateNewPasswordScreen}
            />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
            <Route
              exact
              path="/email-verification"
              component={EmailVerification}
            />

            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default PublicLayout;

import React, { useEffect, useState, useContext, useCallback } from "react";

import {
  getAllFeedsOfUser,
  userProfile,
  userProfileOf,
  userSuggestions,
} from "./ProfileService";

import WritePost from "../Shared/WritePost";
import PostList from "../Shared/PostList";

import AppContext from "../../context";
import LocalStorageService from "../../utils/LocalStorageService";
import { useParams } from "react-router";
import {
  deleteFeedPost,
  feedLike,
  feedPost,
  feedShare,
  feedUnLike,
  getCommentsFeed,
  getFeedById,
  postFeedComment,
  updateFeed,
} from "../Feed/FeedService";
import { useUploadFiles } from "../../context/UploadFilesProvider";
import { useUser } from "../../context/UserProvider";

/**
 * @author
 * @function ProfileScreen
 **/

const ProfileScreen = (props) => {
  const context = useContext(AppContext);

  const {
    uploadedFiles,
    uploadFilesHandler,
    removeFiles,
    setUploadedFiles,
    imgUrl,
    setImgUrl,
  } = useUploadFiles();
  const { getUserOfData } = useUser();
  const { id } = useParams();
  const userDetails = LocalStorageService.getItem("userDetail");
  const { setUserDataHandler, userData } = context;

  const [userSuggestionsList, setUserSuggestionsList] = useState([]);
  const [userOfDetails, setUserOfDetails] = useState({});
  const [postData, setPostData] = useState([]);
  const [feedData, setFeedData] = useState({
    content: "",
  });
  const [feedImages, setFeedImages] = useState([]);

  const [showWritePostModal, setShowWriteModal] = useState(false);
  const [userPrfoileDetails, setUserProfileDetails] = useState({});
  const [commentData, setCommentData] = useState({
    comment: "",
  });
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEmojiComment, setOpenEmojiComment] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [feedIdToUpdate, setFeedIdToUpdate] = useState("");

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenToast(false);
  };

  const handleCloseWritePostModal = () => {
    setFeedIdToUpdate("");
    setShowWriteModal(false);
    setFeedData({ content: "" });
  };
  const handleShowWritePostModal = () => setShowWriteModal(true);
  const handleShowEmojiOpen = () => setIsEmojiOpen(true);
  const handleCloseEmojiOpen = () => setIsEmojiOpen(false);

  const handleClickCommentEmoji = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenEmojiComment((prev) => !prev);
  };

  const getUserProfileHandler = useCallback(async () => {
    const res = await userProfileOf(id);

    if (res && res.status) {
      setUserDataHandler(res.data);
      setUserOfDetails(res.data);
    }
  }, [id, setUserDataHandler]);

  const userSuggestionsHandler = async () => {
    const res = await userSuggestions();

    if (res && res.status) {
      setUserSuggestionsList(res.data);
    }
  };

  const getAllFeedsOfUserHandler = useCallback(async () => {
    const res = await getAllFeedsOfUser(userOfDetails._id);
    if (res && res.status) {
      setPostData(res.data);
    } else {
      setPostData([]);
    }
  }, [userOfDetails?._id]);

  const feedLikeHandler = async (id) => {
    const res = await feedLike(id);

    if (res.message === "Already Liked") {
      await feedUnLike(id);
    }
    getAllFeedsOfUserHandler();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFeedData((prevState) => ({ ...prevState, [name]: value }));
  };

  const feedPostHandler = async () => {
    const { content } = feedData;
    // const formData = new FormData()

    // formData.append('description', content)

    // for (const file of feedImages) {
    //   formData.append(`files`, file)
    // }
    const payload = {
      description: content,
      links: uploadedFiles,
    };
    const res = await feedPost(payload);

    if (res.status) {
      handleCloseWritePostModal();
      getAllFeedsOfUserHandler();
      setUploadedFiles([]);
      setImgUrl([]);
    }
  };
  const feedPostUpdateHandler = async () => {
    const { content } = feedData;

    const payload = {
      description: content,
      links: uploadedFiles,
    };
    let res;
    if (feedIdToUpdate) {
      res = await updateFeed(payload, feedIdToUpdate);
      if (res.status) {
        handleCloseWritePostModal();
        getAllFeedsOfUserHandler();
        setUploadedFiles([]);
        setImgUrl([]);
        setFeedData({ content: "" });
      }
    } else {
      feedPostHandler();
    }
  };

  const uploadImagesHandler = async (file) => {
    setFeedImages((prevState) => [...prevState, file]);
    uploadFilesHandler(file, "PROFILE");
  };

  const commentOpenHandler = async (data) => {
    const tempData = [...postData];
    setCommentId(data._id);

    tempData.forEach((element) => {
      if (element._id === data._id) {
        if (element.isCommentBoxOpen) {
          element.isCommentBoxOpen = false;
        } else {
          element.isCommentBoxOpen = true;
        }
      }
    });

    setPostData(tempData);
  };
  const postCommentHandler = async (data) => {
    setCommentData({ [data._id]: "" });
    const res = await postFeedComment(data._id, commentData[data._id]);

    if (res && res.status) {
      // getFeedHandler()
      commentShowHandler(data._id);
    }
  };

  const commentShowHandler = async (id) => {
    let commentListTemp = [];
    const res = await getCommentsFeed(id);

    if (res && res.status) {
      commentListTemp = res.data.comments.reverse();
    }
    const tempData = [...postData];
    tempData.forEach((element) => {
      if (element._id === id) {
        element.isCommentShow = true;
        element.commentData = commentListTemp;
      }
    });
    setPostData(tempData);
  };

  const feedShareHandler = async (feedId) => {
    const res = await feedShare(feedId);

    if (res.status) {
      setOpenToast(true);
      setToastMessage("You have shared this post!");
    } else {
      setOpenToast(true);
      setToastMessage("Someting went wrong!");
    }
    //  setToastMessage();
    getAllFeedsOfUserHandler();
  };

  const handleChangeComment = (e, id) => {
    const { name, value } = e.target;
    setCommentData((prevState) => ({ ...prevState, [id]: value }));
  };

  useEffect(() => {
    getUserProfileHandler();
    userSuggestionsHandler();
    getAllFeedsOfUserHandler();
  }, [getAllFeedsOfUserHandler, getUserProfileHandler]);

  const deleteFeedPostHandler = async (id) => {
    const res = await deleteFeedPost(id);
    getAllFeedsOfUserHandler();
  };

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
    setFeedData((prevState) => ({
      ...prevState,
      content: prevState.content + emojiObject.emoji,
    }));
    handleCloseEmojiOpen();
  };

  const onCommentEmojiClick = (event, emojiObject) => {
    setCommentData((prevState) => ({
      ...prevState,
      [commentId]: prevState[commentId] + emojiObject.emoji,
    }));
    setOpenEmojiComment(false);
  };

  const getFeedByIdHandler = async (id) => {
    const res = await getFeedById(id);

    if (res.status) {
      if (res.data.mediaLinks.length > 0) {
        for (const links of res.data.mediaLinks) {
          setUploadedFiles((prevState) => [...prevState, links._id]);
          setImgUrl((prevState) => [...prevState, links.location]);
        }
      } else {
        setUploadedFiles([]);
        setImgUrl([]);
      }

      setFeedData({ content: res.data.description });
    }

    setFeedIdToUpdate(id);
  };

  const isOtherUser = userOfDetails?._id === userDetails?._id ? false : true;

  localStorage.setItem("userId", id);

  useEffect(() => {
    getUserOfData(id);
  }, [id]);

  return (
    <>
      {!isOtherUser && (
        <WritePost
          handleChange={handleChange}
          onSubmit={feedPostHandler}
          showWritePostModal={showWritePostModal}
          handleCloseWritePostModal={handleCloseWritePostModal}
          handleShowWritePostModal={handleShowWritePostModal}
          uploadImagesHandler={uploadImagesHandler}
          uploadedFile={imgUrl}
          removeFileHandler={removeFiles}
          onEmojiClick={onEmojiClick}
          isEmojiOpen={isEmojiOpen}
          handleShowEmojiOpen={handleShowEmojiOpen}
          handleCloseEmojiOpen={handleCloseEmojiOpen}
        />
      )}

      <PostList
        userSuggestionsList={userSuggestionsList}
        postData={postData}
        userSuggestionsHandler={userSuggestionsHandler}
        setUserSuggestionsList={setUserSuggestionsList}
        feedLikeHandler={feedLikeHandler}
        commentOpenHandler={commentOpenHandler}
        postCommentHandler={postCommentHandler}
        handleChangeComment={handleChangeComment}
        commentData={commentData}
        feedShareHandler={feedShareHandler}
        commentShowHandler={commentShowHandler}
        deleteFeedPostHandler={deleteFeedPostHandler}
        handleClickCommentEmoji={handleClickCommentEmoji}
        anchorEl={anchorEl}
        openEmojiComment={openEmojiComment}
        onCommentEmojiClick={onCommentEmojiClick}
        handleCloseWritePostModal={handleCloseWritePostModal}
        handleShowWritePostModal={handleShowWritePostModal}
        showWritePostModal={showWritePostModal}
        isEmojiOpen={isEmojiOpen}
        handleShowEmojiOpen={handleShowEmojiOpen}
        handleCloseEmojiOpen={handleCloseEmojiOpen}
        uploadImagesHandler={uploadImagesHandler}
        uploadedFile={imgUrl}
        removeFileHandler={removeFiles}
        onEmojiClick={onEmojiClick}
        handleChange={handleChange}
        feedData={feedData}
        onSubmit={feedPostUpdateHandler}
        getFeedByIdHandler={getFeedByIdHandler}
        openToast={openToast}
        handleCloseToast={handleCloseToast}
        toastMessage={toastMessage}
      />
    </>
  );
};

export default ProfileScreen;

import React, { useEffect, useState } from "react";
import PostCard from "../Profile/PostCard";

import { Button } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import Picker from "emoji-picker-react";

import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";

import { followUser } from "../Profile/ProfileService";
import SimpleSnackbar from "../../components/UI/SnakBar";
import LocalStorageService from "../../utils/LocalStorageService";
import CustomModal from "../../components/UI/Modal/";
import UserListOnComment from "../Feed/UserListOnComment";
import { Col, Row } from "react-bootstrap";
import { useUploadFiles } from "../../context/UploadFilesProvider";
import isEmpty from "is-empty";

/**
 * @author
 * @function PostList
 **/

const PostList = (props) => {
  const userDetails = LocalStorageService.getItem("userDetail");

  const {
    postData,
    feedLikeHandler,
    commentOpenHandler,
    postCommentHandler,
    handleChangeComment,
    userSuggestionsList,
    setUserSuggestionsList,
    userSuggestionsHandler,
    commentData,
    feedShareHandler,
    commentShowHandler,
    deleteFeedPostHandler,
    handleClickCommentEmoji,
    anchorEl,
    openEmojiComment,
    onCommentEmojiClick,
    handleShowWritePostModal,
    showWritePostModal,
    handleCloseWritePostModal,
    isEmojiOpen,
    handleShowEmojiOpen,
    handleCloseEmojiOpen,
    uploadedFile,
    removeFileHandler,
    onEmojiClick,
    handleChange,
    feedData,
    onSubmit,
    uploadImagesHandler,
    getFeedByIdHandler,
    openToast,
    handleCloseToast,
    toastMessage,
    emojiWrapRef,
  } = props;
  const {
    setUploadedFiles,

    setImgUrl,
  } = useUploadFiles();
  const [open, setOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [showFollowingModal, setShowFollowingModal] = useState(false);
  const [feedId, setFeedId] = useState("");
  const [isUpdatedModal, setIsUpdatedModal] = useState(false);

  const handleCloseFollowingModal = () => setShowFollowingModal(false);
  const handleShowFollowingModal = (id) => {
    setFeedId(id);
    setShowFollowingModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const followUserHandler = async (id) => {
    const tempFollower = [...userSuggestionsList];
    tempFollower.forEach((el) => {
      if (el._id === id) {
        el.isFollowing = true;
      }
    });
    setUserSuggestionsList(tempFollower);
    const res = await followUser(id);

    if (res && res.status) {
      userSuggestionsHandler();
    } else {
      setOpen(true);
      setMessage("Something went wrong");
    }
  };

  const getCommentsFeedHanlder = async (id) => {
    commentShowHandler(id);
    // const res = await getCommentsFeed(id)

    // if (res && res.status) {
    //   setCommentList(res.data.comments.reverse())
    // }
  };

  const handleFileChange = (e) => {
    const files = e.target.files[0];

    uploadImagesHandler(files);
  };

  const getPostByIdHandler = (id) => {
    resetModalData();
    getFeedByIdHandler(id);
    setIsUpdatedModal(true);
  };

  const resetModalData = () => {
    setUploadedFiles([]);
    setImgUrl([]);
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="post_list">
        <ul>
          {postData?.map((post, i) => (
            <li key={i}>
              <Card>
                <PostCard
                  post={post}
                  deleteFeed={deleteFeedPostHandler}
                  showEditModal={handleShowWritePostModal}
                  getPostByIdHandler={getPostByIdHandler}
                />
                <CardActions disableSpacing>
                  <div className="buttons">
                    <ul>
                      <li>
                        <i
                          onClick={() => feedLikeHandler(post._id)}
                          title="Like"
                          className={`icon  ${
                            post?.likes?.length > 0 &&
                            post?.likes?.some((p) => p === userDetails._id)
                              ? "liked"
                              : "like"
                          }`}
                        ></i>{" "}
                        {post?.likes?.length === 1 &&
                        post?.likes?.some((p) => p === userDetails._id) ? (
                          <p className="you_other_like">You liked this</p>
                        ) : post?.likes?.length > 0 &&
                          post?.likes?.some((p) => p === userDetails._id) ? (
                          <p className="you_other_like">You and other</p>
                        ) : null}{" "}
                        <span
                          className="like_count"
                          onClick={() => handleShowFollowingModal(post._id)}
                        >
                          {post.likesCount > 0 && post.likesCount}
                        </span>
                      </li>
                      <li onClick={() => commentOpenHandler(post)}>
                        <i title="Comment" className="icon comment"></i>
                      </li>
                      {/* <li onClick={() => feedShareHandler(post._id)}>
                        <i title="Share" className="icon share_feed"></i>
                      </li> */}
                    </ul>
                  </div>
                  <div className="like_comment_detail">
                    {post.commentCount > 0 && (
                      <p onClick={() => getCommentsFeedHanlder(post._id)}>
                        <span className="comment_count">
                          {post.commentCount}
                        </span>{" "}
                        comments view all
                      </p>
                    )}

                    {post.isCommentBoxOpen && (
                      <div className="bottom-bar">
                        <Popper
                          open={openEmojiComment}
                          anchorEl={anchorEl}
                          placement="top-star"
                          transition
                          ref={emojiWrapRef}
                          // ref={(ref) => (emojiWrapRef.current[i] = ref)}
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                              <Paper className="comment_emoji_wrapper">
                                <Picker
                                  onEmojiClick={onCommentEmojiClick}
                                  pickerStyle={{ width: "100%" }}
                                />
                              </Paper>
                            </Fade>
                          )}
                        </Popper>
                        <div className="options">
                          <ul>
                            {/* <li>
                              <span>
                                <i className="icon image"></i>
                              </span>
                            </li> */}
                            {/* <li>
                              <span>
                                <i className="icon paper-clip"></i>
                              </span>
                            </li> */}
                            <li onClick={handleClickCommentEmoji}>
                              <span>
                                <i className="icon smile"></i>
                              </span>
                            </li>
                          </ul>
                        </div>
                        <textarea
                          className="msg-input"
                          placeholder="Write a comment"
                          name="comment"
                          value={commentData[post?._id]}
                          onChange={(e) => handleChangeComment(e, post._id)}
                          autoFocus
                        ></textarea>
                        <div
                          className="send-button"
                          onClick={() => postCommentHandler(post)}
                        >
                          <i className="icon send"></i>
                        </div>
                      </div>
                    )}
                  </div>
                </CardActions>
                {post.isCommentShow && (
                  <div className="comment_wrapper">
                    <ul>
                      {post?.commentData?.map((c) => {
                        return (
                          <li>
                            <div className="media">
                              {c?.user?.profilePic ? (
                                <img src={c.user.profilePic} alt="" />
                              ) : (
                                <span className="dp_name">
                                  {c.user.name.charAt(0)}
                                </span>
                              )}

                              <div className="details">
                                <h5
                                  onClick={() =>
                                    (window.location.href = `/profile/${c?.user._id}`)
                                  }
                                >
                                  @
                                  {c?.user?.username
                                    ? c?.user?.username
                                    : "username"}
                                </h5>
                                <p>{c.comment}</p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </Card>
            </li>
          ))}

          <li>
            <div className="people_know_wrap">
              <h5>People you may know</h5>
              <div className="people_row">
                {userSuggestionsList?.slice(0, 3).map((item, i) => {
                  return (
                    <div className="people_col" key={item._id}>
                      <div className="people_card user_name">
                        {item?.profilePic ? (
                          <img
                            src={item?.profilePic}
                            alt=""
                            onClick={() =>
                              (window.location.href = `/profile/${item._id}`)
                            }
                          />
                        ) : (
                          <span
                            className="dp_name"
                            onClick={() =>
                              (window.location.href = `/profile/${item._id}`)
                            }
                          >
                            {item.name.charAt(0)}
                          </span>
                        )}

                        <h6
                          onClick={() =>
                            (window.location.href = `/profile/${item._id}`)
                          }
                        >
                          @{item?.username ? item?.username : "username"}
                        </h6>
                        <p>{item?.location}</p>
                        {!item.isFollowing ? (
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => followUserHandler(item._id)}
                          >
                            Follow
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                          >
                            Following
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
      <CustomModal
        show={showFollowingModal}
        handleClose={handleCloseFollowingModal}
        title="Users"
      >
        <UserListOnComment feedId={feedId} />
      </CustomModal>
      <CustomModal
        show={showWritePostModal}
        handleClose={() => {
          handleCloseWritePostModal();
          setIsUpdatedModal(false);
          resetModalData();
        }}
        title={isUpdatedModal ? "Update Post" : "Create Post"}
        className="create_post"
        isEmojiOpen={isEmojiOpen}
        handleCloseEmojiOpen={handleCloseEmojiOpen}
      >
        <div className="create_post_wrap">
          {isEmojiOpen ? (
            <Picker
              onEmojiClick={onEmojiClick}
              pickerStyle={{ width: "100%" }}
            />
          ) : (
            <>
              <textarea
                className="form-control"
                placeholder="What's on your mind?"
                rows="4"
                name="content"
                onChange={handleChange}
                value={feedData?.content}
              ></textarea>
              {uploadedFile?.length > 0 && (
                <>
                  <Row>
                    {uploadedFile.map((item, i, arr) => {
                      let files;
                      if (i === 0) {
                        files = (
                          <Col
                            key={i}
                            md={arr.length > 2 && 12}
                            className="uploaded_images_col"
                          >
                            <span
                              className="cancel_image"
                              onClick={() => removeFileHandler(i)}
                            >
                              <i className="icon cancel"></i>
                            </span>
                            <img src={item} alt="" />
                          </Col>
                        );
                      } else if (i === 1) {
                        files = (
                          <>
                            <Col key={i} className="uploaded_images_col">
                              <span
                                className="cancel_image"
                                onClick={() => removeFileHandler(i)}
                              >
                                <i className="icon cancel"></i>
                              </span>
                              <img src={item} alt="" />
                            </Col>
                          </>
                        );
                      } else if (i === 2) {
                        files = (
                          <>
                            <Col key={i} className="uploaded_images_col">
                              <span
                                className="cancel_image"
                                onClick={() => removeFileHandler(i)}
                              >
                                <i className="icon cancel"></i>
                              </span>
                              <img src={item} alt="" />
                            </Col>
                          </>
                        );
                      } else if (i === 3) {
                        files = (
                          <>
                            <Col key={i} className="uploaded_images_col">
                              <span
                                className="cancel_image"
                                onClick={() => removeFileHandler(i)}
                              >
                                <i className="icon cancel"></i>
                              </span>
                              <img src={item} alt="" />
                            </Col>
                          </>
                        );
                      }

                      return files;
                    })}
                  </Row>
                </>
              )}

              <div className="bottom-bar">
                <div className="options">
                  <ul>
                    <li>
                      <label htmlFor="upload-photo">
                        <input
                          style={{ display: "none" }}
                          id="upload-photo"
                          name="upload-photo"
                          type="file"
                          accept="image/x-png,image/gif,image/jpeg"
                          multiple
                          onChange={handleFileChange}
                        />

                        <span>
                          <i className="icon image"></i>
                        </span>
                      </label>
                    </li>
                    {/* <li>
                      <span>
                        <i className="icon paper-clip"></i>
                      </span>
                    </li> */}
                    <li onClick={handleShowEmojiOpen}>
                      <span>
                        <i className="icon smile"></i>
                      </span>
                    </li>
                  </ul>
                </div>

                <button className="send-button" onClick={onSubmit}>
                  {isUpdatedModal ? "Update" : "Post"}
                </button>
              </div>
            </>
          )}
        </div>
      </CustomModal>
      <SimpleSnackbar
        open={openToast}
        handleClose={handleCloseToast}
        message={toastMessage}
      />
    </>
  );
};

export default PostList;

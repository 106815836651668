import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/Logo.png";

/**
 * @author
 * @function Terms
 **/

const Terms = (props) => {
  return (
    <>
      <div className="head logo_head_common">
        <Link to="/">
          <img src={Logo} className="logo" alt="logo" />
        </Link>
      </div>
      <div className="terms_wrap border-top">
        <Container fluid>
          <h1>Terms & Conditions</h1>
          <p className="sub_heading">Welcome to REVIT</p>
          <h3>1. Introduction; Your Agreement to these Terms of Service</h3>
          <p>
            <strong>
              PLEASE READ THESE TERMS OF SERVICE CAREFULLY. THIS IS A BINDING
              CONTRACT
            </strong>
            . Welcome to the services operated by Revitstream Interactive, Inc.
            (collectively with its affiliates, “Revitstream” or “We”) consisting
            of the website available at https://www.Revitstream.com, and its
            network of websites, software applications, or any other products or
            services offered by Revitstream (the “Revitstream Services”). Other
            services offered by Revitstream may be subject to separate terms.
          </p>
          <p>
            When using the Revitstream Services, you will be subject to
            Revitstream’s Community Guidelines and additional guidelines or
            rules that are posted on the Revitstream Services, made available to
            you, or disclosed to you in connection with specific services and
            features. Revitstream may also offer certain paid services, which
            are subject to the Revitstream Terms of Sale as well as any
            additional terms or conditions that are disclosed to you in
            connection with such services. All such terms and guidelines (the
            “Guidelines”) are incorporated into these Terms of Service by
            reference.
          </p>
          <p>
            The Terms of Service apply whether you are a user that registers an
            account with the Revitstream Services or an unregistered user. You
            agree that by clicking “Sign Up” or otherwise registering,
            downloading, accessing, or using the Revitstream Services, you are
            entering into a legally binding agreement between you and
            Revitstream regarding your use of the Revitstream Services. You
            acknowledge that you have read, understood, and agree to be bound by
            these Terms of Service. If you do not agree to these Terms of
            Service, do not access or otherwise use any of the Revitstream
            Services.
          </p>
          <p>
            When using Revitstream or opening an account with Revitstream on
            behalf of a company, entity, or organization (collectively,
            “Subscribing Organization”), you represent and warrant that you: (i)
            are an authorized representative of that Subscribing Organization
            with the authority to bind that organization to these Terms of
            Service and grant the licenses set forth herein; and (ii) agree to
            these Terms of Service on behalf of such Subscribing Organization.
          </p>

          <h3>2. Use of Revitstream by Minors and Blocked Persons</h3>
          <p>
            The Revitstream Services are not available to persons under the age
            of 13. If you are between the ages of 13 and the age of legal
            majority in your jurisdiction of residence, you may only use the
            Revitstream Services under the supervision of a parent or legal
            guardian who agrees to be bound by these Terms of Service.
          </p>
          <p>
            The Revitstream Services are also not available to any users
            previously removed from the Revitstream Services by Revitstream or
            to any persons barred from receiving them under the laws of the
            United States (such as its export and re-export restrictions and
            regulations) or applicable laws in any other jurisdiction.
          </p>
          <p>
            <strong>
              BY DOWNLOADING, INSTALLING, OR OTHERWISE USING THE REVITSTREAM
              SERVICES, YOU REPRESENT THAT YOU ARE AT LEAST 13 YEARS OF AGE,
              THAT YOUR PARENT OR LEGAL GUARDIAN AGREES TO BE BOUND BY THESE
              TERMS OF SERVICE IF YOU ARE BETWEEN 13 AND THE AGE OF LEGAL
              MAJORITY IN YOUR JURISDICTION OF RESIDENCE, AND THAT YOU HAVE NOT
              BEEN PREVIOUSLY REMOVED FROM AND ARE NOT PROHIBITED FROM RECEIVING
              THE REVITSTREAM SERVICES.
            </strong>
          </p>
        </Container>
      </div>
    </>
  );
};

export default Terms;

import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Button, TextField } from "@material-ui/core";
import FormCheckBox from "../../components/UI/Checkbox";
import FormInput from "../../components/UI/FormInput";
import FormInputGroup from "../../components/UI/InputGroup";
import DatePicker from "../../components/UI/DatePicker";
import SwitchButton from "../../components/UI/SwitchButton";
import MultipleSelectDropDown from "../../components/UI/MultiselectDropdown";
import moment from "moment";

import { streamEdit, streamStart } from "./ProfileService";
import SimpleSnackbar from "../../components/UI/SnakBar";
import { getAllProductByUser } from "../Products/ProductService";
import LocalStorageService from "../../utils/LocalStorageService";
import isEmpty from "is-empty";
import { useUser } from "../../context/UserProvider";

/**
 * @author
 * @function StreamAccount
 **/

const StreamAccount = (props) => {
  const userDetails = LocalStorageService.getItem("userDetail");
  const { userData } = useUser();
  const {
    handleCloseModal,
    getAllStreamHandler,
    fromStreamList,
    editData,
    isEditMode,
  } = props;
  const [checked, setChecked] = useState(true);
  const [streamDetails, setStreamDetails] = useState({
    date: new Date().toISOString("yyyy-mm-dd hh:mm:ss"),
    time: new Date().toISOString("yyyy-mm-dd hh:mm:ss"),
  });
  const [isActive, setIsActive] = useState({
    TIER_2: false,
    TIER_1: false,
    ALL: true,
  });
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [productOpen, setProductOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isStreamFree, setIsStreamFree] = useState(false);
  const [isPastOpen, setIsPastOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleDateChange = (date) => {
    let dateISO = date.toISOString("yyyy-mm-dd hh:mm:ss");

    setStreamDetails((prevState) => ({ ...prevState, date: dateISO }));
  };
  const handleTimeChange = (e) => {
    const { value } = e.target;

    // const hours = parseInt(new Date().getHours());
    // const minutes = parseInt(new Date().getMinutes());

    // const tempHour = parseInt(value.split(":")[0]);
    // const tempMinute = parseInt(value.split(":")[1]);

    // if (hours > tempHour || minutes > tempMinute) {
    //   setIsPastOpen(true);
    // } else {
    //   setIsPastOpen(false);
    // }

    // const timeISO = time.toISOString('yyyy-mm-dd hh:mm:ss')
    setStreamDetails((prevState) => ({ ...prevState, time: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setStreamDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  const viewProfileHandler = (type) => {
    setIsActive((prevState) => ({ prevState: false, [type]: true }));
    setStreamDetails((prevState) => ({ ...prevState, type }));
  };

  const onSubmit = async () => {
    const { streamTitle, cost, type, date, time } = streamDetails;

    const dateTemp = date.split("T");

    const streamTimeTemp = new Date(`${dateTemp[0] + "T" + time + ":00"}`);
    const payload = {
      title: streamTitle,
      price: parseInt(cost === "This stream will be free for audience"?"0":cost),
      startTime: streamTimeTemp,
      viewerProfile: type ? type : "ALL",
      productLinks: selectedProducts,
    };

    if (isEditMode) {
      const payloadEdit = {
        title: streamTitle,
        price: parseInt(cost) || editData.price,
        startTime: streamTimeTemp,
        viewerProfile: type ? type : "ALL",
        productLinks: selectedProducts,
      };
      const res = await streamEdit(editData._id, payloadEdit);
      if (res && res.status) {
        handleCloseModal();
        setOpen(true);
        setMessage("Stream Edited successfully");
        if (fromStreamList) {
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        }
      } else {
        setOpen(true);
        setMessage(res?.response?.data?.message);
      }
      return;
    }

    const res = await streamStart(payload);

    if (res && res.status) {
      handleCloseModal();
      setOpen(true);
      setMessage("Stream created successfully");
      if (fromStreamList) {
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      }
    } else {
      setOpen(true);
      setMessage(res?.response?.data?.message);
    }
  };

  const productShowChangehandler = (e) => {
    const { checked } = e.target;

    if (checked) {
    } else {
    }
    setProductOpen(checked);
  };

  const handleProductChange = (event) => {
    const { value } = event.target;
    setSelectedProducts(value);
  };

  const getAllProductHandler = useCallback(async () => {
    const res = await getAllProductByUser(userDetails._id);

    if (res && res.status) {
      setAllProducts(res.data.reverse());
    } else {
      setAllProducts([]);
    }
  }, [userDetails._id]);

  const onFreeHanlder = () => {
    setIsStreamFree((prevState) => {
      if (!prevState) {
        setStreamDetails((prevState) => ({
          ...prevState,
          cost: "This stream will be free for audience",
        }));
      } else {
        setStreamDetails((prevState) => ({
          ...prevState,
          cost: "",
        }));
      }
      return !prevState;
    });
  };

  useEffect(() => {
    getAllProductHandler();
  }, [getAllProductHandler]);

  useEffect(() => {
    if (!isEmpty(editData)) {
      if (editData.price === 0) {
        setIsStreamFree(true);
      }
      const convertedTime = moment.utc(editData?.startTime).local().format();

      const timeTemp = convertedTime.split("T")[1];
      const hour = timeTemp.split(":")[0];
      const minute = timeTemp.split(":")[1];

      setStreamDetails({
        streamTitle: editData.title,
        cost:
          editData.price === 0
            ? "This stream will be free for audience"
            : editData.price,
        time: `${hour}:${minute}`,
        date: editData.startTime,
      });
      let type = editData.viewerProfile;
      setIsActive((prevState) => ({ prevState: false, [type]: true }));
      setStreamDetails((prevState) => ({ ...prevState, type }));
      setSelectedDate(editData.startTime);
    }
  }, [editData]);

  return (
    <>
      <div className="stream_account_wrap">
        <FormInput
          label="Stream Title *"
          name="streamTitle"
          onChange={handleChange}
          value={streamDetails.streamTitle}
        />
        <Row>
          <Col>
            <FormInputGroup
              label={
                <span>
                  Cost *{" "}
                  <span
                    className={`free_cta ${isStreamFree ? "active" : ""}`}
                    onClick={onFreeHanlder}
                  >
                    Free
                  </span>
                </span>
              }
              groupText={`${!isStreamFree ? "/ minutes" : ""}`}
              name="cost"
              onChange={handleChange}
              value={streamDetails.cost}
              readOnly={isStreamFree}
            />
          </Col>
          <Col>
            <Form.Group className="view_profile">
              <Form.Label>Viewer Profile *</Form.Label>
              <ul>
                <li onClick={() => viewProfileHandler("TIER_1")}>
                  <span className={`${isActive.TIER_1 ? "active" : ""}`}>
                    18+
                  </span>
                </li>
                {/* <li onClick={() => viewProfileHandler('TIER_1')}>
              <span className={`${isActive.TIER_1 ? 'active' : ''}`}>
                Tired Verified
              </span>
            </li> */}
                <li onClick={() => viewProfileHandler("ALL")}>
                  <span className={`${isActive.ALL ? "active" : ""}`}>All</span>
                </li>
              </ul>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Row>
            <Col>
              {userData.productCount > 0 && (
                <Form.Group>
                  <SwitchButton
                    isChecked={productOpen}
                    change={productShowChangehandler}
                    label="Do you want to add products?"
                  />
                </Form.Group>
              )}
            </Col>
            {productOpen && (
              <Col md={12}>
                <MultipleSelectDropDown
                  label="Select Products"
                  value={selectedProducts}
                  onChange={handleProductChange}
                  list={allProducts}
                />
              </Col>
            )}
          </Row>
        </Form.Group>

        {/* <p className='kyc_text'>
          KYC is not verified then show KYC verification messege
        </p> */}

        {/* <FormInput label="Signature  *" /> */}
        <Form.Group>
          <Row>
            <Col className="date_picker ">
              <Form.Label>Date *</Form.Label>
              <DatePicker
                onDateChange={handleDateChange}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </Col>
            <Col className="date_picker time_picker_col">
              <Form.Label>Time *</Form.Label>
              {/* <CustomTimePicker onTimeChange={handleTimeChange} /> */}
              <TextField
                id="time"
                type="time"
                onChange={handleTimeChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                value={streamDetails?.time}
              />
              {isPastOpen && (
                <p className="text-danger mt-2">Time should not be in past</p>
              )}
            </Col>
          </Row>
        </Form.Group>

        {/* <Form.Group>
          <FormCheckBox
            checked={checked}
            handleChange={handleCheckChange}
            label="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
        </Form.Group> */}
        <div className="form_buttons">
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            className="ml-99"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
        </div>
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default StreamAccount;

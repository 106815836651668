import React from "react";

import { Dropdown } from "react-bootstrap";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Col, Row } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

import moment from "moment";
import { Link } from "react-router-dom";
import LocalStorageService from "../../utils/LocalStorageService";

export default function PostCard(props) {
  const userDetails = LocalStorageService.getItem("userDetail");

  const { post, deleteFeed, showEditModal, getPostByIdHandler } = props;

  return (
    <>
      <CardHeader
        avatar={
          post.user.profilePic ? (
            <img src={post.user.profilePic} alt="" />
          ) : (
            <span className="dp_name">{post.user.name.charAt(0)}</span>
          )
        }
        action={
          <>
            {userDetails._id === post.user._id && (
              <IconButton aria-label="settings">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <div className="action">
                      <MoreHorizIcon />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="javascript:void(0)"
                      onClick={() => {
                        showEditModal();
                        getPostByIdHandler(post._id);
                      }}
                    >
                      <i className="icon edit"></i> Edit
                    </Dropdown.Item>

                    <Dropdown.Item
                      href="javascript:void(0)"
                      onClick={() => deleteFeed(post._id)}
                    >
                      <i className="icon delete"></i> Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </IconButton>
            )}

            <p>{moment(post.created_at).startOf("seconds").fromNow()}</p>
          </>
        }
        title={
          <p
            onClick={() => (window.location.href = `/profile/${post.user._id}`)}
          >
            @{post.user.username ? post.user.username : "username"}
          </p>
        }
        // subheader={post.user.location}
      />
      <div className="post_image">
        <SimpleReactLightbox>
          <SRLWrapper>
            <Row>
              {post?.mediaLinks?.map((image, i, arr) => {
                let postCard;
                if (i === 0) {
                  postCard = (
                    <Col
                      className="post_card_col rajesh"
                      md={(arr.length > 2 && 12) || (arr.length === 1 && 12)}
                      key={i}
                    >
                      <img className="card_media" src={image.location} alt="" />
                    </Col>
                  );
                } else if (i === 1) {
                  postCard = (
                    <Col className="post_card_col" key={i}>
                      {/* <CardMedia
                        className="card_media"
                        image={image}
                        title="Paella dish"
                      /> */}
                      <img className="card_media" src={image.location} alt="" />
                    </Col>
                  );
                } else if (i === 2) {
                  postCard = (
                    <Col className="post_card_col" key={i}>
                      {/* <CardMedia
                        className="card_media"
                        image={image}
                        title="Paella dish"
                      /> */}
                      <img className="card_media" src={image.location} alt="" />
                    </Col>
                  );
                } else if (i === 3) {
                  postCard = (
                    <Col className="post_card_col" key={i}>
                      {/* <CardMedia
                        className="card_media"
                        image={image}
                        title="Paella dish"
                      /> */}
                      <img className="card_media" src={image.location} alt="" />
                    </Col>
                  );
                }
                return postCard;
              })}
            </Row>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      {post.type === "SHARE" && (
        <CardContent>{post?.parentPost?.description}</CardContent>
      )}
      {post.type === "POST" && <CardContent>{post.description}</CardContent>}
      {post.type === "STREAM" && (
        <CardContent className="stream_post_card">
          <h2>
            Hi Everyone, I am going live on{" "}
            <strong>
              {moment
                .utc(post?.stream?.startTime)
                .local()
                .format("MMMM Do YYYY, h:mm:ss a")}
            </strong>
          </h2>
          <h5 className="title">
            I will be talking about <strong>{post.description}</strong>
          </h5>

          <div className="stream_cost mb-3">
            Steam Cost :
            {post?.stream?.price === 0 ? (
              <strong>Free</strong>
            ) : (
              <>
                <p>
                  <i className="icon revit_coin"></i> {post?.stream?.price}{" "}
                  /minute
                </p>
              </>
            )}
          </div>
          <div className="stream_url">
            <Link
              to={`/streaming/${post?.stream?._id}/${
                userDetails._id === post?.stream?.userId ? "host" : "audience"
              }`}
              className="mt-3"
            >
              Go to stream
            </Link>
          </div>
        </CardContent>
      )}
    </>
  );
}

import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../../assets/img/Logo.png";
import LoginImg from "../../../assets/img/login_img.png";

import AuthFooter from "../../../components/shared/AuthFooter";
import { resetPassword } from "../AuthService";
import { useForm } from "react-hook-form";
import useQuery from "../../../hooks/useQueryHook";
import { useOffline } from "../../../context/OfflineProvider";
import SimpleSnackbar from "../../../components/UI/SnakBar";
import { Link, useHistory } from "react-router-dom";

/**
 * @author
 * @function CreateNewPasswordScreen
 **/

const CreateNewPasswordScreen = (props) => {
  const { isOffline } = useOffline();
  const query = useQuery();

  const history = useHistory();
  const [error, setError] = useState("");
  const [values, setValues] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [passwordDetails, setPasswordDetails] = useState({});

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClickShowPassword = () => {
    setValues((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = passwordDetails;
    if (password.length < 8) {
      setOpen(true);
      setMessage("Minimum 8 characters required");
      return;
    }
    if (password !== confirmPassword) {
      setOpen(true);
      setMessage("Password is not same");
      return;
    }
    const payload = {
      email: query.get("email"),
      password,
      confirmPassword,
      token: query.get("token"),
    };
    const res = await resetPassword(payload);

    if (res && res?.status) {
      setOpen(true);
      setMessage(res.message);
    } else {
      setOpen(true);
      setMessage("something went wrong");
    }
    history.push('/login')
    //
    //
  };

  return (
    <>
      <div className="login_wrapper">
        <div className="head">
          <Link to="/">
            <img src={Logo} className="logo" alt="logo" />
          </Link>
        </div>
        <div className="middle_content">
          <Container>
            <Row>
              <Col md={7}>
                <div className="form_wrap">
                  <h1>
                    Create
                    <br /> New Password
                  </h1>
                  <p>
                    Your new password must be different <br />
                    from previous used passwords.
                  </p>
                  <form onSubmit={onSubmit}>
                    <div className="input_box login">
                      <div className="input_wrap">
                        <TextField
                          label="Password"
                          placeholder="enter your password"
                          id="password"
                          name="password"
                          type={values.showPassword ? "text" : "password"}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={passwordDetails?.password}
                          onChange={(e) => {
                            setPasswordDetails({
                              ...passwordDetails,
                              password: e.target.value,
                            });
                          }}
                          required
                          variant="filled"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    setValues((prevState) => ({
                                      ...prevState,
                                      showPassword: !prevState.showPassword,
                                    }));
                                  }}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                                {/* <i className={`icon ${icon}`}></i> */}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className="input_wrap">
                        <TextField
                          label="Confirm Password"
                          placeholder="enter your password"
                          name="confirmPassword"
                          id="confirmPassword"
                          type={
                            values.showConfirmPassword ? "text" : "password"
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            setPasswordDetails({
                              ...passwordDetails,
                              confirmPassword: e.target.value,
                            });
                          }}
                          required
                          variant="filled"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    setValues((prevState) => ({
                                      ...prevState,
                                      showConfirmPassword:
                                        !prevState.showConfirmPassword,
                                    }));
                                  }}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showConfirmPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                                {/* <i className={`icon ${icon}`}></i> */}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>

                    <div className="button_wrap">
                      <p>{error && <span className="error">{error}</span>}</p>

                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        id="confirm"
                      >
                        Confirm
                      </Button>
                    </div>
                  </form>
                </div>
              </Col>
              <Col md={5}>
                <div className="login_right">
                  <div className="image_wrap">
                    <img src={LoginImg} alt="" />
                    <div className="circle"></div>
                    <div className="circle_gray"></div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer_content"></div>
        <AuthFooter />
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default CreateNewPasswordScreen;

import React, { useEffect, useRef, useState } from "react";

import { useStreamComment } from "../../context/StreamCommentProvider";
import LocalStorageService from "../../utils/LocalStorageService";
import Popper from "@material-ui/core/Popper";
import Picker from "emoji-picker-react";

import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import useChat from "../../hooks/useChat";
import isEmpty from "is-empty";
import { getCommentStream } from "./StreamingService";

/**
 * @author
 * @function CommentBox
 **/

const CommentBox = (props) => {
  const userDetails = LocalStorageService.getItem("userDetail");
  const { closeCommentHandler, streamId } = props;
  const {
    postCommentHandler,
    getCommentHandler,
    commentList,

    scrollRef,
    commentValue,
  } = useStreamComment();
  const { sendMessage } = useChat(streamId);
  const [comment, setComment] = useState("");

  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleShowEmojiOpen = () => setIsEmojiOpen(true);
  const handleCloseEmojiOpen = () => setIsEmojiOpen(false);

  const handleChange = (e) => {
    const { value } = e.target;
    setComment(value);
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [commentList]);

  useEffect(() => {}, []);



  const onSubmit = (e) => {
    if (e.key === "Enter") {
      if (isEmpty(comment)) return;
      const messageData = {
        senderId: userDetails._id,
        streamId: streamId,
        message: comment,
      };

      sendMessage(messageData, "STREAM");

      postCommentHandler(messageData, streamId);
      setComment("");
    }
  };

  const handleClickEmoji = (event) => {
    setAnchorEl(event.currentTarget);
    setIsEmojiOpen((prev) => !prev);
  };

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);

    setComment((prevState) => prevState + emojiObject.emoji);
    handleCloseEmojiOpen();
  };

  useEffect(() => {
    getCommentHandler(streamId);
  }, []);



  return (
    <>
      <div className="comment_box">
        <div className="comment_head">
          <span></span>
          <span className="close" onClick={closeCommentHandler}>
            <i className="icon cancel_gray" style={{color:"white"}}></i>
          </span>
          <h5 style={{fontSize:"14px"}}>Comments</h5>

        </div>
        <div className="comment_body">
          <div className="comment_ul">
            {commentList?.map((c) => (
              <div
                className={`comment_li ${
                  c.senderId === userDetails._id ? "mine" : "other"
                }`}
                key={c.senderId}
              >
                <div className="user">
                  {c.senderId !== userDetails._id && (
                    <>
                      {" "}
                      {c?.profilePic ? (
                        <img src={c?.profilePic} alt="" />
                      ) : (
                        <span class="dp_name">{c.username?.charAt(0)}</span>
                      )}
                    </>
                  )}

                  <div className="details">
                    <h5>{c.recieverId}</h5>
                    <p className="chat">{c.message}</p>
                  </div>
                </div>
              </div>
            ))}
            <div ref={scrollRef} />
          </div>
        </div>
        <div className="comment_bottom">
          <Popper
            open={isEmojiOpen}
            anchorEl={anchorEl}
            placement="top-star"
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper className="comment_emoji_wrapper">
                  <Picker
                    onEmojiClick={onEmojiClick}
                    pickerStyle={{ width: "100%" }}
                  />
                </Paper>
              </Fade>
            )}
          </Popper>
          <ul>
            {/* <li>
              <i className="icon image"></i>
            </li> */}
            {/* <li>
              <i className="icon paper-clip "></i>
            </li> */}
            <li onClick={handleClickEmoji}>
              <i className="icon smile"></i>
            </li>
          </ul>
          <input
            value={comment}
            className="form-control"
            onChange={handleChange}
            onKeyDown={onSubmit}
            placeholder="Comment..."
          />
        </div>
      </div>
    </>
  );
};

export default CommentBox;

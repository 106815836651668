import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Nav, Tab } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { cancelBid, getAllMyBids, getBuyerOrders } from "../../Service";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import SimpleSnackbar from "../../../../components/UI/SnakBar";

/**
 * @author
 * @function MyOrders
 **/

export const MyOrders = (props) => {
  let history = useHistory();
  const [orderList, setOrderList] = useState([]);
  const [bidList, setBidList] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const getBuyerOrdersHandler = async () => {
    const res = await getBuyerOrders("&subType=BUY");

    if (res.status) {
      for (const data of res.data) {
        setOrderList(data.data);
      }
    }

   
  };

 

  useEffect(() => {
    getBuyerOrdersHandler();
  }, []);

  const getAllMyBidsHandler = async () => {
    const res = await getAllMyBids();
    if (res && res.status) {
      setBidList(res.data);
    }
  };

  useEffect(() => {
    getAllMyBidsHandler();
  }, []);

  const cancelBidHandler = async (id) => {
    const res = await cancelBid(id);
   
    if (res?.response) {
      setOpen(true);
      setMessage(res?.response?.data?.message);
      return;
    }
    if (res.status) {
      setOpen(true);
      setMessage(res.message);
      getAllMyBidsHandler();
    }

   
  };

  return (
    <>
      <div className="my_order_wrap my_product_wrap">
        {/* <div className="head">
          <h5>My Orders</h5>
        </div> */}

        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="first">My Orders</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">My Bids</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div className="body">
                {orderList.length === 0 && (
                  <h5 className="text-center pt-5">You have no orders!</h5>
                )}
                <ul className="my-product-list">
                  {orderList.map((p) => (
                    <li class="list">
                      <div className="d-flex">
                        <div class="image_wrap">
                          {p?.product?.mediaLinks?.slice(0, 1)?.map((url) => (
                            <img src={url.location} alt="" className="product-order-img" />
                          ))}
                        </div>
                        <div class="details">
                          <div class="dropdown">
                            <button
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-basic"
                              type="button"
                              class="dropdown-toggle btn btn-success"
                            >
                              <div class="action">
                                <svg
                                  class="MuiSvgIcon-root"
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                >
                                  <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                                </svg>
                              </div>
                            </button>
                          </div>
                          <div class="p_detail"> {p?.product?.productName}</div>
                          <p class="p_brand">Brand: {p?.product?.brandName}</p>
                          <p class="seller_text">
                            Seller:{" "}
                            <Link to={`/profile/${p?.seller?._id}`}>
                              {p?.seller?.name}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <p class="p_price">
                        <i class="icon revit_coin"></i> {p?.product?.amount}
                      </p>
                      <div className="status text-center">
                        <h5>
                          {p.status === "ACCEPTED" && (
                            <>
                              Your product will be delivered on{" "}
                              {moment(p?.product?.created_at)
                                .add(10, "days")
                                .format("Do, MMM YYYY")}
                            </>
                          )}
                          {p.status === "DELIVERED" && (
                            <>
                              <Badge variant="success">Delivered</Badge>
                            </>
                          )}
                          {p.status === "PENDING" && (
                            <>Waiting for seller to accept the order</>
                          )}
                          {p.status === "SHIPPED" && (
                            <>
                              Your order has been shipped <br />
                              and will delivered on{" "}
                              {moment(p?.product?.created_at)
                                .add(10, "days")
                                .format("Do, MMM YYYY")}
                            </>
                          )}
                          {p.status === "CANCELLED" && (
                            <>
                              <Badge variant="danger">CANCELLED</Badge>
                            </>
                          )}
                        </h5>

                        <QuestionAnswerIcon
                          style={{ cursor: "pointer", marginTop: "10px" }}
                          onClick={() => {
                            history.push(`/messages/user/${p.seller._id}`);
                          }}
                        />
                        {/* <p>Your item has been delivered</p> */}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div className="body">
                {bidList.length === 0 && (
                  <h5 className="text-center pt-5">You have no Bids!</h5>
                )}
                <ul>
                  {bidList.map((p) => (
                    <li class="list">
                      <div className="d-flex">
                        <div class="image_wrap">
                          {p?.product?.links.slice(0, 1).map((url) => (
                            <img src={url} alt="" />
                          ))}
                        </div>
                        <div class="details">
                          {/* <div class="dropdown">
                            <button
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-basic"
                              type="button"
                              class="dropdown-toggle btn btn-success"
                            >
                              <div class="action">
                                <svg
                                  class="MuiSvgIcon-root"
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                >
                                  <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                                </svg>
                              </div>
                            </button>
                          </div> */}
                          <div class="p_detail"> {p?.product?.productName}</div>
                          <p class="p_brand">Brand: {p?.product?.brandName}</p>
                          <p class="seller_text">
                            Seller:{" "}
                            <Link to={`/profile/${p?.seller?._id}`}>
                              {p?.seller?.name}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <p class="p_price">
                        <i class="icon revit_coin"></i> {p?.product?.amount}
                      </p>
                      <div className="status text-center">
                        <h5>
                          {p.status === "ACCEPTED" && (
                            <>
                              Your product will be delivered on{" "}
                              {moment(p?.product?.created_at)
                                .add(10, "days")
                                .format("Do, MMM YYYY")}
                            </>
                          )}
                          {p?.status === "DELIVERED" && (
                            <>
                              <Badge variant="success">Delivered</Badge>
                            </>
                          )}
                          {p?.status === "PENDING" && (
                            <>
                              {moment(
                                new Date(p?.product?.bidExpireyDate)
                              ).isBefore(moment()) ? (
                                <>Bid Expired</>
                              ) : (
                                <>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                      history.push(
                                        `/product/detail/${p?.product?._id}?isModify=true`
                                      )
                                    }
                                  >
                                    Modify Bid
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    onClick={() =>
                                      cancelBidHandler(p?.product?._id)
                                    }
                                    className="ml-2"
                                  >
                                    Cancel
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                          {p?.status === "SHIPPED" && (
                            <>
                              Your order has been shipped <br />
                              and will delivered on{" "}
                              {moment(p.product.created_at)
                                .add(10, "days")
                                .format("Do, MMM YYYY")}
                            </>
                          )}
                          {p?.status === "CANCELLED" && (
                            <>
                              <Badge variant="danger">CANCELLED</Badge>
                            </>
                          )}
                        </h5>
                        <QuestionAnswerIcon
                          style={{ cursor: "pointer", marginTop: "10px" }}
                          onClick={() => {
                            history.push(`/messages/user/${p.seller._id}`);
                          }}
                        />
                        {/* <p>Your item has been delivered</p> */}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

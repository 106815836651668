import React from "react";
import CustomModal from "../../components/UI/Modal";

/**
 * @author
 * @function LowBalanceModal
 **/

export const LowBalanceModal = (props) => {
  const { show, close } = props;
  return (
    <>
      <CustomModal show={show} handleClose={close} title="Low Balance">
        <div className="balance_add_wrapper">
          <div className="balance_add_icon">
            <i className="icon-lg add_wallet"></i>
          </div>
          <h5>insufficient balance</h5>
          <p>Please add balace to your account</p>
          <a
            target="_blank"
            href="https://revicoin.com/"
            rel="noreferrer"
            onClick={close}
          >
            Add Balace
          </a>
        </div>
      </CustomModal>
    </>
  );
};

import React, { createContext, useContext } from "react";
import { useState } from "react";

const ProductsContext = createContext();

export function useProducts() {
  return useContext(ProductsContext);
}

export default function ProductsProvider({ children }) {
  const [allProductsContext, setAllProductsContext] = useState([]);

  const onSubmitProductsHandler = (data) => {
    setAllProductsContext(data);
  };

  const values = {
    allProductsContext,
    onSubmitProductsHandler,
  };
  return (
    <ProductsContext.Provider value={values}>
      {children}
    </ProductsContext.Provider>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../../assets/img/Logo.png";
import LoginImg from "../../../assets/img/login_img.png";
import Input from "../../../components/UI/Input";
import AuthFooter from "../../../components/shared/AuthFooter";
import { useHistory } from "react-router";
import LocalStorageService from "../../../utils/LocalStorageService";
import { sendVerificationMail, userLogin } from "../AuthService";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import SimpleSnackbar from "../../../components/UI/SnakBar";
import { useOffline } from "../../../context/OfflineProvider";
import { useUser } from "../../../context/UserProvider";

/**
 * @author
 * @function SigninScreen
 **/

const SigninScreen = (props) => {
  const { onSubmitUserHandler } = useUser();
  const { isOffline } = useOffline();
  let history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [checked, setChecked] = useState(true);
  const [email, setEmail] = useState("");
  const [values, setValues] = useState({
    showPassword: false,
  });
  const [isRememberMe, setIsRememberMe] = useState(false);

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isVerified, setIsverified] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClickShowPassword = () => {
    setValues((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setIsRememberMe(event.target.checked);
  };
  // const changeHandler = (e) => {
  //   const { value, name } = e.target
  //   setUserDetails((prevState) => ({ ...prevState, [name]: value }))
  // }
  const onSubmit = async (data) => {
   
    const { email, password } = data;
    const payload = {
      email,
      password,
      mobile: "",
      isAdmin: 0,
    };
    setEmail(email);
    const res = await userLogin(payload);

    if (res && res?.status) {
      setIsverified(false);
      // LocalStorageService.setItem('token', res.data.token)
      localStorage.setItem("token", res.data.token);
      LocalStorageService.setItem("userDetail", res.data.user);
      onSubmitUserHandler(res.data.user);

      const rememberObj = {
        email: email,
        passowrd: password,
      };
      if (isRememberMe) {
        LocalStorageService.setItem("remeberMe", rememberObj);
      }
      //LocalStorageService.setItem('userDetails',)
      history.push("/feed");
    } else {
      if (res?.response?.data.message === "Account verification pending") {
        setIsverified(true);
        setError("");
      } else {
        setIsverified(false);
        if (!res?.response?.data?.status) {
          setError(res?.response?.data?.message);
        }
      }
    }
    //
    //
  };

  const userLoginHandler = async (data) => {
  
    const payload = {
      email: data.email,
      password: data.passowrd,
      mobile: "",
    };
    const res = await userLogin(payload);
    if (res && res?.status) {
      localStorage.setItem("token", res.data.token);
      LocalStorageService.setItem("userDetail", res.data.user);
      history.push("/feed");
    }
  };

  useEffect(() => {
    const rememberObj = LocalStorageService.getItem("remeberMe");
   
    if (rememberObj) {
      setIsRememberMe(true);
      userLoginHandler(rememberObj);
    }
  }, []);

  const resendMailHandler = async () => {
    const res = await sendVerificationMail(email);

    if (res.status) {
      setIsverified(false);
      setError("");
      setOpen(true);
      setMessage(res.data);
    }
  };

  const gotToSignupHandler = () => {
    history.push("/signup");
  };



  return (
    <>
      <div className="login_wrapper">
        <div className="head">
          <Link to="/">
            <img src={Logo} className="logo" alt="logo" />
          </Link>
        </div>
        <div className="middle_content">
          <Container>
            <Row>
              <Col md={7}>
                <div className="form_wrap">
                  <h1>
                    Welcome to
                    <br /> Online Streaming System
                  </h1>
                  <p>
                    If you already have a account,
                    <br /> please sign in below.
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input_box login">
                      <div className="input_wrap">
                        <TextField
                          label="Email ID"
                          placeholder="enter your email"
                          id="email"
                          name="email"
                          type="email"
                          {...register("email", { required: true })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...props}
                          variant="filled"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <i className={`icon email`}></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className="input_wrap">
                        <TextField
                          label="Password"
                          placeholder="enter your password"
                          name="password"
                          id="password"
                          type={values.showPassword ? "text" : "password"}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register("password", { required: true })}
                          variant="filled"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                                {/* <i className={`icon ${icon}`}></i> */}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      {/* <Input
                        label="Password"
                        placeholder="enter your password"
                        name="password"
                        icon="lock"
                        type="password"
                        {...register("password", { required: true })}
                      /> */}
                    </div>
                    <div className="remomber_forgot_wrap">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isRememberMe}
                            onChange={handleChange}
                            name="checkedB"
                            color="primary"
                            id="remeberMe"
                          />
                        }
                        label="Remember Me"
                      />
                      <Link
                        to="/reset-password"
                        id="forgotPassword"
                        className="forgot_text"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                    <div className="button_wrap">
                      {errors.email ? (
                        <span className="error">Email is required</span>
                      ) : errors.password ? (
                        <span className="error">Password is required</span>
                      ) : (
                        <>{error && <span className="error">{error}</span>}</>
                      )}

                      {isVerified && (
                        <p className="warning_p">
                          Kindly verify your email to login. Email not received?
                          <a
                            href="javascript:void(0)"
                            onClick={resendMailHandler}
                          >
                            Resend
                          </a>
                        </p>
                      )}

                      <div className="d-flex justify-content-between">
                        <Button
                          variant="contained"
                          color="primary"
                          // onClick={loginHandler}
                          type="submit"
                          id="login"
                          style={{marginRight:"10px"}}
                        >
                          Login
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={gotToSignupHandler}
                          id="signup"
                        >
                          Sign Up
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
              <Col md={5}>
                <div className="login_right">
                  <div className="image_wrap">
                    <img src={LoginImg} alt="" />
                    <div className="circle"></div>
                    <div className="circle_gray"></div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer_content"></div>
        <AuthFooter />
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default SigninScreen;

import axios from "axios";

export const addProductAPI = async (payload) => {
  try {
    const { data } = await axios.post(`store/product`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const getAllProduct = async () => {
  try {
    const { data } = await axios.get(`store/product`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getAllProductByUser = async (id) => {
  try {
    const { data } = await axios.get(`store/user/${id}/product`);
    return data;
  } catch (err) {
    return err;
  }
};
export const deleteProduct = async (id, payload) => {
  try {
    const { data } = await axios.post(`store/product/${id}/delete`);
    return data;
  } catch (err) {
    return err;
  }
};

export const getProductDetail = async (id) => {
  try {
    const { data } = await axios.get(`store/product/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};
export const updateProduct = async (id, payload) => {
  try {
    const { data } = await axios.post(`store/product/${id}`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const addAddressAPI = async (payload) => {
  try {
    const { data } = await axios.post(`user/addresses`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const makeDefaultAddress = async (id) => {
  try {
    const { data } = await axios.post(`user/addresses/${id}/default`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getUserAddress = async () => {
  try {
    const { data } = await axios.get(`user/addresses`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getUserAddressById = async (id) => {
  try {
    const { data } = await axios.get(`user/addresses/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};
export const updateUserAddress = async (id, payload) => {
  try {
    const { data } = await axios.post(`user/addresses/${id}`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const deleteUserAddress = async (id, payload) => {
  try {
    const { data } = await axios.delete(`user/addresses/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};

export const kycUpdate= async (payload)=>{
 try {
  const {data} = await axios.post(`user/addUserKyc`);
  return data;
 } catch (error) {
    return error;
 }
}


export const getCateories = async () => {
  try {
    const { data } = await axios.get(`store/productCategory`);
    return data;
  } catch (err) {
    return err;
  }
};
export const placeBidProduct = async (id, payload) => {
  try {
    const { data } = await axios.post(`store/product/${id}/bid`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const placeOrder = async (id, payload) => {
  try {
    const { data } = await axios.post(`store/product/${id}/buy`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const editBid = async (id, payload) => {
  try {
    const { data } = await axios.post(
      `store/product/bid/${id}/modify`,
      payload
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getStateCityByZipCode = async (zip) => {
  try {
    const { data } = await axios.get(
      `https://app.zipcodebase.com/api/v1/search?apikey=2160df60-2d94-11ec-9987-758b7bdd8b5e&codes=${zip}`
    );
    return data;
  } catch (err) {
    return err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  addProductAPI,
  getAllProduct,
  addAddressAPI,
  getUserAddress,
  updateUserAddress,
  getUserAddressById,
  deleteUserAddress,
  deleteProduct,
  getProductDetail,
  updateProduct,
  getCateories,
  getAllProductByUser,
  placeBidProduct,
  placeOrder,
  getStateCityByZipCode,
  makeDefaultAddress,
  editBid,
};

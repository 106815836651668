import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { useForm } from "react-hook-form";

import { Link } from "react-router-dom";
import { resetPassword } from "../Auth/AuthService";
import SimpleSnackbar from "../../components/UI/SnakBar";
import useQuery from "../../hooks/useQueryHook";
import { changePassword } from "./Service";
import { useHistory } from "react-router-dom";

/**
 * @author
 * @function ChangePassword
 **/

const ChangePassword = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const query = useQuery();
  const history = useHistory();

  const [error, setError] = useState("");
  const [values, setValues] = useState({
    showPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [passwordDetails, setPasswordDetails] = useState({});

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { currentPassword, newPassword } = passwordDetails;
    if (currentPassword.length < 8) {
      setOpen(true);
      setMessage("Minimum 8 characters required");
      return;
    }

    const payload = {
      password: currentPassword,
      newPassword,
    };
    const res = await changePassword(payload);

    console.log(res.response, "res");

    if (res && res?.status) {
      setOpen(true);
      setMessage(res.message);
      setTimeout(() => {
        localStorage.clear();
        history.push("/login");
      }, 500);
    } else {
      setOpen(true);
      setMessage(res?.response?.data?.message || "something went wrong");
    }
    //
    //
  };

  return (
    <>
      <div className="login_wrapper change-password">
        <h5>Change Password</h5>
        <p className="info-text">
          Your new password must be different <br />
          from previous used passwords.
        </p>
        <Row>
          <Col md={12}>
            <div className="form_wrap">
              <form onSubmit={onSubmit}>
                <div className="input_box login">
                  <div className="input_wrap">
                    <TextField
                      label="Current Password"
                      placeholder="enter your current password"
                      id="password"
                      name="currentPassword"
                      required
                      type={values.showPassword ? "text" : "password"}
                      value={passwordDetails?.currentPassword}
                      onChange={(e) => {
                        setPasswordDetails({
                          ...passwordDetails,
                          currentPassword: e.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="filled"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setValues((prevState) => ({
                                  ...prevState,
                                  showPassword: !prevState.showPassword,
                                }));
                              }}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                            {/* <i className={`icon ${icon}`}></i> */}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="input_wrap">
                    <TextField
                      label="New Password"
                      placeholder="enter your new password"
                      id="password"
                      name="password"
                      required
                      type={values.showNewPassword ? "text" : "password"}
                      value={passwordDetails?.newPassword}
                      onChange={(e) => {
                        setPasswordDetails({
                          ...passwordDetails,
                          newPassword: e.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="filled"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setValues((prevState) => ({
                                  ...prevState,
                                  showNewPassword: !prevState.showNewPassword,
                                }));
                              }}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showNewPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                            {/* <i className={`icon ${icon}`}></i> */}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>

                <div className="button_wrap">
                  <p>
                    {errors.password ? (
                      <span className="error">Password is required</span>
                    ) : errors.confirmPassword ? (
                      <span className="error">
                        Confirm password is required
                      </span>
                    ) : (
                      <>{error && <span className="error">{error}</span>}</>
                    )}
                  </p>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    id="confirm"
                    style={{alignItems:"center"}}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default ChangePassword;

import React from "react";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import Select from "@material-ui/core/Select";

export default function MultipleSelectDropDown(props) {
  const { label, value, onChange, list } = props;

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-mutiple-name-label">{label}</InputLabel>
        <Select
          className="product_list_menu"
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          multiple
          value={value}
          onChange={onChange}
          fullWidth
          input={<Input />}
        >
          {list.map((p) => (
            <MenuItem key={p._id} value={p._id}>
              {/* <img
                className="product_img"
                src={p.mediaLinks.slice(0, 1).map((i) => i.location)}
                alt=""
              />{" "} */}
              {p.productName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import style from './Message.module.scss'

const Message = ({ onClick, children }) => {
  return (
    <div className={style.message}>
      {onClick ? (
        <button
          aria-hidden
          type="button"
          className={style.message__button}
          onClick={onClick}
        >
          <div className={style.message__content}>{children}</div>
        </button>
      ) : (
        <div className={style.message__content}>{children}</div>
      )}
    </div>
  )
}

Message.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.shape({}).isRequired,
}

export default Message

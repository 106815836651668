import React, { useEffect } from 'react'
import { useState } from 'react';
import { Col, Form, Row, Table, Toast } from "react-bootstrap";
import { Button } from "@material-ui/core";
import isEmpty from "is-empty";
import { kycUpdate } from '../Products/ProductService';
import axios from 'axios';
import { toast } from 'react-toastify';

import Modal from 'react-bootstrap/Modal';

const KYCUpdate = () => {

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const handleClose = () => setShowUpdateModal(false);
  const handleShow = () => {
    setShowUpdateModal(true);
  }




  const [errorMessage, setErrorMessage] = useState("");

  //const { kycData} = props; 

  const [selectedOption, setSelectedOption] = useState('PASSPORT');
  const [kycData, setKycData] = useState({});

  const [kycDetails, setKycDetails] = useState({});


  const [id_number, setId_number] = useState();
  const [file, setFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [kycSubmitted, setKycSubmitted] = useState(false)
  // const handlerChange = (e) => {
  //     const { name, value } = e.target;

  //     setKycDetails((prevState) => ({ ...prevState, [name]: value }));
  //   };



  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleIdNumber = (e) => {
    setId_number(e.target.value);
    setKycDetails((prevState) => ({ ...prevState, id_number: e.target.value }));
  }

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
    setKycDetails((prevState) => ({ ...prevState, file: e.target.files[0] }));

  }
  const handleImageUpload = (e) => {
    setSelectedImage(e.target.files[0])
    setKycDetails((prevState) => ({ ...prevState, selectedImage: e.target.files[0] }));
  }

  function selectElement(data) {
    setShowUpdateModal(true);


    setKycDetails(data)

  }
  const handleUpdateKyc = () => {
    setShowUpdateModal(true)

  }

  const onSubmit = async (e) => {

    e.preventDefault();
    //const { idPhoto, uniqueId } = kycDetails;
    const formData = new FormData();
    formData.append('id_type', selectedOption)
    formData.append('id_number', id_number);

    formData.append('id_photo', file); // Use the same field name as in your backend API
    formData.append('user_photo', selectedImage)




    try {
      const response = await axios.post('user/addUserKyc', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads
        },
      });

      // Handle the response 
      console.log('API Response:', response);

      if (response.status === 200) {
        // Success case: Action was completed successfully
        console.log('Message:', response.message);
        setKycSubmitted(true)
        toast.success("KYC document uploaded successfuly")
        getUserKyc();

        // Handle the data as needed, for example, update your UI
      } else {
        // API request was successful, but the action failed
        toast.message(response.message)
        console.error('Action failed. Message:', response.message);
        // You can show an error message to the user
      }

    } catch (error) {
      // Handle errors (e.g., show an error message)
      //console.error('API Error:', error.message);
      setErrorMessage(error.message)
      toast.error(errorMessage)


    }

  }
  // Need to Manage Through Custom Hokk
  const getUserKyc = async () => {
    //  Try to Get URL from the ENV
    // END Point Should come form Common File 
    // DO NOT Write any Static Line in Code , Instead Make a Config File  & get data from their only

    const res = await axios.get('https://api.revitstream.com/v1/user/getUsersKyc');
    console.log("response from getuserKyc", res);
    if (res) {
      console.clear()
      console.log("Res--,>>>", res)
      setKycData(res?.data?.data)
      //setLoading(false)
    }
  }


  const updateUserKyc = async (e) => {
    e.preventDefault();
    const updatedFormData = new FormData();

    updatedFormData.append('id_type', selectedOption)
    updatedFormData.append('id_number', kycDetails.id_number);

    // if(id_number){
    //   updatedFormData.append('id_number', id_number)
    // }

    if (kycDetails.file) {
      updatedFormData.append('id_photo', kycDetails.file); // Use the same field name as in your backend API
    }
    if (selectedImage) {
      updatedFormData.append('user_photo', selectedImage)
    }


    try {
      const response = await axios.put('user/updateUserKyc', updatedFormData
        // headers:{
        //   'Content-Type': 'multipart/form-data',
        // },
      );

      //getUserKyc();

      if (response.status === 200) {
        // Success case: Action was completed successfully
        console.log('Message:', response.message);
        toast.success("KYC document updated successfuly");
        getUserKyc();

        // Handle the data as needed, for example, update your UI
      } else {
        // API request was successful, but the action failed
        console.error('Action failed. Message:', response.message);
        toast.message(response.message)
        // You can show an error message to the user
      }

      handleClose();
      getUserKyc();

    }
    catch (error) {
      // Handle errors (e.g., show an error message)
      //console.error('API Error:', error.message);
      setErrorMessage(error.message)


    }



  }

  console.log(kycDetails)

  useEffect(() => {
    getUserKyc();
    console.log("user kyc data is ", kycData)
  }, []);

  useEffect(() => {


  }, [kycSubmitted]);





  return (
    <div className='kyc-wrap'>



      <div className='add_wrap my_product_wrap p-5'>

        {
          (kycData && kycData?.length) ? (
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>User Image</th>
                  <th>ID Image</th>
                  <th>ID_Number</th>
                  <th>Kyc Status</th>
                  <th>ID Type</th>

                </tr>
              </thead>
              <tbody>
                {kycData.map((ele, index) => (
                  <tr key={index}>

                    <td><img src={ele?.user_photo}
                      alt='user'
                      style={{
                        maxWidth: '100px',  // Set the maximum width you desire
                        maxHeight: '100px', // Set the maximum height you desire
                      }}
                    /></td>

                    <td><img src={ele?.id_image}
                      alt='user'
                      style={{
                        maxWidth: '100px',  // Set the maximum width you desire
                        maxHeight: '100px', // Set the maximum height you desire
                      }}
                    /></td>

                    <td>{ele?.id_number} </td>
                    <td>{ele?.user_kyc_status}</td>
                    <td>
                      {ele.id_type}
                    </td>
                    <td>
                      {ele?.user_kyc_status === 'Rejected' && (
                        <Button
                          onClick={handleUpdateKyc}
                        >Update KYC</Button>
                      )}
                    </td>

                  </tr>


                ))}

              </tbody>

            </Table>



          ) : (
            <>
              <div className={`head`}>
                <h5>KYC Updation</h5>
              </div>

              <Form onSubmit={onSubmit}>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Label> Document Type*</Form.Label>
                      <Form.Control as="select" type='file' className='form-control' name='id_type' onChange={handleOptionChange} required>
                        <option value="PASSPORT">Passport</option>
                        <option value="DRIVING_LICENSE">Driving License</option>
                        <option value="NATIONAL_ID_CARD">National ID CARD</option>

                      </Form.Control>

                    </Col>

                    <Col>
                      <Form.Label>Choose File *</Form.Label>
                      <Form.Control
                        type='file'
                        className='form-control'
                        name="id_photo"
                        onChange={handleFileUpload}
                        required

                      />
                      {console.log("file value is", file)}
                    </Col>
                    <br>
                    </br>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Row className='mt-3'>
                    <Col>
                      <Form.Label>Enter Id *</Form.Label>
                      <Form.Control
                        placeholder="Enter your Id"
                        name="id_number"
                        required
                        onChange={handleIdNumber}
                        value={id_number}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {console.log("unique value is", id_number)}
                    </Col>
                  </Row>

                </Form.Group>

                <Form.Group>
                  <Row className='mt-4' >

                    <Col>
                      <Form.Label>USER Image *</Form.Label>
                      <Form.Control
                        type='file'
                        className='form-control'
                        name="user_photo"
                        onChange={handleImageUpload}
                        required

                      />
                      {console.log("image upload value is", selectedImage)}
                    </Col>
                    <Col></Col>



                  </Row>
                </Form.Group>


                {errorMessage && <p className='error'>{errorMessage}</p>}
                <div className="form_buttons">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"

                  >
                    Save
                  </Button>
                  {/* <Button
              variant="outlined"
              color="secondary"
              className="ml-99"
              onClick={cancelHandler}
            >
              Cancel
            </Button>  */}
                </div>
              </Form>
            </>

          )
        }

        

        <Modal show={showUpdateModal} onHide={handleClose}>

          <Modal.Body closeButton>
            <div className='add_wrap my_product_wrap p-5'>
              <div className={`head`}>
                <h5>KYC Updation</h5>
              </div>
              {/* remove onSubmit and add function on Update button and other updating value methods */}
              <Form onSubmit={onSubmit}>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Label> Document Type*</Form.Label>
                      <Form.Control as="select" type='file' className='form-control' name='id_type' onChange={handleOptionChange} required>
                        <option value="PASSPORT">Passport</option>
                        <option value="DRIVING_LICENSE">Driving License</option>
                        <option value="NATIONAL_ID_CARD">National ID CARD</option>

                      </Form.Control>

                    </Col>

                    <Col>
                      <Form.Label>Choose File *</Form.Label>
                      <Form.Control
                        type='file'
                        className='form-control'
                        name="id_photo"
                        onChange={handleFileUpload}
                        required

                      />
                      {console.log("file value is", file)}
                    </Col>
                    <br>
                    </br>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Row className='mt-3'>
                    <Col>
                      <Form.Label>Enter Id *</Form.Label>
                      <Form.Control
                        placeholder="Enter your Id"
                        name="id_number"
                        required
                        onChange={handleIdNumber}
                        value={id_number}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {console.log("unique value is", id_number)}
                    </Col>
                  </Row>

                </Form.Group>

                <Form.Group>
                  <Row className='mt-4' >

                    <Col>
                      <Form.Label>USER Image *</Form.Label>
                      <Form.Control
                        type='file'
                        className='form-control'
                        name="user_photo"
                        onChange={handleImageUpload}
                        required

                      />
                      {console.log("image upload value is", selectedImage)}
                    </Col>
                    <Col></Col>



                  </Row>
                </Form.Group>


                {errorMessage && <p className='error'>{errorMessage}</p>}
                <div className="form_buttons">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"

                  >
                    Save
                  </Button>
                  {/* <Button
              variant="outlined"
              color="secondary"
              className="ml-99"
              onClick={cancelHandler}
            >
              Cancel
            </Button>  */}
                </div>
              </Form>
            </div>



          </Modal.Body>
          {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
        </Modal>



      </div>




    </div>
  )
}

export default KYCUpdate
import React, { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import ContactListLoader from "../../components/SkeletonLoaders/ContactListLoader";

import { getAllContacts, getFollowers } from "../Messanger/Service";
// import { getFollowers } from "../Profile/ProfileService";


/**
 * @author
 * @function ContactList
 **/

const ContactList = (props) => {
  const [contactList, setContactList] = useState([]);
  const [copiedList, setCopiedList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = (e) => {
    const tempData = [...contactList];
    const { value } = e.target;
    const filterData = tempData.filter((p) =>
      p.username.toLowerCase().includes(value)
    );
    if (value) {
      setContactList(filterData);
    } else {
      setContactList(copiedList);
    }
  };

  const getAllContactsHanlder = async () => {
    setIsLoading(true);
  
    
              const userDetailString = localStorage.getItem("userDetail");

              const userDetail = JSON.parse(userDetailString);

              
              const userId = userDetail._id;

              console.log(userId); 

              const res = await getFollowers(userId);


    if (res?.status) {
      setCopiedList(res.data.followers);
      setContactList(res.data.followers);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setContactList([]);
    }
    console.log(res.data, 'chat  list')
  };

  useEffect(() => {
    getAllContactsHanlder();
  }, []);

  return (
    <>
      <div className="contact_wrap sticky-top">
        <div className="head">
          <h5>Contacts</h5>
          {/* <MoreHorizIcon /> */}
        </div>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text>
              <i className="icon search"></i>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl placeholder="Search" onChange={handleSearch} />
          <InputGroup.Append>
            <InputGroup.Text>
              {/* <i class="icon settings"></i> */}
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        {isLoading ? (
          <ContactListLoader />
        ) : (
          <div className="contact_list">
            <ul>
              {contactList && contactList.map((item) => {
                return (
                  <li
                    key={item._id}
                  >
                    <div className="image_wrap">
                      {item?.profilePic ? (
                        <img src={item?.profilePic} alt=""
                        onClick={() =>
                          (window.location.href = `/profile/${item._id}`)
                        }
                        />
                      ) : (
                        <span className="dp_name"
                        onClick={() =>
                          (window.location.href = `/profile/${item._id}`)
                        }
                        >
                          {item?.username?.charAt(0)}
                        </span>
                      )}
                    </div>
                    <div className="content">
                      <h6>@{item?.username ? item?.username : "username"}</h6>
                      {/* <p>{item?.location}</p> */}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default ContactList;

import React, { useContext, useState } from "react";

import { userProfileOf } from "../containers/Profile/ProfileService";

const UserProfilePicContext = React.createContext();

export function useProfilePic() {
  return useContext(UserProfilePicContext);
}

export default function UserProfilePicProvider({ children }) {
  const [profilePic, setProfilePic] = useState(null);
  const [userName, setUserName] = useState(null);

  const findUserProfilePicHandler = async (id) => {
    const res = await userProfileOf(id);

    if (res && res.status) {
      setProfilePic(res.data.profilePic);
      setUserName(res.data.name);
      return res.data.profilePic;
    }
  };

  const context = {
    profilePic,
    findUserProfilePicHandler,
    userName,
  };
  return (
    <UserProfilePicContext.Provider value={context}>
      {children}
    </UserProfilePicContext.Provider>
  );
}
